import React, { Component, } from 'react';


import Loading from './animations/Loading'
import '../scss/components/_checkout.scss';
import FormValidator from '../Helpers/FormValidator';
import { states, go } from './States';
import Stepper from "./Stepper";
import { StepperProviderClass } from './StepperProvider'; // als Object - nicht als Default Import...???
import Zahlungsart from './Steps/Zahlart3.js';
import Abrechnung from './Steps/Abrechnung';
import Lieferart from './Steps/Lieferart';
import Lieferung from './Steps/Lieferung';
import Bezahlung from './Steps/Bezahlung';
import Bestellung from './Steps/Bestellung';
import Warenkorb from './Steps/Warenkorb';
import Fertig from './Steps/Fertig';
import axios from 'axios';
import measureElement from '../Helpers/measureElement'


// OP: const appContext  = useContext(AppContext); - hier noch unnötig!!!
// https://facebook.github.io/create-react-app/docs/adding-bootstrap
// Reihenfolge im Dokument genau einhalten


// OP Test  - Button outside  - with bootstap marker - 
global.ppp = null;  // Versuch
class Checkout extends Component {
  constructor(props) {
    super(props);
    this.getCountries();
    this.validZahlungsart = new FormValidator([
      /* für Zahlart3 rausgenommen - später bei weiteren Opitionen wie Kauf auf Vorkasse oder ... muss es dann eventuell rein...
       {
         field: 'bezahlart',
         method: 'isEmpty',
         validWhen: false,
         message: 'Bitte Zahlungsart auswählen.'
       },      */
    ]);
    this.validDefault = new FormValidator([]);
    this.validWarenkorb = new FormValidator([]);
    this.validAnmeldung = new FormValidator([
      {
        field: 'anrede',
        method: 'isEmpty',
        validWhen: true,
        message: 'true auf false ändern! Bitte Anrede auswählen'
      },
      {
        field: 'email',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte Email Adresse eingeben.'
      },
      {
        field: 'email',
        method: 'isEmail',
        validWhen: true,
        message: 'That is not a valid email.'
      },
      {
        field: 'username',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte Benutzernamen eingeben.'
      }
      ,
      {
        field: 'passwort',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte Passwort eingeben.'
      }
    ]);

    this.validAbrechnung = new FormValidator([
      {
        field: 'email',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte Email Adresse eingeben.'
      },
      {
        field: 'email',
        method: 'isEmail',
        validWhen: true,
        message: 'Das ist keine gültige e-mail Adresse.'
      },
      {
        field: 'anrede',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte Anrede auswählen'
      },

      {
        field: 'vorname',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte Vorname eingeben'
      },
      {
        field: 'nachname',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte Nachname eingeben'
      },
      {
        field: 'strasse',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte Strasse mit Hausnummer eingeben'
      },
      {
        field: 'hausnummer',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte  Hausnummer eingeben'
      },
      {
        field: 'postleitzahl',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte Postleitzahl  eingeben.'
      },
      {
        field: 'ort',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte Ort eingeben.'
      },
      /*  op: vorerst - raus - vielleicht für immer-
      {
        field: 'land',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte Land auswählen.'
      }, */
    ]);
    this.validLieferung = new FormValidator([
      /*
      {
        field: 'email',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte Email Adresse eingeben.'
      },
      {
        field: 'email',
        method: 'isEmail',
        validWhen: true,
        message: 'Das ist keine gültige e-mail Adresse.'
      },*/
      {
        field: 'anrede',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte Anrede auswählen'
      },

      {
        field: 'vorname',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte Vorname eingeben'
      },
      {
        field: 'nachname',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte Nachname eingeben'
      },
      {
        field: 'strasse',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte Strasse mit Hausnummer eingeben'
      },
      {
        field: 'hausnummer',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte  Hausnummer eingeben'
      },
      {
        field: 'postleitzahl',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte Postleitzahl  eingeben.'
      },
      {
        field: 'ort',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte Ort eingaben.'
      },
      /*  op: vorerst - raus - vielleicht für immer-
      {
        field: 'land',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte Land auswählen.'
      }, */
    ]);
    this.validBestellung = new FormValidator([
      {
        field: 'agbs',
        method: 'isEmpty',
        validWhen: false,
        message: 'Bitte unsere AGB und Widerrufsbelehrung bestätitigen.'
      },
    ]);
    this.state = {
      pro: process.env.NODE_ENV === 'production',
      fetch: false,
      countries: [],
      currentStep: states.Lieferung,
      valZahlungsart: this.validZahlungsart.SetToValid(),
      valDefault: this.validDefault.SetToValid(),
      valAnmeldung: this.validAnmeldung.SetToValid(),  // geht nicht wenn es im Adresse Array liegt...
      valDelivery: this.validLieferung.SetToValid(),  // geht nicht wenn es im Anmeldung Array liegt...
      valCreditor: this.validAbrechnung.SetToValid(),  // 
      valBestellung: this.validBestellung.SetToValid(),
      valWarenkorb: this.validWarenkorb.SetToValid(),
      cart: this.props.cart,  // "derty..."? nein --> kommt über appcontext aus checkout.jsx

      Default: {     // Dummy   
      },
      Warenkorb: {     // Dummy   
      },
      Zahlungsart: {
        bezahlart: "PayPal",  // OP: für Echtbetrieb verbessen...
        isSdkready: false
      },
      Versandart: {
        condition: "Lieferung erfolgt per Kurierdienst - Lieferzeit ca. 3 - 5 Tage innerhalb von Deutschland"  // OP: für Echtbetrieb verbessen...
      },
      newUser: {
        name: '',
        age: '',
        gender: '',
        skills: [],
        about: '',
        bezahlart: "PayPal"
      },
      Anmeldung: {
        anrede: '',
        email: 'J@schatral.de',
        username: '_test',
        passwort: 'ffff',
        Land: '',
        Tier: { key: '101', value: 'Lion' },
        selectedOption: { value: 'vanilla', label: 'Vanilla' },
      },
      Lieferung: {
        email: "",
        anrede: 'Herr',
        titel: '',
        vorname: "", // process.env.NODE_ENV === 'production' ? "":'Jürgen',
        nachname:"", // process.env.NODE_ENV === 'production' ? "":'Schatral',
        firmenname: "", //process.env.NODE_ENV === 'production' ? "":'EDV',
        strasse: "", // process.env.NODE_ENV === 'production' ? "":'Ringstr.',
        strasse2: '',
        hausnummer: "", // process.env.NODE_ENV === 'production' ? "":'5A',
        packstation: '',
        postleitzahl: "", //  process.env.NODE_ENV === 'production' ? "":'56204',
        ort: "", // process.env.NODE_ENV === 'production' ? "":'Hillscheid',
        region: '',
        telefonnummer: '',
        land: { CountryId: 1, de: 'Bundesrepulbik Deutschland' },
      },
      Abrechnung: {
        email: "",
        anrede: 'Herr',
        titel: '',
        vorname: "", //process.env.NODE_ENV === 'production' ? "":'Jürgen',
        nachname: "", // process.env.NODE_ENV === 'production' ? "":'Schatral',
        firmenname: "", // process.env.NODE_ENV === 'production' ? "":'EDV',
        strasse: "", //process.env.NODE_ENV === 'production' ? "":'Ringstr.',
        strasse2: '',
        hausnummer: "", // process.env.NODE_ENV === 'production' ? "":'5A',
        packstation: '',
        postleitzahl: "", // process.env.NODE_ENV === 'production' ? "":'56204',
        ort: "", // process.env.NODE_ENV === 'production' ? "":'Hillscheid',
        region: '',
        telefonnummer: '',
        land: { CountryId: 1, de: 'Bundesrepulbik Deutschland' },
      },
      Bestellung:
      {
        agbs: [],
      },
      anredeOptions: ['Herr', 'Frau',],
      titelOptions: ['Dr.', 'Professor'],
      landOptions: ["Bundesrepublic Deutschand", "Vereinigten Staaten von Amerika", "England"],

    }
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }


  generateArray() {


    const lcart = [
      {
        "ProductId": 1,
        "PictureUrl": '/Content/Images/Oelgemaelde/351_BEILSTEIN.JPG',
        "M_MwstId": 1,
        "Title": "Beilstein",
        "description": "Ölgemälde.",
        "quantity": 1,
        "price": 10.00,
        "sku": "351",
        "currency": "EUR",
        "M_Prz": 7,
      },
      {
        "ProductId": 2,
        "PictureUrl": "/Content/Images/Oelgemaelde/352_BURG_ELZ.JPG",
        "M_MwstId": 2,
        "Title": "Burg Eltz - Germany",
        "description": "Ölgemälde. 40/50 cm groß. Dieses Gemälde wurde mit feinsten Künstler-Harz-Ölfarben, Mussini von Schmincke, auf Leinwand  gefertigt. Gedruckt wurde es auf alterungsbeständigen 300 g/m schweren und DIN A3 großen Papier. Ein Rand von 5 bis -- je Seite, je nach Breite und Höhe des Bildes, zum bequemen Einrahmen wurde berücksichtigt.",
        "quantity": 1,
        "price": 5.00,
        "sku": "352",
        "currency": "EUR",
        "M_Prz": 19
      },
    ];
    if (this.state.cart.length === 0)
      this.setState({ cart: lcart }, () => console.log(this.state.cart))

  }
  // https://www.valentinog.com/blog/await-react/
  // https://blog.northcoders.com/react-componentwillmount-to-be-deprecated
  async componentDidMount() {  // DidMount!!!! not deprecated

   //     this.generateArray();
    //debugger;
  }
  // getSnapshotBeforeUpdate(prevProps, prevState) {}  // UNSAFE_componentWillMount()

  getCountries = () => {
    // debugger;
    axios.get('/api/db/dbCountries')
      .then(
        res => this.setState({ countries: res.data },
          // this.setState({ fetch: false })
        ))
      .catch(err => {
        console.log(err);
        return null;
      });
  }
  setCurrentStep = (stage) => {
    this.setState({ currentStep: stage })
  }
  checkValidation = (currentStep) => {
    let validation = null
    switch (currentStep) {
      case states.Fertig:
        validation = this.validLieferung.validate(this.state.Lieferung)
        this.setState({ valDelivery: validation });
        break;
      case states.Bestellung:
        validation = this.validBestellung.validate(this.state.Bestellung)
        this.setState({ valBestellung: validation });
        break;
      case states.Zahlungsart:  // only for testing purposes
        validation = this.validZahlungsart.validate(this.state.Zahlungsart)
        validation.isValid = (global.ppp !== null); // Neu: 22.09.20 - override...
        this.setState({ valZahlungsart: validation });
        break;
      case states.Abrechnung:  // only for testing purposes
        validation = this.validAbrechnung.validate(this.state.Abrechnung)
        this.setState({ valCreditor: validation });
        break;
      case states.Lieferung:
        validation = this.validLieferung.validate(this.state.Lieferung)
        this.setState({ valDelivery: validation });
        break;
      case states.Warenkorb:
          validation = this.validWarenkorb.validate(this.state.Warenkorb)
          this.setState({ valWarenkorb: validation });
          break;  
      case states.Anmeldung:  // unused
        validation = this.validAnmeldung.validate(this.state.Anmeldung)
        this.setState({ valAnmeldung: validation });
        break;
      default:  // Workaroud - wenn nichts zu prüfen gibt - bis es eine leeres Objekt funktionier - Start-Konditionen validieren 
        validation = this.validDefault.validate({})
        this.setState({ valDefault: validation });
      // wenn es keine Prüfung gibt, soll er ein okay zurückgeben....

    }
    return (validation)
  }
  handleFormSubmit = event => {
    event.preventDefault()
    const { email, username, password } = this.state.Anmeldung
    alert(`Your registration detail: \n 
         Email: ${email} \n 
         Username: ${username} \n
         Password: ${password}`)
  }
  handleOptionChange(e) {
    let value = e.target.value;
    let name = e.target.name;
    // debugger
    this.setState(prevState => ({
      Zahlungsart:
      {
        ...prevState.Zahlungsart, [name]: value
      }
    }), () => console.log())
  };
  handleInput(e, action) {
    console.log(typeof (action))
    //debugger;
    if (typeof (action) !== 'undefined') {
      if (action.name === "landLieferung") {
        this.setState(prevState => ({
          Lieferung:
          {
            ...prevState.Lieferung, land: e
          }
        }))
        return
      }
      if (action.name === "landAbrechnung") {
        this.setState(prevState => ({
          Abrechnung:
          {
            ...prevState.Abrechnung, land: e
          }
        }))
        return
      }
    }
    let value = e.target.value;
    let name = e.target.name;
    console.log('handleInput- currentstep: ', this.state.currentStep)
    switch (this.state.currentStep) {
      case states.Bestellung:
        this.handleCheckBoxAgb(e);
        break;
      case states.Zahlungsart:
        this.setState(prevState => ({
          Zahlungsart:
          {
            ...prevState.Zahlungsart, [name]: value
          }
        }), () => console.log('handleInput -state Zahlungsart: ', this.state.Zahlungsart))
        break;
      case states.Abrechnung:
        this.setState(prevState => ({
          Abrechnung:
          {
            ...prevState.Abrechnung, [name]: value
          }
        }), () => console.log('handleInput -state Abrechnung: ', this.state.Abrechnung))
        break;
      case states.Lieferung:
        this.setState(prevState => ({
          Lieferung:
          {
            ...prevState.Lieferung, [name]: value
          }
        }), () => console.log('handleInput -state Lieferung: ', this.state.Lieferung))

        break;
      case states.Anmeldung:
        this.setState(prevState => ({
          Anmeldung:
          {
            ...prevState.Anmeldung, [name]: value
          }
        }), () => console.log('handleInput -state Anmeldung: ', this.state.Anmeldung))
        break;
      case states.Fertig:
        this.setState(prevState => ({
          Lieferung:
          {
            ...prevState.Lieferung, [name]: value
          }
        }), () => console.log('handleInput -state Lieferung: ', this.state.Lieferung))
        break;
      default:
        this.setState(prevState => ({
          Default:
          {
            ...prevState.Default, [name]: value
          }
        }), () => console.log('handleInput -state default: ', this.state.Default))
    }
  }
  handleCheckBoxAgb(e) {

    const newSelection = e.target.value;
    let newSelectionArray;
    //debugger;

    if (this.state.Bestellung.agbs.indexOf(newSelection) > -1) {
      newSelectionArray = this.state.Bestellung.agbs.filter(s => s !== newSelection)
    } else {
      newSelectionArray = [...this.state.Bestellung.agbs, newSelection];
    }

    this.setState(prevState => ({
      Bestellung:
        { ...prevState.Bestellung, agbs: newSelectionArray }
    })
    )
  }

  handleCheckBox(e) {   //template

    const newSelection = e.target.value;
    let newSelectionArray;
    //debugger;

    if (this.state.newUser.skills.indexOf(newSelection) > -1) {
      newSelectionArray = this.state.newUser.skills.filter(s => s !== newSelection)
    } else {
      newSelectionArray = [...this.state.newUser.skills, newSelection];
    }

    this.setState(prevState => ({
      newUser:
        { ...prevState.newUser, skills: newSelectionArray }
    })
    )
  }
  callcloseCheckout() {

    // if (window.confirm("Checkout abbrechen? ")) { //funktioniert - muss für Echtbetrieb wieder rein  
    // this.props.closeCheckout();  // ggf. Dev Condition einbringen.
    //  }

  }

  getRowHeight() {
    // Problem: Bestellung muss unter entsprechenden  Context stehen - Parameterisierung ist zu umfangreich...
    let content = <Bestellung handleChange={this.handleInput} state={this.state} />
    let height = measureElement(content).height;
    return height;
  }

  render() {
    // let hight = this.getRowHeight()
    const countrieslength = (this.state.countries.length);
    // console.log("countrieslength: " + JSON.stringify(countrieslength))
    // console.log("Cart: " + JSON.stringify(this.state.cart))
    /* <div  className={this.props.openCheckout ? "_modal-wrapper active" : "_modal-wrapper"} style={{ }}> </div>*/

    return (
      <React.Fragment>
        {countrieslength < 0 ? 
          (<Loading />)
          :
          (<StepperProviderClass startsWith={this.props.startsWith} state={this.state} checkValidation={this.checkValidation} setCurrentStep={this.setCurrentStep} >
            <Stepper>  
              <Stepper.Progress>  
               <Stepper.Stage pos={go.Warenkorb} strKey={states.Warenkorb} />                  
                <Stepper.Stage pos={go.Abrechnung} strKey={states.Abrechnung} />                
                <Stepper.Stage pos={go.Lieferung} strKey={states.Lieferung} />
                <Stepper.Stage pos={go.Lieferart} strKey={states.Lieferart} />
                <Stepper.Stage pos={go.Zahlungsart} strKey={states.Zahlungsart} />
                <Stepper.Stage pos={go.Bestellung} strKey={states.Bestellung} />
                <Stepper.Stage pos={go.Bezahlung} strKey={states.Bezahlung} />
                <Stepper.Stage pos={go.Fertig} strKey={states.Fertig} />               
              </Stepper.Progress>
              <Stepper.Header title="Stepper Heading Test"/> 
              <Stepper.Steps>
              <Stepper.Step strKey={states.Warenkorb}
                  content={<Warenkorb handleChange={this.handleInput} state={this.state}/>}/>                    
                <Stepper.Step strKey={states.Abrechnung}
                  content={<Abrechnung handleChange={this.handleInput} state={this.state} setCurrentStep={this.setCurrentStep}/>}/>
                <Stepper.Step strKey={states.Lieferung}
                  content={<Lieferung handleChange={this.handleInput} state={this.state}/>}/>                
                <Stepper.Step strKey={states.Lieferart} 
                  content={<Lieferart handleChange={this.handleCheckBox} state={this.state}/>}/>
                <Stepper.Step strKey={states.Zahlungsart}
                  content={<Zahlungsart handleChange={this.handleInput} state={this.state}/>}/>
                <Stepper.Step strKey={states.Bestellung}
                  content={<Bestellung handleChange={this.handleInput} state={this.state}/>}/>
                <Stepper.Step strKey={states.Bezahlung}
                  content={<Bezahlung handleChange={this.handleInput} state={this.state}/>}/>
                <Stepper.Step strKey={states.Fertig}
                  content={<Fertig handleChange={this.handleInput} state={this.state} paypal={this.props.retwPaypal}/>}/>
              </Stepper.Steps>              
              <Stepper.Sidebar2 title="Sidebar2" closeCheckout={this.callcloseCheckout.bind(this)}/>
              <Stepper.Footer title="Stepper Footer"/>         
            </Stepper>
          </StepperProviderClass>
          )
        }
      </React.Fragment>
    )
  }
}

export default Checkout;

/*
  
              
               

*/