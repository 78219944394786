import React from 'react';
const TextArea = (props) => {   
// let classname = props.classname ? props.classname : "form-control"
return (
  <div className="form-group">
    <label className="form-label">{props.title}</label>
    <textarea
      className={props.classname ? props.classname : "form-control"}
      name={props.name}
      rows={props.rows}
      cols = {props.cols}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder} 
      />
      <span className="invalid-feedback d-block">{props.errormessage}</span>
  </div>
)

}
export default TextArea;