
import React from 'react';
import errorClass from './lib'
import Input from '../../elements/Input';
import Select from 'react-select';
import { StepperContext } from '../StepperProvider'
//import OpenLoginForm from "../../Header/OpenLoginForm"

import { authContext } from "../../context/AuthContext";
import  AppContext from "../../context/AppContext";

import LoginBar from "../../Header/LoginBar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import  preventTransitions  from "../../Helpers/preventTransition"
const Envelope = <FontAwesomeIcon icon={faEnvelope} />;

function activeClass(active) {
  return (active ? 'btn  form-check-label radio-toolbar-selected' : 'btn form-check-label radio-toolbar-unselected');
}
export default (props) => {


  const [email, setEmail] = React.useState(props.state.Abrechnung.email);
  //const refEmail = React.useRef(props.state.Abrechnung.email);
  const Cx = React.useContext(authContext);
  const appContext = React.useContext(AppContext);
  //let vbEmail = Cx.auth.authenticated ? Cx.auth.username : props.state.Abrechnung.email;
  React.useEffect(() => {
    setEmail(Cx.auth.authenticated ? Cx.auth.email : props.state.Abrechnung.email);
    (props.state.Abrechnung.email = Cx.auth.authenticated ? Cx.auth.email : props.state.Abrechnung.email);  // OP: Testen - ist das so möglich - ohnes setState?
       
    appContext.updateCheckout(true);
  }, [Cx.auth.authenticated])

  const btnGoLieferung = () => (
    <StepperContext.Consumer>
      {(context) => {
        const { handleClickgoLieferung } = context
        return (
          <button type="button" className="btn btn-outline-success" onClick={handleClickgoLieferung.bind(this)}>Andere Lieferadresse angeben</button>
        )
      }}
    </StepperContext.Consumer>
  )

  const { land } = props.state.Abrechnung;
  const handleInputFocus = () =>  // OP: möglichkeit von login Aufruf als Modal?
  (
    Cx.auth.authenticated ? null : setEmail("")
  );

  console.log('Abrechnung - currentstep: ', props.state.currentStep)
  return (
    <React.Fragment>
      {preventTransitions(true)}
      <div className="container-fluid ">
        
        <div className="form-row">
          <div className="col">
            <Input
              required={true}
              type="email"
              formgroup={"displayIcon-wrapper form-group"}
              displayicon={<i title={"E-Mail Adresse erfassen"}>{Envelope}</i>}
              onFocus={handleInputFocus}
              title={'E-mail: *'}
              name={'email'}
              value={Cx.auth.authenticated ? email : props.state.Abrechnung.email}
              placeholder={'E-Mail Adresse '}
              onChange={props.handleChange}
              // disabled={Cx.auth.authenticated} Js. 10.01.2021 - raus bis klar ist ob immer e-mail Adresse ermittelt werden kann... 
              className={`form-control ${errorClass(props.state.valCreditor.email.isInvalid)}`}
              errormessage={props.state.valCreditor.email.message}
            /></div>
          <LoginBar withLogout={false} />
        </div>
        <div className="form-row">
          Anrede:
         </div>
        <div className="form-row">
          <div className="col">
            <div className="radio-toolbar">
              <label className={activeClass(props.state.Abrechnung.anrede === 'Frau')}>
                <input className="form-check-input"
                  style={{ display: "none" }}
                  type="radio"
                  name="anrede"
                  value="Frau"
                  onChange={props.handleChange}
                />
                Frau
            </label>
            </div>
          </div>
          <div className="col">
            <div className="radio-toolbar">
              <label className={activeClass(props.state.Abrechnung.anrede === 'Herr')}>
                <input className="form-check-input"
                  type="radio"
                  name="anrede"
                  value="Herr"
                  onChange={props.handleChange}
                />
                Herr
            </label>
            </div>
          </div>
          <span className="invalid-feedback d-block">{props.state.valCreditor.anrede.message}</span>
          <div>
          </div>

        </div>
        <div className="form-row">
          Titel:
         </div>
        <div className="form-row">
          <div className="col">
            <div className="radio-toolbar">
              <label className={activeClass(props.state.Abrechnung.titel === 'Dr.')}>
                <input className="form-check-input"
                  style={{ display: "none" }}
                  type="radio"
                  name="titel"
                  value="Dr."
                  onChange={props.handleChange}
                />
                Dr.
              </label>
            </div>
          </div>
          <div className="col">
            <div className="radio-toolbar">
              <label className={activeClass(props.state.Abrechnung.titel === 'Prof.')}>
                <input className="form-check-input"
                  type="radio"
                  name="titel"
                  onChange={props.handleChange}

                  value="Prof."
                />
                Prof.
              </label>
            </div>
          </div>
          <div className="col">
            <div className="radio-toolbar">
              <label className={activeClass(props.state.Abrechnung.titel === 'Prof. Dr.')}>
                <input className="form-check-input"
                  type="radio"
                  name="titel"
                  onChange={props.handleChange}
                  value="Prof. Dr."
                />
                Prof. Dr.
              </label>
            </div>
          </div>
          <div className="col">
            <div className="radio-toolbar">
              <label className={activeClass(props.state.Abrechnung.titel === '')}>
                <input className="form-check-input"
                  type="radio"
                  name="titel"
                  onChange={props.handleChange}
                  value=""
                />&nbsp;
              </label>
            </div>
          </div>
          <div>
          </div>
        </div>
        <Input inputtype={'text'}
          title={'Vorname: *'}
          name={'vorname'}
          value={props.state.Abrechnung.vorname}
          placeholder={'Vorname '}
          onChange={props.handleChange}
          className={`form-control ${errorClass(props.state.valCreditor.vorname.isInvalid)}`}
          errormessage={props.state.valCreditor.vorname.message}
        />
        <Input inputtype={'text'}
          title={'Nachname: *'}
          name={'nachname'}
          value={props.state.Abrechnung.nachname}
          placeholder={'Nachname '}
          onChange={props.handleChange}
          className={`form-control ${errorClass(props.state.valCreditor.nachname.isInvalid)}`}
          errormessage={props.state.valCreditor.nachname.message}
        />
        <Input inputtype={'text'}
          title={'Firmenname (optional):'}
          name={'firmenname'}
          value={props.state.Abrechnung.firmenname}
          placeholder={'Firmenname - optional '}
          onChange={props.handleChange}
        />
        <div className="form-row">
          <div className="col">
            <Input inputtype={'text'}
              title={'Strasse: *'}
              name={'strasse'}
              value={props.state.Abrechnung.strasse}
              placeholder={'Strasse '}
              onChange={props.handleChange}
              className={`form-control ${errorClass(props.state.valCreditor.strasse.isInvalid)}`}
              errormessage={props.state.valCreditor.strasse.message}
            />
          </div>
          <div className="col">
            <Input inputtype={'text'}
              title={'Hausnummer: *'}
              name={'hausnummer'}
              value={props.state.Abrechnung.hausnummer}
              placeholder={'Hausnummer '}
              onChange={props.handleChange}
              className={`form-control ${errorClass(props.state.valCreditor.hausnummer.isInvalid)}`}
              errormessage={props.state.valCreditor.hausnummer.message}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col">
            <Input inputtype={'text'}
              title={'Postleitzahl: *'}
              name={'postleitzahl'}
              value={props.state.Abrechnung.postleitzahl}
              placeholder={'Postleitzahl '}
              onChange={props.handleChange}
              className={`form-control ${errorClass(props.state.valCreditor.postleitzahl.isInvalid)}`}
              errormessage={props.state.valCreditor.postleitzahl.message}
            />
          </div>
          <div className="col">
            <Input inputtype={'text'}
              title={'Ort: *'}
              name={'ort'}
              value={props.state.Abrechnung.ort}
              placeholder={'Ort '}
              onChange={props.handleChange}
              className={`form-control ${errorClass(props.state.valCreditor.ort.isInvalid)}`}
              errormessage={props.state.valCreditor.ort.message}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col">
            <div className="form-group">
              <label htmlFor={'land'}> {'Land: *'} </label>
              <Select
                name={'landAbrechnung'}
                value={land}
                onChange={props.handleChange}
                options={props.state.countries}
                getOptionLabel={(option) => option.de}
                getOptionValue={(option) => option.CountryId}
              />
            </div>
          </div>
          <div className="col">
            <Input inputtype={'text'}
              title={'Region:'}
              name={'region'}
              value={props.state.Abrechnung.region}
              placeholder={'Bundesland / Region / Gemeinde / Kanton (optional) '}
              onChange={props.handleChange}
            />
          </div>
        </div>
        <Input inputtype={'text'}
          title={'Telefonnummer für Rückfragen:'}
          name={'telefonnummer'}
          value={props.state.Abrechnung.telefonnummer}
          placeholder={'wird auf Wunsch auf das Etikett gedruckt zwecks Liefererleichterung'}
          onChange={props.handleChange}
        />
        <div className="form-group">
          {btnGoLieferung()}
        </div>
      </div>
    </React.Fragment>
  )


}


