import React from 'react';
import '../scss/components/_modali.scss';
// Gsk: react button looks like a link button
/*
const ButtonStyle = {
  borderRadius: '3px',
  background: 'rgb(3, 174, 243)',
  color: 'white',
  border: 'none',
  padding: '.5rem 1rem',
  fontWeight: 700,
  borderBottom: '2px solid rgb(2, 143, 199)',
};
// https://stackoverflow.com/questions/1367409/how-to-make-button-look-like-a-link
const LinkStyle = {
  background: 'none!important',
  border: 'none',
  padding: '0!important',
 // optional
  fontFamily: 'arial, sans-serif',
  // input has OS specific font-family
  color: '#069',
  textDecoration: 'underline',
  cursor: 'pointer',
};
*/

// https://stackoverflow.com/questions/4628958/how-to-make-a-submit-button-display-as-a-link
// style={LinkStyle}
const LinkButton = ({ handleClick, children }) => (
  <button className="linkbutton" type="button"  onClick={handleClick}>
    {children}
  </button>
);

export default LinkButton;
