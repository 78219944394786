// https://stackoverflow.com/questions/37463832/how-to-play-pause-video-in-react-without-external-library

// OP: 14.09.20 Passwort per Button anzeigen, feststellen wenn passwort falsch - oder immer 
// Grosstaste gedrückt?
import React from 'react';
import Input from '../elements/Input';
import googleLogo from "./googleLogo.svg";
import { GoogleLogin } from 'react-google-login'
//import TwitterLogin from 'react-twitter-auth';
import FacebookLoginWithButton from "./Fb/src/facebook-with-button"  //'react-facebook-login';
//import InstagramLogin from 'react-instagram-login';    // wird per Facebook Login durchgeführt????
import jwt_decode from 'jwt-decode';
import miniLogo from "../assets/miniWaitStateReactLogo.svg";


//import '../scss/components/_modal.scss'  //  OP: weo ist scss Modul? in scssX - leeres File! 24.11.20
import FormValidator from '../Helpers/FormValidator';
import errorClass from '../Checkout/Steps/lib'
/** Context */

import config from './config.json';

import axios from 'axios';
import { lF } from '../Checkout/States'

import ReactIsCapsLockActive from '@matsun/reactiscapslockactive'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEnvelope } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;
const Envelope = <FontAwesomeIcon icon={faEnvelope} />;


const akt = {
    signin: "1",
    register: "2",
    forgot: "3",
    gast: "4",

}




function activeClass(active) {
    // console.log("active: " + active)
    return (active ? 'btn  form-check-label radio-toolbar-selected' : 'btn form-check-label radio-toolbar-unselected');
}

// https://anthonyjgrove.github.io/react-google-login/?path=/info/google-login-button--default-button

/*
 * return das eigentliche Anmeldeformaular
 * @param   {string} onOption  setzt Text statt Icon Ausgabe
 * @param   {string} closeLogionForm übergibt toggle Funktion zum Schliessen des Dialogs.
 * 
 */
function LoginForm(props) { // Mit Grossbuchstaben beginnen!
    console.log(props);
    const [passwordShown, setPasswordShown] = React.useState(false);
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const passwordMatch = (repeatPassword, data) => {
        // debugger;
        return data.password === repeatPassword
    }

    const validUsername = new FormValidator([
        {
            field: 'username',
            method: 'isEmail',
            validWhen: true,
            message: 'Das ist keine gülige E-mail Adresse.'
        },
        {
            field: 'password',
            method: 'isEmpty',
            validWhen: false,
            message: 'Passworteingabe fehlt'
        },
        {
            field: 'repeatPassword',
            method: 'isEmpty',
            validWhen: true,
            message: ''
        },

    ]);
    const validForgotPassword = new FormValidator([
        {
            field: 'username',
            method: 'isEmpty',
            validWhen: false,
            message: 'E-mail wird benötigt.'
        },
        {
            field: 'username',
            method: 'isEmail',
            validWhen: true,
            message: 'Das ist keine gülige E-mail Adresse.'
        },
        {
            field: 'password',
            method: 'isEmpty',
            validWhen: true,
            message: ''
        },
        {
            field: 'repeatPassword',
            method: 'isEmpty',
            validWhen: true,
            message: ''
        },

    ]);
    const validRegisterUser = new FormValidator([
        {
            field: 'username',
            method: 'isEmpty',
            validWhen: false,
            message: 'E-mail wird benötigt.'
        },
        {
            field: 'username',
            method: 'isEmail',
            validWhen: true,
            message: 'Das ist keine gültige e-mail Adresse.'
        },

        {
            field: 'password',
            method: 'isEmpty',
            validWhen: false,
            message: 'Passwort wird gebraucht.'
        },
        {
            field: 'repeatPassword',
            method: 'isEmpty',
            validWhen: false,
            message: 'Passwortbestätigung wird benötigt.'
        },
        {
            field: 'repeatPassword',
            method: passwordMatch,   // notice that we are passing a custom function here
            validWhen: true,
            message: 'Passwort and Passwortbestätigung nicht identisch.'
        }
    ]);


    const initialState = {
        option: akt.signin,
        username: "",
        password: "",
        repeatPassword: "",
        isSubmitting: false,  // OP: noch nicht eingebunden - Quelle suchen...
        errorMessage: "",
        messageFromServer: "",
    };


    const [option, setOption] = React.useState("1");
    const [data, setData] = React.useState(initialState);
    const [validation, setValidation] = React.useState(validUsername.SetToValid())  // müssen alle initialisiert werden...

    //let history = useHistory();  //instead of the HDC withRouter() OP: als Beispiel voerst drin lassen 


    React.useEffect(() => {
        if (props.onOption) {
            callSetOption(props.onOption)
        }
        else {
            if (props.setOption) {
                callSetOption(props.setOption)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.onOption, props.setOption])

    const forgotPassword = () => {

        axios
            .post('/forgotPassword', {
                email: data.username,     // Erfassungsfeld für beides ist username!

            })
            .then(response => {
                console.log(response.data);
                if (response.data === 'Email is invalid' || response.data === "Email is required") {
                    setData({
                        ...data,
                        isSubmitting: false,
                        errorMessage: "E-mail nicht gefunden - ungültiges Format oder fehlt!"
                    });
                }
                if (response.data === 'recovery email sent') {  // erfolgreich
                    setData({
                        ...data,
                        isSubmitting: false,
                        errorMessage: null,
                        messageFromServer: "E-Mail für Kennwortänderung erfolgreich versendet.",
                        // username: "",  --> damit Anwender seine e-mail Adresse sieht...
                        password: "",
                        repeatPassword: "",
                    });
                    setOption("1")
                }

            })
            .catch(error => {
                console.log(error);
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: error.message
                });
            });

    };
    const registerUser = () => {

        axios
            .post('/registerUser', {

                username: data.username,  // email Adresse
                email: data.username,     // email explizt auf dem Server abspeichern ... Erfassungsfeld für beides ist username!
                password: data.password,
            })
            .then(response => {
                console.log(response.data);
                if (response.data === 'Benutzer bzw. E-Mail Adresse schon vergeben') {
                    let info = data.username + ": " + response.data
                    setData({
                        ...data,
                        isSubmitting: false,
                        errorMessage: info,
                        //username: "",
                    });
                }
                if (response.data.message === 'user created') {  // erfolgreich
                    setData({
                        ...data,
                        isSubmitting: false,
                        errorMessage: "",
                        messageFromServer: "Welcome E-mail versendet! - Bitte anmelden...",
                        // username: "",                        
                        password: "",
                        repeatPassword: "",
                    });
                    setOption("1")
                }
            })
            .catch(error => {
                console.log(error);
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: error.message
                });
            });

    };
    const loginUser = () => {

        axios.post('/api/loginUser', {
            username: data.username,
            password: data.password,
        }).then(response => {
            console.log(response.data);
            if (response.data.mId) {
                //debugger;
                console.log(response.data);
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: response.data.info
                });
            }
            else {

                const decoded = jwt_decode(response.data.token); // Test
                // Anstelle von: localStorage.setItem('JWT', response.data.token);
                /*
                 auth.setAuthStatus(
                     {
                         avatar: decoded.avatar,
                         token: response.data.token.replace("Bearer ", ""),
                         authenticated: true,
                         id: decoded.Id,
                         email: decoded.Email,
                         username: decoded.UserName,  // OP prüfen, warum unbelegt...
                         roles: decoded.roles
                     }
                 );
                 */

                const confToken =
                {
                    avatar: decoded.avatar,
                    token: response.data.token.replace("Bearer ", ""),  // replace unnötig!?
                    authenticated: true,
                    id: decoded.Id,
                    email: decoded.Email,
                    username: decoded.UserName,
                    roles: decoded.roles

                }

                //console.log(decoded)
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: "",
                    messageFromServer: "",
                    username: "", // wird e-mail befüllt...                      
                    password: "",
                    repeatPassword: "",
                });
                if (props.lfMode === lF.checkout) {  // OP: prüfen ggf. NICHT mehr notwendig...
                    props.openCheckout();
                }
                props.closeLoginForm(confToken);  // 03.08.20: Zweig ok...
                //setClean(true);
            }
        }).catch(error => {
            console.log(error);
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: error.message
            });
        });




    }
    const handleFormSubmit = e => {
        // OP: Test - nehme hier oder woanders mal e.preventDefault() raus...
        // https://www.mediaevent.de/javascript/event-handler-default-verhindern.html

        e.preventDefault();
        // https://tylermcginnis.com/react-router-programmatically-navigate/
        // https://blog.bitsrc.io/must-know-concepts-of-react-router-fb9c8cc3c12
        // https://dev.to/projectescape/programmatic-navigation-in-react-3p1l
        /*  history.push({
             pathname: '/paypalsuccess',
            startsWith : { stage: states.Lieferung, co: 2 }
           })
      
            */
        // debugger;
        let lvalidation = null;
        switch (option) {
            case akt.gast:
                lvalidation = validRegisterUser.validate(data)
                break;
            case akt.register:
                lvalidation = validRegisterUser.validate(data)
                break;
            case akt.forgot:
                lvalidation = validForgotPassword.validate(data)
                break;
            default:  // akt.signin
                lvalidation = validUsername.validate(data)
        }
        setValidation(lvalidation)  // globalisieren...
        // OP: switch case je nach Option --- wenn nötig...
        // https://blog.logrocket.com/a-guide-to-usestate-in-react-ecb9952e406c/


        if (lvalidation.isValid) {

            console.log(validation);
            setData({
                ...data,
                isSubmitting: true,
                errorMessage: "",
                messageFromServer: "",
            });

            switch (option) {

                case akt.register:
                    registerUser();
                    break;
                case akt.forgot:
                    forgotPassword();
                    break;
                case akt.gast:
                    registerUser();
                    break;
                // OP regitsterGast(); -> feine Nuancen fehlen noch ()

                default:  // akt.signin
                    loginUser();
            }
        }

    }
    const handleClose = event => {
        props.closeLoginForm();
    }


    const handleInputChange = event => {
        setData({
            ...data,
            [event.target.name]: event.target.value
            // Wichtig: Input name="repeatPassword" muss state Variable data.repeatPassword genau entsprechen!!!!  ID im Input field notwendig?
        });
    };



    const onFailureGoogle = (error) => {
        setData({
            ...data,
            isSubmitting: false,
            errorMessage: "Login mit Google - Meldung: " + error.error + "! ... Cookies, bzw. Cookies für Drittanbieter erlaubt? - Bitte Browsereinstellungen prüfen - Danke.",
            messageFromServer: "",
        });
        console.log(error);  //OP: Test provozieren, damit error evaluiert werden kann...
    };


    const facebookComponentClicked = () => { // unnötig nur als Test rein...
        console.log("Clicked!")
   
      
    }

    const responseFacebook = (response) => {     
        const clientPicture = response.picture.data.url  // Workaround 02.01.21: --> wird nur noch bei App Key clientseitig geliefert - https://developers.facebook.com/blog/post/2020/08/04/Introducing-graph-v8-marketing-api-v8
        console.log(response);
        const APIURL = window.location.origin; // new URL(current_url);  // "http://localhost:4000"; // OP: globalisieren - 
        // OP: const baseUrl = req.protocol + "://" + req.get("host");
        socialfetch("Facebook", APIURL + '/api/v1/auth/facebook', response, clientPicture)
    }
    const socialfetch = (name, uri, response, clientPicture) => {
        setData({
            ...data,
            isSubmitting: true,
            errorMessage: "",
            messageFromServer: "",
        });
        const tokenBlob = new Blob([JSON.stringify({ access_token: response.accessToken, clientPicture: clientPicture }, null, 2)], { type: 'application/json' });
        const options = {
            method: 'POST',
            body: tokenBlob,
            mode: 'cors',
            cache: 'default'
        };  
        console.log(options, options.mode)    
        fetch(uri, options).then(res => {
            // HTTP header names are case-insensitive!!!!
            const token = res.headers.get('Authorization');
            if (token) {
                res.json()
                    .then(response => {
                        if (token && response.success) {
                            //console.log(response.user)
                            //console.log(token);
                            // https://stackoverflow.com/questions/31399950/decoding-jwt-tokens-without-the-secret

                            const decoded = jwt_decode(token);
                            const confToken =
                            {
                                avatar:  name==="Facebook" ? clientPicture : decoded.avatar,
                                token: token.replace("Bearer ", ""),  // replace unnötig!?
                                authenticated: true,
                                id: decoded.id,
                                email: decoded.email,
                                username: decoded.name,
                                roles: decoded.roles

                            }

                            props.closeLoginForm(confToken);

                        }
                        else {
                            setData({
                                ...data,
                                isSubmitting: false,
                                errorMessage: name + " login fehlgeschlagen!",
                                messageFromServer: "",
                            });
                        }
                    }).catch(function (error) {
                        console.log(error);
                        setData({
                            ...data,
                            isSubmitting: false,
                            errorMessage: "Unberkanter Netzwerkfehler - " + name + " login fehlgeschlagen!",
                            messageFromServer: "",
                        });
                    });
            }
            else {
                // if (res.status === 401 || res.status === 500) {
                    setData({
                        ...data,
                        isSubmitting: false,
                        errorMessage: name + ": " + res.statusText,
                        messageFromServer: "",
                    });
                    //console.log(token)
               // }
            }
        })
    }

    const responseGoogle = (response) => {
        console.log(response)
        const APIURL = window.location.origin; // new URL(current_url);  // "http://localhost:4000"; // OP: globalisieren - 
        // OP: const baseUrl = req.protocol + "://" + req.get("host");
        socialfetch("Google", APIURL + '/api/v1/auth/google', response, null)
    }
    /*
    const twitterResponse = (response) => {
        console.log(response);
    };
    */
    const callSetOption = nr => {
        setData(initialState);  // abstrakt gehalten, stetzt auch Option, deshalb setOption explitzt danach aufrufen...
        setOption(nr);
    }

    const evalSec = () => { 
     const domainName =  window.location.hostname.toLocaleLowerCase();
      //const status =  process.env.NODE_ENV;
      var clientID = null;
      switch (domainName) {
        case "it-vergabe.de":
          clientID = config.FACEBOOK_APP_ID_It_Vergabe         
          break;
        case "kunstgalerie-hieke.de":
          clientID = config.FACEBOOK_APP_ID_Kunstgalerie_Hieke     
          break;
        default:  // localhost...
          clientID = config.FACEBOOK_APP_ID       
      } 
  
      return clientID;
    }

    //console.log(new Date().toLocaleTimeString() + " bin in loginForm.js")
    return (
        <div className="">
            <div className='card'>
                <button type="button" className="close" onClick={handleClose}>&times;</button>
                <div className="logincontainer">
                    <header >
                        <div className={'header-headings ' + (option === "1" ? 'sign-in' : option === "2" ? 'sign-up' : option === "3" ? 'forgot' : 'gast')}>
                            <span>Anmelden </span>
                            <span>Registrieren</span>
                            <span>Als Gast bestellen</span>
                            <span>Passwort zurücksetzen</span>
                        </div>
                    </header>
                    <h5>Nutze Deinen bevorzugten Account:</h5>
                    <div className="form-row">
                        <div style={{ margin: 5 }} className="col visible" >
                            <GoogleLogin
                                clientId={config.GOOGLE_CLIENT_ID}
                                render={renderProps => (
                                    <button id="customBtn" className="customBtn" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                        <img src={googleLogo} className="svgIcon" alt="logo" />
                                        <span className="buttonText">Login mit Google</span>
                                    </button>
                                )}
                                onSuccess={responseGoogle}
                                onFailure={onFailureGoogle}
                                // is an alias for specifying that you have no subdomains that will access the cookie
                                cookiePolicy={'single_host_origin'}>
                            </GoogleLogin>
                        </div>
                    </div>
                    {/*
                    <div className="form-row">
                        <div className="col" style={{ margin: 5 }} >
                            <TwitterLogin
                                loginUrl="http://localhost:4000/api/v1/auth/twitter"
                                onFailure={onFailure} onSuccess={twitterResponse}
                                requestTokenUrl="http://localhost:4000/api/v1/auth/twitter/reverse"
                            />
                        </div>
                    </div>*/}

                    <div className="form-row">
                        <div style={{ margin: 5 }} className="col visible" >
                            <FacebookLoginWithButton
                                appId={evalSec()}
                                returnScopes={true}
                                authType="rerequest"
                                scope="public_profile,email"
                                autoLoad={false}
                                fields="id,name,email,picture"  // kein Komma  hinten dran - Facebook  meldet Fehler!!!
                                onClick={facebookComponentClicked}
                                callback={responseFacebook}
                                icon="fa-facebook" />
                        </div>

                    </div>
                    {/* // OP: 02.09.20: offen --- ggf. nochmals über Berta@Schatral.de versuchen....
                        // ohne Basic Display probieren, da basic display hinzugefügt wurde
                           - hat aber keine Priorität. 
                           Facebook sagt Authorizieren nur mit Facebook login....
                           also lasse es!!!!
                    <div className="form-row">
                        <div className="col" style={{ margin: 5 }} >
                            <InstagramLogin
                                clientId={config.instagramAuth}
                                buttonText="Login"
                                onSuccess={responseInstagram}
                                onFailure={responseInstagram}
                            />
                        </div>
                    </div> */}
                    <p className="login-line">
                        <span > oder </span> {/*style={{ padding: "0px 10px " }} */}
                    </p>
                    <div className="form-row">
                        <div className="col" style={{ padding: 5 }} >
                            <div className="radio-toolbar">
                                <label className={activeClass(option === "1")}>
                                    <input className="form-check-input"
                                        style={{ display: "none" }}
                                        type="radio"
                                        name="option"
                                        value="1"
                                        onClick={() => callSetOption("1")}
                                    />
                                Anmelden
                        </label>
                            </div>
                        </div>
                        <div className="col" style={{ padding: 5 }} >
                            <div className="radio-toolbar">
                                <label className={activeClass(option === "2")}>
                                    <input className="form-check-input"
                                        style={{ display: "none" }}
                                        type="radio"
                                        name="option"
                                        onClick={() => setOption("2")}
                                        value="2"
                                    />
                                Registrieren
                            </label>
                            </div>
                        </div>


                    </div>

                    <div className="form-row"> {/* ... doch drin lassen 11.08.20  */}
                        <div className="col" style={{ padding: 5 }} >
                            <div className="radio-toolbar">
                                <label className={activeClass(option === "4")}>
                                    <input className="form-check-input"
                                        style={{ display: "none" }}
                                        type="radio"
                                        name="option"
                                        onClick={() => callSetOption("4")}
                                        value="4"
                                    />
                                    Als Gast bestellen
                                </label>
                            </div>
                        </div>

                        <div className="col" style={{ padding: 5 }} >
                            <div className="radio-toolbar">
                                <label className={activeClass(option === "3")}>
                                    <input className="form-check-input"
                                        style={{ display: "none" }}
                                        type="radio"
                                        name="option"
                                        onClick={() => callSetOption("3")}
                                        value="3"
                                    />
                        Passwort vergessen
                    </label>
                            </div>
                        </div>
                    </div>

                    <form className="account-form" onSubmit={handleFormSubmit}>
                        <div className={'account-form-fields ' + (option === akt.signin ? 'sign-in' : option === akt.register ? 'sign-up' : option === akt.forgot ? 'forgot' : 'gast')} >
                            <Input name="username"
                                formgroup={"displayIcon-wrapper form-group"}
                                displayicon={<i title={"E-Mail Adresse erfassen"}>{Envelope}</i>}
                                type="email"
                                placeholder="E-Mail Adresse"
                                required={true}
                                value={data.username}
                                onChange={handleInputChange}
                                className={`form-control ${errorClass(validation.username.isInvalid)}`}
                                errormessage={validation.username.message}
                            />
                            <Input name="password"
                                formgroup={"displayIcon-wrapper form-group"}
                                displayicon={<i title={"Passwort anzeigen"} onClick={togglePasswordVisiblity}>{eye}</i>}
                                type={passwordShown ? "text" : "password"}
                                placeholder="Passworteingabe"
                                required={option === akt.signin || option === akt.register || option === akt.gast ? true : false}
                                disabled={option === akt.forgot ? true : false}
                                value={data.password}
                                onChange={handleInputChange}
                                className={` form-control ${errorClass(validation.password.isInvalid)}`}
                                errormessage={validation.password.message}
                            />
                            <Input name="repeatPassword"
                                type="password"
                                placeholder="Wiederholung Passworteingabe"
                                required={option === akt.register || option === akt.gast ? true : false}
                                disabled={option === akt.forgot ? true : false}
                                value={data.repeatPassword}
                                onChange={handleInputChange}
                                className={`form-control ${errorClass(validation.repeatPassword.isInvalid)}`}
                                errormessage={validation.repeatPassword.message}
                            />
                        </div>
                        <button disabled={data.isSubmitting} className="btn btn-outline-success" style={{ height: "40px" }} type="submit">
                            {data.isSubmitting ? (<img className="spinner" src={miniLogo} alt="loading icon" />) :
                                (option === akt.signin ? 'Anmeldung' : option === akt.register ? 'Registrieren' : option === akt.forgot ? 'Passwort zurücksetzen' : 'Als Gast fortfahren')
                            }
                        </button>
                        <br />
                        {validation.username.isInvalid && (
                            <span className="form-error">{validation.username.message}</span>
                        )}
                        {data.errorMessage && (
                            <span className="form-error">{data.errorMessage}</span>
                        )}
                        {data.messageFromServer && (
                            <span className="form-error">{data.messageFromServer}</span>
                        )}
                        <ReactIsCapsLockActive>
                            {active => <span className="form-warning">{active ? 'Shift-Taste ist gedrückt' : ''}</span>}
                        </ReactIsCapsLockActive>
                        <br></br>
                    </form>

                    {/*React.createElement("footer", null)*/}
                </div>
            </div>
        </div>
    );
}
//    {active => <span>{active ? 'Shift-Taste ist gedrückt' : ''}</span>}
export default LoginForm;

/* socialFetch:
google:
avatar:'https://lh4.googleusercontent.com/-c_gxm-mOz5g/AAAAAAAAAAI/AAAAAAAAAAA/AMZuuckrbtYeoiuDU5l2vs9kT-rFv-e0QA/s96-c/photo.jpg'
email:'info@schatral.de'
exp:1606734466
iat:1606730866
id:'2897dad0-eacc-11ea-af08-cf63f85464f5'
name:'info@schatral.de'
roles:'Benutzer'

Facebook:
avatar:'https://graph.facebook.com/v2.6/106610047841617/picture?type=large'
email:'berta@schatral.de'
exp:1606734572
iat:1606730972
id:'2aa74910-ebac-11ea-acaf-01b707014f62'
name:'berta@schatral.de'
roles:'Hauptbenutzer'

*/