import React, { useState, useContext } from 'react';
import InputR from '../../elements/InputR';
import Measure from 'react-measure';
import { StepperContext } from '../StepperProvider'
import CheckBox from '../../elements/CheckBox'
// import errorClass from './lib'
//import linkButton from "../../modal/LinkButton"
import ShowAgbsDialog from '../../modal/showAgbs'
import preventTransitions from "../../Helpers/preventTransition"
import AppContext from "../../context/AppContext"



const Bestellung = (props) => {
    //const [selectedOption, setSelectedOption] = useState('option1');
    const [dimensions, setDimensions] = useState({ width: -1, height: -1, });
    const [agbOptions,] = useState([' ']); // Hinweis: Blank muss rein! //Ich habe die AGB und die Widerrufsbelehrung Ihres Shop gelesen und bin mit deren Geltung einverstanden']

    const amountContext = useContext(AppContext);
    /* 
    Do you even need a constructor?
    The constructor comes in very handy, but sometimes it’s just too much. If you aren’t planning on maintaining state in your component and aren’t binding any event handlers, you don’t need to define a constructor at all.
    https://alligator.io/react/constructors-with-react-components/
    */
   /*
    constructor(props) {
        super(props);

        this.state = {
            selectedOption: 'option1',
            pdfWiderruf: "", // Op sollte pdf mit Widerruftext sein...
            activeWiderruf: false,
            activeAgb: false,
            dimensions: {
                width: -1,
                height: -1,
            },
            agbOptions: [' '] // Hinweis: Blank muss rein! //Ich habe die AGB und die Widerrufsbelehrung Ihres Shop gelesen und bin mit deren Geltung einverstanden']
        };

     
    }
    */

   

    // https://www.dyn-web.com/tutorials/forms/radio/onclick-onchange.php
    // https://stackoverflow.com/questions/45463188/onchange-or-onclick-for-radio-buttons-and-check-boxes-in-reactjs
    
    // OP: TEST: button Clicks as function or as constant...
    const btnGoWarenkorb = () => (
        <StepperContext.Consumer>
            {(context) => {
                const { handleClickgoWarenkorb } = context
                return (

                    <button type="button" className="linkbutton"  onClick={() => handleClickgoWarenkorb()}>Ändern</button>
                )
            }}
        </StepperContext.Consumer>
    )
    const btnGoLieferung = () => (
        <StepperContext.Consumer>
            {(context) => {
                const { handleClickgoLieferung } = context
                return (

                    <button type="button" className="linkbutton"  onClick={() => handleClickgoLieferung()}>Ändern</button>
                )
            }}
        </StepperContext.Consumer>
    )
    const btnGoLieferart = () => (
        <StepperContext.Consumer>
            {(context) => {
                const { handleClickgoLieferart } = context
                return (
                    <button type="button" className="linkbutton" onClick={handleClickgoLieferart}>Ändern</button>
                )
            }}
        </StepperContext.Consumer>
    )
    const btnGoAbrechnung = () => (
        <StepperContext.Consumer>
            {(context) => {
                const { handleClickgoAbrechnung } = context
                return (
                    <button type="button" className="linkbutton" onClick={handleClickgoAbrechnung}>Ändern</button>
                )
            }}
        </StepperContext.Consumer>
    )
    const btnGoZahlungsart = () => (
        <StepperContext.Consumer>
            {(context) => {
                const { handleClickgoZahlungsart } = context
                return (
                    <button type="button" className="linkbutton" onClick={handleClickgoZahlungsart}>Ändern</button>
                )
            }}
        </StepperContext.Consumer>
    )

    const handleLocalChange = (event) => {
        props.handleChange(event)
    }

 

    // style={{ width: "20px", height: "20px"}}
    const agbs_und_Wiederruf = () => {
        
        return (

            <div>

                <ShowAgbsDialog link={"Allgemeine Geschäftsbedingungen"} searchKey={'AGB Kunstgalerie-Hieke'} title={"Allgemeine Geschäftbedingungen"} /> und <ShowAgbsDialog link={'Widerrufsbelehrung'} searchKey={'Widerruf'} title={"Widerrufsbelehrung"} />
                <div>
                    <br />
                    Bitte beachten Sie für Ihre Bestellung auch unsere Widerrufsbelehrung.
                </div>
                <div className="form-row" style={{ flexWrap: 'nowrap' }} >
                    <div className="col-xs-1">
                        <CheckBox
                            name={'agbs'}
                            classname={"form-check-input-override"}
                            options={agbOptions}
                            selectedOptions={props.state.Bestellung.agbs}
                            handleChange={handleLocalChange}
                            errormessage={props.state.valBestellung.agbs.message}
                            scrollToError={true}
                        />
                    </div>
                    <div className="col-xs-11" style={{ margin: "5px", padding: "15px" }}>
                        Ich habe die  <ShowAgbsDialog link={"AGB"} searchKey={'AGB Kunstgalerie-Hieke'} title={"Allgemeine Geschäftbedingungen"} /> und die <ShowAgbsDialog link={'Widerrufsbelehrung'} searchKey={'Widerruf'} title={"Widerrufsbelehrung"} /> Ihres Shop gelesen und bin mit deren Geltung einverstanden
                    </div>
                </div>

            </div>
        )

    }
    const rechnungsAnschrift = () => {
        return (
            <div>
                <div className="form-row">

                    <div className="col" style={{ fontWeight: "900" }}>
                        Anschrift:
                        </div>
                    <div className="col text-right">
                        {btnGoAbrechnung()}
                    </div>
                </div>
                <div className="form-row">
                    <div className="col">
                        <InputR inputtype={'text'}
                            title={'Anrede'}
                            name={'anrede'}
                            value={props.state.Abrechnung.anrede}
                            readOnly
                        />
                    </div>
                    <div className="col">
                        <InputR inputtype={'text'}
                            title={'Titel'}
                            name={'titel'}
                            value={props.state.Abrechnung.titel}
                            readOnly
                        />
                    </div>

                </div>
                <div className="form-row">
                    <div className="col">
                        <InputR inputtype={'text'}
                            title={'Vorname'}
                            name={'vorname'}
                            value={props.state.Abrechnung.vorname}
                            readOnly
                        />
                    </div>
                    <div className="col">
                        <InputR inputtype={'text'}

                            title={'Nachname'}
                            name={'nachname'}
                            value={props.state.Abrechnung.nachname}
                            readOnly
                        />
                    </div>
                </div>

                <InputR inputtype={'text'}
                    title={'Firmenname'}
                    name={'firmenname'}
                    value={props.state.Abrechnung.firmenname}
                />
                <div className="form-row">
                    <div className="col">
                        <InputR inputtype={'text'}
                            title={'Strasse:'}
                            name={'strasse'}
                            value={props.state.Abrechnung.strasse}
                        />
                    </div>
                    <div className="col">
                        <InputR inputtype={'text'}
                            title={'Nr.:'}
                            name={'hausnummer'}
                            value={props.state.Abrechnung.hausnummer}

                        />
                    </div>
                </div>

                <div className="form-row">
                    <div className="col">
                        <InputR inputtype={'text'}
                            title={'Postleitzahl'}
                            name={'postleitzahl'}
                            value={props.state.Abrechnung.postleitzahl}
                        />
                    </div>
                    <div className="col">
                        <InputR inputtype={'text'}
                            title={'Ort:'}
                            name={'ort'}
                            value={props.state.Abrechnung.ort}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col">
                        <InputR inputtype={'text'}
                            title={'Land:'}
                            name={'land'}
                            value={props.state.Abrechnung.ort}

                        />
                    </div>
                    <div className="col">
                        <InputR inputtype={'text'}
                            title={'Region:'}
                            name={'region'}
                            value={props.state.Abrechnung.region}

                        />
                    </div>
                </div>
                <InputR inputtype={'text'}
                    title={'Telefonnummer:'}
                    name={'telefonnummer'}
                    value={props.state.Lieferung.telefonnummer}
                />
            </div>)
    }
    const lieferAnschrift = () => {
        return (
            <div  >
                <div className="form-row ">
                    <div className="col" style={{ fontWeight: "900" }}>
                        Andere Lieferanschrift:
                </div>
                    <div className="col text-right">
                        {btnGoLieferung()}
                    </div>
                </div>
                <div className="form-row">
                    <div className="col">
                        <InputR inputtype={'text'}
                            title={'Anrede'}
                            name={'anrede'}
                            value={props.state.Lieferung.anrede}
                            readOnly
                        />
                    </div>
                    <div className="col">
                        <InputR inputtype={'text'}
                            title={'Titel'}
                            name={'titel'}
                            value={props.state.Lieferung.titel}
                            readOnly
                        />
                    </div>
                    <div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col">
                        <InputR inputtype={'text'}
                            title={'Vorname'}
                            name={'vorname'}
                            value={props.state.Lieferung.vorname}
                            readOnly
                        />
                    </div>
                    <div className="col">
                        <InputR inputtype={'text'}

                            title={'Nachname'}
                            name={'nachname'}
                            value={props.state.Lieferung.nachname}
                            readOnly
                        />
                    </div>
                    <div>
                    </div>
                </div>

                <InputR inputtype={'text'}
                    title={'Firmenname'}
                    name={'firmenname'}
                    value={props.state.Lieferung.firmenname}
                />
                <div className="form-row">
                    <div className="col">
                        <InputR inputtype={'text'}
                            title={'Strasse:'}
                            name={'strasse'}
                            value={props.state.Lieferung.strasse}
                        />
                    </div>
                    <div className="col">
                        <InputR inputtype={'text'}
                            title={'Nr.:'}
                            name={'hausnummer'}
                            value={props.state.Lieferung.hausnummer}

                        />
                    </div>
                </div>

                <div className="form-row">
                    <div className="col">
                        <InputR inputtype={'text'}
                            title={'Postleitzahl'}
                            name={'postleitzahl'}
                            value={props.state.Lieferung.postleitzahl}
                        />
                    </div>
                    <div className="col">
                        <InputR inputtype={'text'}
                            title={'Ort:'}
                            name={'ort'}
                            value={props.state.Lieferung.ort}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col">
                        <InputR inputtype={'text'}
                            title={'Land:'}
                            name={'land'}
                            value={props.state.Lieferung.ort}

                        />
                    </div>
                    <div className="col">
                        <InputR inputtype={'text'}
                            title={'Region:'}
                            name={'region'}
                            value={props.state.Lieferung.region}

                        />
                    </div>
                </div>
                <InputR inputtype={'text'}
                    title={'Telefonnummer:'}
                    name={'telefonnummer'}
                    value={props.state.Lieferung.telefonnummer}
                />

            </div>
        )
    }
    const lieferArt = () => {
        return (
            <div>
                <div className="form-row ">

                    <div className="col" style={{ fontWeight: "900" }}>
                        Versandart:
                        </div>
                    <div className="col text-right">
                        {btnGoLieferart()}
                    </div>
                </div>
                <div className="form-row">
                    <div className="col">
                        <InputR inputtype={'text'}
                            className={"form-control form-control-sm"}
                            title={'Kurierdienst'}
                            name={'lieferdienst'}
                            value={props.state.Versandart.condition}
                            readOnly
                        />
                    </div>

                    <div>
                    </div>
                </div>

            </div>
        )
    }
    const zahlungsArt = () => { // OP: style funktioniert noch  nicht...
        return (
            <div >
                <div className="form-row ">
                    <div className="col" style={{ fontWeight: "900" }}>
                        Zahlungsart:
                        </div>
                    <div className="col text-right">
                        {btnGoZahlungsart()}
                    </div>
                </div>
                <div className="form-row" >
                    <div className="col">
                        <InputR style={{ overflowWrap: "break-all", }} inputtype={'text'}
                            title={'Bezahlsystem'}
                            name={'bezahlsystem'}
                            value={props.state.Zahlungsart.bezahlart}
                            readOnly
                        />
                    </div>

                    <div>
                    </div>
                </div>

            </div>
        )
    }


    const warenKorb = (betrag) => { // https://dev.to/abdulbasit313/an-easy-way-to-create-a-customize-dynamic-table-in-react-js-3igg

        return (
            <div>
                <div className="form-row ">
                    <div className="col" style={{ fontWeight: "900" }}>
                        Warenkorb
                    </div>
                    <div className="col text-right">
                    {btnGoWarenkorb()}
                </div>
                </div>


                <table className="warenkorb form-control-sm">

                    <tbody>

                        {renderTableHeader()}
                        {renderTableData()}
                        {renderTableFuss(betrag)}

                    </tbody>
                </table>
            </div>
        ) // Versand per Kurier - Lieferzeit ca. 3 - 4 Tage bei Versand innerhalb von Deutschland	
    }
    const renderTableHeader = () => {

        return (
            <tr>
                <th> Gemälde</th>
                <th> Id / SKU</th>
                <th> Produkt</th>
                <th> Preis</th>
            </tr>

        )
    }

    const renderTableFuss = (betrag) => {
        
        const amount = amountContext.amountGetter;
        amountContext.updateCheckout(true);
        // OP: betrifft display - Warum ".cart-item_we" notwendig - ist Double von _header.scss ohne display überschreibt .cart-item im .warenkorb
        let formatPrice = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 });
        let Lieferkosten = 
        Number.parseFloat(amount.details.shipping) +
        Number.parseFloat(amount.details.insurance) +
        Number.parseFloat(amount.details.handling_fee);       
       // let Gesamtbetrag = betrag + Lieferkosten
        return (
            <React.Fragment>
                <tr className="cart-item_we" style={{ border: "2px solid #ccc", }}>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: "right", }}> Summe</td>
                    <td className="product-price" style={{ textAlign: "right", }}>{formatPrice.format(betrag)}</td>
                </tr>
                <tr className="cart-item_we" style={{ border: "2px solid #ccc", }}>

                    <td colSpan='3' style={{ textAlign: "right", }}> Versand per Kurier - Lieferzeit ca. 3 - 4 Tage innerhalb von Deutschland	</td>
                    <td className="product-price" style={{ textAlign: "right", }}>{formatPrice.format(Lieferkosten)}</td>
                </tr>

                <tr className="cart-item_we" style={{ border: "3px solid ", }}>
                    <td>enthaltene Mwst 7 %</td>
                    <td>{formatPrice.format(amount.details.tax)}</td>
                    <td style={{ textAlign: "right", border: "3px solid", fontWeight: "bolder" }}> Gesamtbetrag</td>
                    <td className="product-price" style={{ textAlign: "right", fontWeight: "bolder" }} >{formatPrice.format(amount.total)}</td>
                </tr>

            </React.Fragment>
        )
    }


    const renderTableData = () => {
        let formatPrice = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 });

        // https://gomakethings.com/converting-strings-to-numbers-with-vanilla-javascript/
        return props.state.cart.map((product) => {  //OP: image fehlt noch...
            const { sku, name } = product //  destructuring : id, sku, name,  quantity, price, currency, image, PictureUrl
            return (
                <tr className="cart-item_we" key={sku}>
                    <td> <img className="product-image" src={product.Images[0].original} alt={product.name} /></td>
                    <td>{sku}</td>
                    <td className="product-name" >{name}</td>
                    <td className="product-price" style={{ textAlign: "right", }}>{formatPrice.format(product.price)}</td>
                </tr>
            )
        })
    }

    const callResize = (contentRect) => {  // dimensions wird innerhalb der Bestellung nicht genutzt - zeigt mir die Verwendung des spread operator innerhalb der useState function
        // https://github.com/souporserious/react-measure
        setDimensions({...dimensions, width: contentRect.bounds.width, height: contentRect.bounds.height});
        
        console.log('Width and hight: ', JSON.stringify(contentRect.bounds, null, 2))
        console.log('Width and hight: ', JSON.stringify(dimensions, null, 2))
    }

    let content;

    let betrag = props.state.cart.map(product => Number(product.price)).reduce((total, price) => { return total + price });
    console.log('currentstep: ', props.state.currentStep)
    console.log('currentstep: ', props.state.Bestellung.agbs)
    // https://github.com/souporserious/react-measure
    content = (
        <Measure bounds onResize={callResize} >
            {({ measureRef, contentRect }) => (
                
                <div className="checkout-override">
                    <div ref={measureRef} className="container-fluid">

                        <div className="form-row ">

                            <div className="col" style={{ border: "2px solid #ccc", padding: 5, margin: 5, }}>
                                {rechnungsAnschrift()}
                            </div>
                            <div className="col" style={{ border: "2px solid #ccc", padding: 5, margin: 5, }}>
                                {lieferAnschrift()}
                            </div>
                        </div>
                        <div className="form-row " >
                            <div className="col" style={{ border: "2px solid #ccc", padding: 5, margin: 5, }} >
                                {lieferArt()}
                            </div>
                            <div className="col" style={{ border: "2px solid #ccc", padding: 5, margin: 5, }} >
                                {zahlungsArt()}
                            </div>
                        </div>

                        <div className="form-row ">
                            <div className="col" style={{ border: "2px solid #ccc", padding: 5, margin: 5, }} >
                                {warenKorb(betrag)}
                            </div>
                        </div>
                        <div className="form-row" >

                            <div className="col" style={{ border: "2px solid #ccc", padding: 5, margin: 5, }} >

                                {agbs_und_Wiederruf()}

                            </div>

                        </div>

                    </div>
                </div>
            )}
        </Measure>);
    return (
        <React.Fragment>
            {preventTransitions(true)}
            {content}
        </React.Fragment>
    )
}
export default Bestellung  