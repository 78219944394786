import React, { useState, useRef, useContext } from 'react';
//import searchGreen from '../assets/search-green.png';
import search from '../assets/search.png';
import back from '../assets/back.png'
import AppContext from "../context/AppContext"

export default () => {
  const [mobileSearch, setMobileSearch] = useState(false);
  const seachBoxRef = useRef("");  // https://www.robinwieruch.de/react-ref
  const productContext = useContext(AppContext);
  // Search by Keyword
  const handleSearch = (event) => {
    const test = event.target.value;
    productContext.setFilterExpr(event.target.value)
    console.log("handleSearch - event.target.value: " + test);
  }

  const handleMobileSearch = (e) => {
    e.preventDefault();
    setMobileSearch(true);
  }

  const handleSearchNav = (e) => {
    e.preventDefault();
    setMobileSearch(false);

    seachBoxRef.current.value = ""; // OP
    productContext.setFilterExpr("");
    // handleMobileSearch(e);

  }
  const handleSubmit = (e) => {
    e.preventDefault();  // verhindere Defaultverhalten - Seite neu laden. jetzt hier unnötig - durch Effect....
  }
  //debugger;
  return (
    <React.Fragment>
      <div className="search">
        <a className="mobile-search" href="/" onClick={(e) => handleMobileSearch(e)} >
          <img src={search} alt="Suche..." />
        </a>
        <form action="#" method="get" className={mobileSearch ? "search-form active" : "search-form"}>
          <a className="back-button" href="/" onClick={(e) => handleSearchNav(e)}>
            <img src={back} alt="back" />
          </a>
          <input
            type="search"
            ref={seachBoxRef}
            placeholder="Inhalt durchsuchen"
            className="search-keyword"
            onChange={(event) => handleSearch(event)}
          />
          <div className="search-button" onClick={(e) => handleSubmit(e)} />
        </form>
      </div>
    </React.Fragment>
  )
};
