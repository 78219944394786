import React, { useEffect}  from 'react';
import { Link } from 'react-router-dom'
//mport { preventTransitions } from "../../Helpers/preventTransition"



const Fertig = (props) => {


 let uuid = window.localStorage.getItem("uuidv1")

  // https://blog.8bitzen.com/posts/how-to-use-componentWillMount-with-react-hooks
  useEffect(() => {

    return function cleanup() {
      window.localStorage.removeItem("uuidv1");
    };

  }, [uuid])
  
  /* 
  Do you even need a constructor?
  The constructor comes in very handy, but sometimes it’s just too much. If you aren’t planning on maintaining state in your component and aren’t binding any event handlers, you don’t need to define a constructor at all.
  https://alligator.io/react/constructors-with-react-components/
  */
  console.log(props.retwPaypal)
  
  const paypalSuccess = props.paypal.paypalsuccess
  const ok =  (paypalSuccess === "true" && props.paypal.uuid === uuid)
  const error = props.paypal.error;
 
  const displayOk = () =>
  {
    return (
      <React.Fragment>
     
      <form className="container-fluid">
     
      <h1> Bestellung erfolgreich durchgeführt. </h1>
      <br />
      <br />
      <h2>Vielen Dank für Ihren Bestellung!</h2>
      <br />
      <h2>Eine Bestellbestätigung wurde per e-mail an Sie versendet. </h2>
      <Link className="stepperClose" to="/">
        Zurück zur Galerie
     </Link>
    </form>
    </React.Fragment>
    )
  }
  const displayNotOk = () =>
  {
    const displayError = error === "abort" ? "Paypal ist durch Benutzer beendet worden" : "Zuordungsfehler"
   
    return (
      <form className="container-fluid">
      <h1> Bestellung nicht erfolgreich durchgeführt. </h1>
      <br/>
      <h2>
        {displayError}
      </h2>
      <Link className="stepperClose" to="/">
        Zurück zur Galerie
     </Link>
     <br/>
     oder
     <br/>
     <Link className="stepperClose" to="/contact">
        Kontaktseite
     </Link>
    </form>
    )
  }
  return (
    <React.Fragment>

      {ok === true ? ( displayOk()) :
      (displayNotOk())}
    </React.Fragment>
    )   
 
}
export default Fertig  