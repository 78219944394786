import React from 'react';
import axios from 'axios';
import Input from '../elements/Input';
import FormValidator from '../Helpers/FormValidator';
import { Link } from 'react-router-dom';
import Loading from '../Checkout/animations/Loading'
import logo from "../assets/miniWaitStateReactLogo.svg";
import OpenLoginForm from '../Header/OpenLoginForm'

export default (props) => {

  const initialState = {
    username: "",
    email: "",
    password: "",
    repeatPassword: "",
    errorMessage: null,
    messageFromServer: null,
    updated: false,
    isLoading: true,
    error: false,
  };
  const passwordMatch = (repeatPassword, data) => (data.password === repeatPassword)
  const validResetPassword = new FormValidator([
    {
      field: 'username',
      method: 'isEmpty',
      validWhen: false,
      message: 'E-mail wird benötigt.'
    },
    {
      field: 'username',
      method: 'isEmail',
      validWhen: true,
      message: 'Das ist keine gültige e-mail Adresse.'
    },

    {
      field: 'password',
      method: 'isEmpty',
      validWhen: false,
      message: 'Passwort wird gebraucht.'
    },
    {
      field: 'repeatPassword',
      method: 'isEmpty',
      validWhen: false,
      message: 'Passwortbestätigung wird benötigt.'
    },
    {
      field: 'repeatPassword',
      method: passwordMatch,   // notice that we are passing a custom function here
      validWhen: true,
      message: 'Passwort and Passwortbestätigung nicht identisch.'
    }
  ]);
  const [data, setData] = React.useState(initialState);
  const [validation, setValidation] = React.useState(validResetPassword.SetToValid())

  React.useEffect(() => {
    console.log(props.match.params.token)
    setData({
      ...data,
      isLoading: true,
    });
    axios
      .get('/resetPassword', {
        params: {
          resetPasswordToken: props.match.params.token,
        },
      })
      .then(response => {
        console.log(response);
        if (response.data.message === 'password reset link a-ok') {
          setData({
            ...data,
            isSubmitting: false,
            isLoading:false,
            username: response.data.userName,
          });
        } else {
          setData({
            ...data,
            messageFromServer: response.data,
            updated: false,
            isLoading: false,
            error: true,
          });

        }
      })
      .catch(error => {
        console.log(error.data);
      });
// https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.token]);

  const handleInputChange = event => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    const validation = validResetPassword.validate(data)
    setValidation(validation);
    if (validation.isValid) {
      setData({
        ...data,
        isSubmitting: true,
        error: null,
        messageFromServer: null
      });
      axios
        .put('/updateForgottenPassword', {
          username: data.username,
          password: data.password,
        })
        .then(response => {
          console.log(response.data);
          if (response.data === "user not in database") {
            setData({
              ...data,
              messageFromServer: response.data,
              updated: false,     
              isLoading: false,
              error: true,
            });
          }
          if (response.data.message === 'password updated') {
            setData({
              ...data,
              messageFromServer: response.data,
              updated: true,    
              isLoading: false,
              error: false,
            });
          }

        })
        .catch(error => {
          setData({
            ...data,
            messageFromServer: error.message,         
            isLoading: false,
            error: true,
          });
          console.log(error);
        });
    }
  };

  const errorClass = (ungueltig) => {
    return (ungueltig ? 'is-invalid' : '');
  }
  const errorOut = (messageFromServer) => {
    if (messageFromServer === "user not in database") 
      { return "Passworterneuerung schon durchgeführt oder abgelaufen." }
    else {
      return "Passwort zurücksetzen zeitlich ausgelaufen."
      // default error handler:  keine Server errormessage ausgeben - diese hier ist etwas benutzerfreundlicher. 
      // OP: Serverseite protokollieren, bzw. email an Admin - hierzu einfach '/api/users/updateForgottenPassword
      // anstelle von .put('/updateForgottenPassword', setzen 
      // damit wird der default error handler am Server aufgerufen...
      // 
    }
  }
  const WarningStyle = {
    
    color: 'red',
   
  };
  const renderHasError = () => {
    return (
      <div>
        <div style={WarningStyle}>
        {errorOut(data.messageFromServer)}</div>
        <br/>
        <br/>
        <OpenLoginForm link={"Anmelden"}   />    
        <br/>
        <br/>
        <OpenLoginForm link={"Passwort nochmal zurücksetzen?"} autostart={"3"}  /> 
        <br/>
        <br/>
        <Link to="/" >Startseite</Link>
      </div>
    );
  }

  const renderIsLoading = () => {
    return (
      <React.Fragment>
      <div>Lade Benutzerdaten....</div>  
      <Loading/>
      </React.Fragment>
     
    );
  }
  function renderInputForm() {
    return (
      <div>
        <form className="container-fluid" onSubmit={handleFormSubmit}>
          <Input name="username"
            type="email"
            placeholder="E-Mail Adresse"
            required={true}
            disabled={true}
            value={data.username}
            onChange={handleInputChange}
            className={`form-control ${errorClass(validation.username.isInvalid)}`}
            errormessage={validation.username.message}
          />
          <Input name="password"
            type="password"
            placeholder="Passworteingabe"
            required={true}
            value={data.password}
            onChange={handleInputChange}
            className={`form-control ${errorClass(validation.password.isInvalid)}`}
            errormessage={validation.password.message}
          />
          <Input name="repeatPassword"
            type="password"
            placeholder="Wiederholung Passworteingabe"
            required={true}

            value={data.repeatPassword}
            onChange={handleInputChange}
            className={`form-control ${errorClass(validation.repeatPassword.isInvalid)}`}
            errormessage={validation.repeatPassword.message}
          />
          <button disabled={data.isSubmitting} className="btn btn-outline-success" style={{ height: "40px" }} type="submit">
            {data.isSubmitting ? (<img className="spinner" src={logo} alt="loading icon" />) :
              ('Abschicken - Password aktualisieren')
            }
          </button>
        </form>
      </div>
    );
  }
  function renderUpdated() {
    return (
      <div> 
        <p>
          Das Passwort ist erfolgreich gesetzt worden. Bitte melden Sie sich an.
       </p>  
       <OpenLoginForm link={"Anmelden"} route={"/"} />     
       
       <br/>
       <br/>
       <Link to="/" >Startseite</Link>
      </div>
    );
  }
  return (
    <React.Fragment>
      <div style={{ border: "2px solid #ccc", padding: 5, margin: 5, }}>
        <h4> Passwort zurücksetzen</h4>
        {
          data.isLoading ? renderIsLoading() : data.updated ? renderUpdated() : data.error ? renderHasError() : renderInputForm()
        }
      </div>
    </React.Fragment>)
}

