import React from "react";
import emptyCartIcon from '../assets/empty-cart.png'
const EmptyCart = props => {
  // console.log(props.BeispielProp);
  return (
    <div className="empty-cart">
      <img
        src={emptyCartIcon}
        alt="empty-cart"
      />
      <h2>Ihr Warenkorb enthält derzeit keine Artikel.</h2>
    </div>
  );
};

export default EmptyCart;
