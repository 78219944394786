import ReactDOM from "react-dom";
// https://medium.com/trabe/measuring-non-rendered-elements-in-react-with-portals-c5b7c51aec25
const measureElement = (element, layerId = "measure-layer") => {
  const measureLayer = document.getElementById(layerId);

  const renderedElement = ReactDOM.createPortal(element,measureLayer);

  const height = renderedElement.containerInfo.clientHeight;
  const width = renderedElement.containerInfo.clientWidth;

  ReactDOM.unmountComponentAtNode(measureLayer);

  return { height, width };
};

export default measureElement;