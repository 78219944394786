import React from 'react';
import axios from 'axios';

import Loading from '../Checkout/animations/Loading'

import OpenLoginForm from '../Header/OpenLoginForm'
import jwt_decode from 'jwt-decode';
import { authContext } from '../context/AuthContext';
import LinkButton from '../modal/LinkButton'
import {Link, withRouter } from "react-router-dom"; // Wichtig - Link get über withRouter


export default withRouter( (props) => {
  const auth = React.useContext(authContext);

  const initialState = {   
    errorMessage: null,
    messageFromServer: null,
    updated: false,
    isLoading: true,
    error: false,
  };
 
  const [data, setData] = React.useState(initialState); 
  React.useEffect(() => {
    console.log(props.match.params.token)
    setData({
      ...data,
      isLoading: true,
    });
    axios
      .get('/validateEmail', {
        params: {
          verifyEmailToken: props.match.params.token,
        },
      })
      .then(response => {
        console.log(response);
        if (response.data.success) {
          const decoded = jwt_decode(response.data.token); // Test
          // Anstelle von: localStorage.setItem('JWT', response.data.token);
          auth.setAuthStatus(
            {
              avatar: decoded.avatar,
              token: response.data.token.replace("Bearer ", ""),
              authenticated: true,
              id: decoded.Id,
              email: decoded.Email,
              username: decoded.username,
              roles: decoded.roles
            }
          );
          setData({
            ...data,
            messageFromServer: response.data.message,
            error: false,
            upated: true,
            isLoading: false, 
          });
        } else {
          setData({
            ...data,
            messageFromServer: response.data.message,
            updated: false,
            isLoading: false,
            error: true,
          });

        }
      })
      .catch(error => {
        console.log(error);
        setData({
          ...data,
          updated: false,
          isLoading: false,  
          error: true,
          messageFromServer: error.message
        });
      });
    // https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.token]);

 
  const errorOut = (messageFromServer) => {
    if (messageFromServer === "user not in database") { return "Nicht erfolgreich oder schon durchgeführt." }
    else {
      if (messageFromServer === "Verifikation out of time")
      {  
         return "Verifikation per e-mail ist zeitlich ausgelaufen"        
      }
      else
      return messageFromServer
      
    }
  }
  const WarningStyle = {
    color: 'red',
  };

 
  const handleClick = e => {
    // e.stopPropagation() 
    
    // debugger;
   
   //  e.preventDefault(); // verhindere...
     //  OP:  <Route path="/contact" component={Contact}/> 
    props.history.push('/contact')  // anstelle von  window.location.href - geht über den tiefergestellten BrowserRouter....
  
  }

  const renderHasError = () => {
    return (
      <div>
        <div style={WarningStyle}>
          {errorOut(data.messageFromServer)}
          </div>
        <br />
        <br />
        <LinkButton handleClick={handleClick}>
            Kontaktformular
        </LinkButton> 
        <br />
        <br />       
        <OpenLoginForm link={"Registierung der E-mail-Adresse durchführen"} setOption={"2"} />
        <br />
        <br />
        <Link to="/" >Startseite</Link>
        <br />
        <br />
       </div>
    );
  }

  const renderIsLoading = () => {
    return (
      <React.Fragment>
        <div>Lade Benutzerdaten....</div>
        <Loading />
      </React.Fragment>
    );
  }
  
  function renderUpdated() {
    return (
      <div>
        <p>
          Erfolgreich und angemeldet! 
       </p>
       <Link to="/" >Startseite</Link>
      </div>
    );
  }
  return (
    <React.Fragment>
      <div style={{ border: "2px solid #ccc", padding: 5, margin: 5, }}>
        <h4> E-mail Adresse gegenprüfen</h4>
        {
          data.isLoading ? renderIsLoading() : data.error ? renderHasError() : renderUpdated() 
        }
      </div>
    </React.Fragment>)
})

