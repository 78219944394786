import React from 'react';
import NavMenu from './NavMenuBS'
// import Warenkorb from "./Warenkorb"
// import LoginBar from "./LoginBar"
export default () => {
    return (
        <div className="box header">       
            <NavMenu />           
            {/* 
             <Warenkorb />           
            <LoginBar/> 
            */}       
        </div>
    )
}