import React from 'react';
//import CheckBox from '../../elements/CheckBox'
import preventTransitions from "../../Helpers/preventTransition"
export default function Lieferart(props) {

  console.log('Lieferart - currentstep: ', props.state.currentStep)
    

    return (
      <React.Fragment>
    {preventTransitions(true)}
      <form className="container-fluid"> 
        <h1>
        {props.state.Versandart.condition}
        </h1> {/*
        <CheckBox title={'Lieferart'}
          name={'skills'}
          options={props.state.skillOptions}
          selectedOptions={props.state.newUser.skills}
          handleChange={props.handleChange}
        /> Skill 
      */}
 
      </form>
      </React.Fragment>
    );
  }