import React, { useState, useEffect } from "react";
import Measure from 'react-measure';

import Modali, { useModali } from './modali';
import LinkButton from './LinkButton';
import Iframe from 'react-iframe'
//const current_url = window.location.href;
const APIURL = window.location.origin; // new URL(current_url);  // "http://localhost:4000"; // OP: globalisieren - 

const axios = require("axios");
//const getPdfByName = name => axios.get(`${APIURL}/pdf/getPdfByName/${name}`);

const ShowAgbsDialog = (props) => {  // OP: klären warum es als Objekt übergeben worden ist...
  const einheit = 'px'
  const [pdfPath, setDoc] = useState();
  const [initialized, setInitialized] = useState(false);
  const [dimensions, setDimensions] = useState({width: -1, height: -1,});



  useEffect(() => {   // replace componentDidMount, componentDidUpdate, etc.
    if (!initialized) {
      // const response = getPdfByName("AGB");

      // debugger
      //console.log('Param.Width and hight: ', JSON.stringify(props.dimensions, null, 2))
      axios({
        method: "POST",
        url: "/pdf/getPdfByName",
        data: { SearchKey: props.searchKey }   // Fester Wert - muss parameterisiert werden. Server muss aktuelle AGB raussuchen

      }).then((response) => {
        // debugger
        setDoc(response.data.pdfPath);
        setInitialized(true);
      })
      // debugger

    }
  }, [initialized, props.searchKey]);

  const [roundTripModal, toggleExampleModal] = useModali({
    animated: true,
    wide: true,
    title: props.title, //'Allgemeine Geschäftsbedingungen',
    overlayClose: true,
    onHide: () => {},    // void - oder z.B. =>alert('I\'m now hidden'),
    closeButton: true,   // Vorerst drin lassen

  });
 
  return (
    <Measure bounds onResize={contentRect => {setDimensions({  width: contentRect.bounds, height: contentRect.bounds,  });        
    // console.log('Dialog width and hight: ', JSON.stringify(contentRect.bounds, null, 2))
    }}>
      {({ measureRef }) => (
        <React.Fragment >
          <LinkButton handleClick={toggleExampleModal}>
            {props.link}
          </LinkButton>     
            <div ref={measureRef} style={{display: "none"}}>
            <Modali.Modal   {...roundTripModal}>
              <Iframe  url={`${APIURL}/${pdfPath}` }
                width={`${dimensions.width}${einheit}`} //"450px"
                height= "650px"// {`${props.dimensions.height-100}${einheit}`} // "650px"
                id="myId"
                className="myClassname"
                position="relative" />
            </Modali.Modal> 
            </div>  
        </React.Fragment>)}
    </Measure>
  );
};
export default ShowAgbsDialog;

