import React from 'react';

import { authContext } from '../context/AuthContext';

import Modali, { useModali } from '../modal/modali';
import LoginForm from '../LoginForm/loginForm'
import LinkButton from '../modal/LinkButton'
import {withRouter } from "react-router-dom"; // Wichtig - Link get über withRouter

/* OP: 13.08.20: Checkout OpenLoginFormAuto ersetzen
 <OpenLoginForm link={"Passwort vergessen?"} autostart={"3"}  /> 
 https://www.inkoop.io/blog/a-guide-to-js-docs-for-react-js/
*/
/**
 * return Aufruf des Anmeldeformaular
 * @param   {string} link   setzt Text statt Icon Ausgabe
 * @param   {string} autostart startet autoaufruf mit setzen der Dialogoption
 */
export default withRouter( (props) => {
  const [firstModal, toggle] = useModali({
    animated: true,
    large: false,
    closeButton: false,
    overlayClose: false,
    onHide: () => { }, // return void, no function null and nothing...   onHide: () => alert('I\'m now hidden'),
  });

  React.useEffect(() => {
    if (props.autostart) {
      toggle()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.autostart])
  const auth = React.useContext(authContext);
  const callCloseLoginForm = (confToken) => {
    toggle();
    if (confToken !== undefined) {
      let timeoutHack;
      timeoutHack = setTimeout(() => {
        auth.setAuthStatus(
          {
            avatar: confToken.avatar,
            token: confToken.token.replace("Bearer ", ""),
            authenticated: true,
            id: confToken.id,
            email: confToken.email,
            username: confToken.username,  // OP prüfen, warum unbelegt...
            roles: confToken.roles
          }
        );        
        clearTimeout(timeoutHack);        
      }, 100);
    
    }
    //
  }
  return (
    <React.Fragment>
      {props.blind ? (null) : props.link ?
        (
          <LinkButton handleClick={toggle}>
            {props.link}
          </LinkButton>
        ) :
        (
          <h1 onClick={toggle}>
            <i id="fa-user" className="fa fa-user" title={"Anmelden ..."}></i>
          </h1>
        )
      }
      <Modali.Modal {...firstModal}>
        <LoginForm onOption={props.autostart} setOption={props.setOption} closeLoginForm={callCloseLoginForm} />
      </Modali.Modal>
    </React.Fragment>
  );
});

