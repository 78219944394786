import React, { useContext } from 'react';
//import CheckBox from '../../elements/CheckBox'
import preventTransitions from "../../Helpers/preventTransition"
import AppContext from "../../context/AppContext"
import { withRouter } from "react-router-dom"

var buttonStyle2 = {
  textalign: 'left'
};

export default withRouter( (props) => {
  const [count, setCount] = React.useState(0) 
  const cartContext = useContext(AppContext);
 
  React.useEffect(() => {
    setCount(cartContext.data.products.filter(product => product.addedToCart).length);
   
  }, [cartContext.data.products])
  

  console.log('Warenkorb - currentstep: ', props.state.currentStep)

  const handleCartUpdate = (id) => {
    const productsState = cartContext.data.products.map(product => ({ ...product, addedToCart: (product.Id === id ? !product.addedToCart : product.addedToCart) }));

    //debugger
    cartContext.updateData(productsState);
    setCount(productsState.filter(product => product.addedToCart).length)
    // cartContext.updateCart(cartContext.data.products.filter(product => product.addedToCart));
    if (productsState.filter(product => product.addedToCart).length === 0)
        {
         props.history.push('/') 
        }
   }

  let cartItems;
  var formatPrice = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 });
  // https://upmostly.com/tutorials/react-filter-filtering-arrays-in-react-with-examples
  cartItems = cartContext.data.products.filter(product => product.addedToCart).map((product, key) => {
    return (
      <li className="cart-item" key={product.sku}>
        <img className="product-image" src={product.Images[0].original} alt={product.name} />
        <div className="product-info">
          <p className="product-name">{product.Title}</p>
          <p className="product-price">{formatPrice.format(product.price)}</p>
        </div>
        {/*
         <div className="product-total">
           <p className="quantity">
             {product.quantity} {product.quantity > 1 ? "Nos." : "No."}{" "}
           </p>
           <p className="amount">{product.quantity * product.price}</p>
         </div> 
         <button
           className="product-remove" 
           style={buttonStyle}
           onClick={this.props.removeProduct.bind(this, product.id)}
           >x</button> 
         */
        }
        <button
          className="product-remove"
          style={buttonStyle2}
          onClick={() => handleCartUpdate(product.Id)}
        >X</button>
      </li>
    );
  });

    return (
      <React.Fragment>
    {count > 0 ? preventTransitions(true, count): null}
    {cartItems}
      </React.Fragment>
    );
  })