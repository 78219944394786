import React from 'react';


const InputR = (props) => {

 	return (  
     <div  className={props.formgroup ? props.formgroup : "form-group-row"}> 
    <label htmlFor={props.name} className="col-sm-2 col-form-label col-form-label-sm">{props.title}</label>
    <textarea 
      className={props.className ? props.className : "form-control form-control-xs"}  // xs  erst sm
      id={props.name}
      name={props.name}
      type={props.inputtype}
      value={props.value}
      readOnly
      placeholder={props.placeholder}    
      {...props} />
       <span className="invalid-feedback">{props.errormessage}</span>
   </div>
   ) 
  
}

export default InputR;