import React from 'react';
import { states, go } from './States';

export const StepperContext = React.createContext();

class StepperProviderClass extends React.Component {
  static Test = 99;  // heist nur das static einmal instanziert wird, der Wert ist aber trotzdem veränderlich - gilt dann für alle Instanzen....

  state = { // static????-no
    first: true,
    stage: states.Warenkorb,  // this.props.startsWith.stage,// 
    co: 0, // this.props.startsWith.co, // 1, // 5,// this.props.startsWith, // 1, //5,
    titel: "Warenkorb", // this.props.startsWith.stage, //"Zahlungsart", //""Anmeldung",
    update: 0,
    withLaddr: false
  }

  componentDidMount()   // test willMount - veraltet.
  {
    ///*   OP: Wieder rein? 28.11.19 / debugger;
    console.log(this.state.first)
    this.setState({ stage: this.props.startsWith.stage })
    this.setState({ co: this.props.startsWith.co })
    this.props.setCurrentStep(this.props.startsWith.stage) // Conserns: Dynamic creation error: 30.11.19 - hinzugefügt...
    //this.setState({ titel: this.props.startsWith.stage })
    this.setState({ update: this.state.update + 1 })
    if (this.props.startsWith.co > 1) {
      this._set_Index(this.props.startsWith.stage)
    }
    //
  }

  render() {
    //OP: Wieder rein? 28.11.19

    //  debugger;
    StepperProviderClass.Test = StepperProviderClass.Test + 1;


    StepperProviderClass.Foo = this.props.startsWith.stage;
    console.log(this.props.startsWith)
    //*/
    return (
      <StepperContext.Provider value={{
        withLaddr: this.state.withLaddr,
        titel: this.state.titel, // this.titel(),  // hier nicht hin -   this.props.startsWith.titel,   
        co: this.state.co,
        stage: this.state.stage,
        checkValidation: this.props.checkValidation,  // ...  - sondern von props übergeben...
        first: false,
        handleClick: () => this.setState({
          //  this.setState({ co: this.state.co + 1 }) HIER NICHT!!!  validation fehlt
          stage: this._go_forward(this.state.stage)
        }),
        handleClickBack: () => this.setState({
          // co: this.state.co - 1,
          //co: (this.state.co > 1 ? this.state.co - 1 :  1),
          stage: this._go_back(this.state.stage),
        }),
        handleClickgoWarenkorb: () => this.setState({
          co: Object.keys(states).indexOf(states.Warenkorb),
          stage: this._set_Index(states.Warenkorb)
        }),
        handleClickgoLieferung: () => this.setState({
          co: Object.keys(states).indexOf(states.Lieferung),
          withLaddr: true,
          stage: this._set_Index(states.Lieferung)
        }),
        handleClickgoAbrechnung: () => this.setState({
          co: go.Abrechnung,
          stage: this._set_Index(states.Abrechnung)
        }),
        handleClickgoZahlungsart: () => this.setState({
          co: go.Zahlungsart,
          stage: this._set_Index(states.Zahlungsart)
        }),
        handleClickgoLieferart: () => this.setState({
          co: go.Lieferart,
          stage: this._set_Index(states.Lieferart)
        })
      }}
      >{this.props.children}
      </StepperContext.Provider>
    )
  }

  _set_Index(state) {
    switch (state) {
      case states.Warenkorb:
        return this.rC(states.Warenkorb);
      case states.Fertig:
        return this.rC(states.Fertig);
      case states.Zahlungsart:
        return this.rC(states.Zahlungsart);
      case states.Abrechnung:
        return this.rC(states.Abrechnung);
      case states.Lieferart:
        return this.rC(states.Lieferart);
      default:
         return this.rC(states.Lieferung);
    }

  }

  titel() {
    if (this.state.first) return this.props.startsWith.stage;
    return this.state.titel;
  }

  rC(rc) {
    let test = Object.keys(states).indexOf(rc);
    console.log("Test states: " + test.toString()) 
    this.setState({ co: Object.keys(states).indexOf(rc)})
    switch (rc) {
      case states.Fertig:
        this.props.setCurrentStep(rc)  //neuen Context setzen
        this.setState({ titel: 'Bestellung abgeschlossen' })
        return (rc);
      case states.Bezahlung:
        this.props.setCurrentStep(rc)
        this.setState({ titel: 'Sichere Bezahlung durchführen' })
        return (rc);
      case states.Bestellung:
        this.props.setCurrentStep(rc)
        this.setState({ titel: 'Bestellung kontrollieren' })
        return (rc);
      case states.Zahlungsart:
        this.props.setCurrentStep(rc)
        this.setState({ titel: 'Sichere Zahlungsart auswählen' })
        return (rc);
      case states.Lieferart:
        this.props.setCurrentStep(rc)
        this.setState({ titel: 'Lieferart auswählen' })
        return (rc);
      case states.Lieferung:
        this.props.setCurrentStep(rc)
        this.setState({ titel: 'Andere Lieferanschrift erfassen' })
        return (rc);
      case states.Abrechnung:
        this.props.setCurrentStep(rc)
        this.setState({ titel: 'Rechnungs- und Lieferanschrift' })
        return (rc);
      default: // case states.Warenkorb:
        this.props.setCurrentStep(rc)
        this.setState({ titel: 'Warenkorb kontrollieren' })
        return (rc);
    }
  }

  _go_forward(stage) {

    if (this.props.checkValidation(this.state.stage).isValid) {
      switch (stage) {
        case states.Lieferung:
          return this.rC(states.Lieferart);
        case states.Lieferart:
          return this.rC(states.Zahlungsart);
        case states.Zahlungsart:
          return this.rC(states.Bestellung);
        case states.Bestellung:
          return this.rC(states.Bezahlung);
        case states.Bezahlung:
          return this.rC(states.Fertig);
        case states.Abrechnung:
          if (this.state.withLaddr) {
            return this.rC(states.Lieferung);
          }
          else {
            return this.rC(states.Lieferart)
          }
        default: // case states.Warenkorb:
          return this.rC(states.Abrechnung);
      }
    }
    //debugger
    return (stage)
  }

  _go_back(stage) {

    switch (stage) {
      case states.Fertig:
        return this.rC(states.Bezahlung);
      case states.Bezahlung:
        return this.rC(states.Bestellung);
      case states.Bestellung:
        return this.rC(states.Zahlungsart);
      case states.Zahlungsart:
        return this.rC(states.Lieferart);
      case states.Lieferung:
        return this.rC(states.Abrechnung);
      case states.Lieferart:
        if (this.state.withLaddr) {
          return this.rC(states.Lieferung);
        }
        else {
          return this.rC(states.Abrechnung);
        }
      default: // case states.Abrechnung:
        return this.rC(states.Warenkorb);
    }
  }
}

// genutzt wird oben - StepperContext 
const StepperConsumer = StepperContext.Consumer
const StepperProvider = StepperContext.Provider // raw,plain - without extensions.
export { StepperProviderClass, StepperProvider, StepperConsumer, }  // anderer Exportweg zwecks Test - kein Defaultexport - export als Funktionsaufruf?