import AppContext from "../../context/AppContext"
import React, { useContext } from 'react';
import axios from "axios";
// https://stackoverflow.com/questions/63311845/unexpected-use-of-confirm-no-restricted-globals
export default (props) => {
    const imageContext = useContext(AppContext);


    const deleteAction = () => {

        const pictureItem = imageContext.pictureItemGetter;
        if (pictureItem && window.confirm("aktuell angezeigtes Bild entladen?") ) {
            axios({
                method: "POST",
                url: "/deleteImage",
                data: { Id: pictureItem.ImageId }   // Fester Wert - muss parameterisiert werden. Server muss aktuelle AGB raussuchen

            }).then((response) => {
                //debugger
                console.log(response);
                props.updImageDelete(pictureItem.ImageId);   
            }).catch((error) => {
                console.log(error);
            });
        }
    };
    return (
        <div>
            <button className="btn btn-outline-success"
                disabled={props.uploading}
                onClick={() => deleteAction()}  >
                angezeigtes Bild entladen
        </button>
        </div>
    );


}
/*

*/