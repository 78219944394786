import React, { useContext, useState, useEffect } from "react"
import AppContext from "../context/AppContext"
import Product from "./Product";
import Modali, { useModali } from '../modal/modali';

import ReactPaginate from 'react-paginate';
import '../scss/ReactPaginate.scss'


export default () => {
    // do not use modali in an "Product" useEffect clean up process (willunoundComponent)  
    const [HinweisModal, toggleBearbeitungsHinweis] = useModali({
        animated: true,
        title: 'Bearbeitungshinweis',
        message: 'Aktuell gemachte Textänderungen verwerfen?',
        buttons: [
            <Modali.Button
                label="Ja"
                isStyleCancel
                onClick={() => CancelModal()}
            />,
            <Modali.Button
                label="Nein"
                isStyleDestructive
                onClick={() => StoreModal()}
            />,
        ],
    });
    const CancelModal = () => {
        toggleBearbeitungsHinweis();
        preparePaging(selected);
    };
    const StoreModal = () => {
        toggleBearbeitungsHinweis();

    };

    const productContext = useContext(AppContext);
    //console.log("ProductList productContent:", productContext)
    const [selected, setSelected] = useState(0)

    const [perPage, setPerPage] = useState(1);
    const [offset, setOffset] = useState(0)
    // const [, setCurrentPage] = useState(0)
    //const [, setPageCount]= useState(Math.ceil(productContext.data.products.length / perPage));
    const [updated, setUpdated] = useState(false)
    //const [currentPage, setCurrentPage] = useState(0)
    const handleUpdated = (updated) => {
        setUpdated(updated)
    }

    const useStateExt = (initialValue, name) => {
        const [value, setValue] = useState(initialValue);
        //console.log(name + ": ", value);
        // imageContext.updatePictureItem(props.items[initialValue]);   // not possible...
        //React.useDebugValue(`${name}: ${value}`);
        return [value, setValue];
    }
    const [productList, setProducts] = useStateExt(productContext.data.products, "productList")
    // const [count,setCount] = useState(productContext.data.products.length)

    //const aktProductList = React.useRef(productContext.data.products);  // Neu: 09.11.20 - static Variable?!

    const searchingFor = (term) => {

        return function (product) {
            //debugger;  
            return product.Title.toLowerCase().includes(term.toLowerCase()) || !term;
        };
    }


    useEffect(() => {  // Initialeffect - notwendig.
        //receiveProductData();
        //debugger;
        const filterExpr = productContext.filterGetter;

        setProducts(productContext.data.products.filter(searchingFor(filterExpr)).slice(offset, offset + perPage));
        //aktProductList.current = productContext.data.products.filter(searchingFor(filterExpr)).slice(offset, offset + perPage)
    }, [productContext.data.products, offset, perPage, productContext.filterGetter, setProducts])



    const handlePageClick = (e) => {
        setSelected(e)
        if (updated) {
            toggleBearbeitungsHinweis(); // goFurther = window.confirm("Aktuelle Änderungen verwerfen?")
        }
        else {
            preparePaging(e);
        }
        //const slice = productContext.data.products.slice(offset, offset + perPage)
        //setProducts(slice);        
        //setPageCount(Math.ceil(productContext.data.products.length / perPage))  
    };
    const preparePaging = (e) => {
        const selectedPage = e.selected;
        //setCurrentPage(selectedPage)  // ---> useState
        setUpdated(false);
        if (window.innerWidth > 992) {   // Austauschen geben feste Bildgrössen - per CSS
            setPerPage(1)
        }
        else (setPerPage(1))
        //setCurrentPage(currentPage)
        setOffset(selectedPage * perPage);
    }



    // console.log(productContext);

    return (
        <React.Fragment>
             <products-wrapper>
            <ReactPaginate
                previousLabel={"Zurück"}
                nextLabel={"Weiter"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(productContext.data.products.length / perPage)}
                marginPagesDisplayed={0}
                pageRangeDisplayed={0}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"} />
            <div className="wrapper">
                <div className="sidebar"></div>
                <div className="sidebar2"></div>
                <div className="content">
                    <div className="products">
                        {/*Warning: Each child in a list should have a unique "key" prop. : key={index}
                             Warning: Unknown: `key` is not a prop. Trying to access it will result in `undefined` being returned. If you need to access the same value within the child component, you should pass it as a different prop. (https://fb.me/react-special-props)
                             Workaround - don`t use reserved word key - I use doubleKey={index} or better index as key
                        */}
                        {
                            productList.map((product, index) =>
                                
                                <Product handleUpdated={handleUpdated}
                                    product={product}
                                    key={product.Id}   // WICHTIG - ohne das hier - durch den Pager kein eindeutiger KEY!!!!
                                    mykey = {index}    // index immer null - da Pager auf Step 1 eingestellt ist ! 
                                />
                            )
                        }
                    </div>
                </div>
            </div>
            <ReactPaginate
            previousLabel={"Zurück"}
            nextLabel={"Weiter"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(productContext.data.products.length / perPage)}
            marginPagesDisplayed={0}
            pageRangeDisplayed={0}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"} />
        </products-wrapper>
        <Modali.Modal {...HinweisModal}/>      

        </React.Fragment>
    )
}

/*

productList.map((product, index) =>

                                    <Product handleUpdated={handleUpdated}
                                        product={product}
                                        key={product.Id}   // WICHTIG - ohne das hier - durch den Pager kein eindeutiger KEY!!!!
                                        mykey={index}    // index immer null - da Pager auf Step 1 eingestellt ist ! 
                                    />
                                )
 <ReactPaginate
                previousLabel={"Zurück"}
                nextLabel={"Weiter"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(productContext.data.products.length / perPage)}
                marginPagesDisplayed={0}
                pageRangeDisplayed={0}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"} />
*/