import React from 'react';


//import ImageGallery from "react-image-gallery";
//import "react-image-gallery/styles/scss/image-gallery.scss";
import ImageGallery from "../components/imagegallery/ImageGallery"
//import "../scss/components/_image-gallery.scss"

import defaultImage from "../assets/images/default.jpg";
//import '../scss/components/_modal.scss'   // im _index-File enthalten  - hier zwecks demo...
const QuickViewProduct = (props) => {
  const images = [];
  //const imagesRef = React.useRef([])  -- kann neben "Ref" auch als static Var eingesetzt werden!

  const extract = props.product.Images;
  for (const idx in props.product.Images) {
    const tt =
    {
      ImageId: extract[idx].Id,    // image-Id
      original: extract[idx].original,
      thumbnail: extract[idx].original,
      originalTitle: props.product.Title
    };
    images.push(tt)
   // imagesRef.current.push(tt);  // static: behält alle bilder gibt keines frei
  }

  // <img src={props.product.PictureUrl} alt="..." onClick={toggleQuickViewModal} style={{ height: "300px", width: "300px", borderStyle: "groove", borderColor: "gold", borderWidth: "10px" }} />
  // https://medium.com/@MRWwebDesign/responsive-images-the-sizes-attribute-and-unexpected-image-sizes-882a2eadb6db
  // style={{  width: "600px", borderStyle: "groove", borderColor: "gold", borderWidth: "10px" }} 
  return (
    <React.Fragment>
      <div className="quick-view-wrapper">    
      
          <div className="image-galerie" >
            <ImageGallery
              //onClick={toggleQuickViewModal}
              //renderRightNav={renderRightNav2}
              thumbnailPosition="left"   // OP: left, right funktioniert nocht nicht...
              items={images} //{imagesRef.current}
              defaultImage={defaultImage}
              showBullets={true}
              showIndex={false}
              showThumbnails={true}
              lazyLoad={false}   // OP: true funktioniert noch nicht...
              showPlayButton={false}
            //renderCustomControls={someComponent}
            />
          </div>

        
      </div>


    </React.Fragment>
  );
};
export default QuickViewProduct;
/*
 width: 600px;
    border-Style: groove;
    border-Color: gold;
    border-Width: 10px;

*/