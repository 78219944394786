import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//import { NavMenu }  from './Header/NavMenu'

import Header from './Header'
import Shop from "./Shop/ProductList"
import Footer from './Footer/index'
import ImpressumPage from "./sites/Impressum";
import AgbsPage from "./sites/Agbs";
import CheckoutPage from "./sites/checkout";
import ResetPasswordPage from "./sites/ResetPassword";
import ValidateEmail from "./sites/ValidateEmail";
import ContactForm from './Contact/ContactForm'
import VersionPage from './sites/Versionsangabe'
import ShowDocumentPage from "./sites/ShowDocument"
const AppRouter = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
       <Header />
      <div className="container">
        <Switch>
          <Route exact path="/showdocument/:name" component={ShowDocumentPage} />
          <Route exact path="/validateEmail/:token" component={ValidateEmail} />
          <Route exact path="/resetPassword/:token" component={ResetPasswordPage} />
          <Route path="/version"><VersionPage /></Route>
          <Route path="/contact"><ContactForm /></Route>
          <Route path="/showagbs"><AgbsPage /></Route>
          <Route path="/impressum"><ImpressumPage /></Route>
          <Route path="/checkout"><CheckoutPage /></Route>
          <Route path="/cart"> </Route>
          <Route path="/"><Shop /> </Route>
        </Switch>
      </div>
      <Footer />
    </Router>
  );
};
export default AppRouter;