import React, { useContext} from "react";
import Checkout from "../Checkout/Checkout";
import { states, go, /*lF */ } from '../Checkout/States';
import AppContext from "../context/AppContext"
import { authContext } from "../context/AuthContext"
import OpenLoginFormAuto from "../Header/OpenLoginFormAuto"
const queryString = require('query-string');
export default () => { // functional component written in ES6  
  const appContext = useContext(AppContext);
  const Cx = useContext(authContext);
  let cartItems = appContext.data.products.filter(product => product.addedToCart)  // muss drin bleiben!!!
  // let uuid = appContext.payIdGetter;  // geht nicht... warum?
  console.log(window.localStorage.getItem("uuidv1")); // 20.09.2020 -  geht!
  // let tada = appContext.tada;
  // console.log(new Date().toLocaleString() +  ":  uuid , tada = " + uuid)
  // OP: search mit uuid vergleichen
  React.useEffect(() => {
    console.log(appContext);
    return function cleanup() {
      appContext.updateCheckout(false);
    };

  }, [])
  const search = window.location.search.toString();
  let queryKeys = queryString.parse(search)
  console.log(queryKeys)
  const login = Cx.auth.authenticated;
  // console.log(appContext.data.products)
  console.log(login);
  return (
    <React.Fragment>
      {login === false & search === "" ?
        (<OpenLoginFormAuto />)
        :
        (null)}
      {search === '' ?
        (<Checkout startsWith={{ stage: states.Warenkorb, co: go.Warenkorb }} cart={cartItems} />)
        :
        (<Checkout startsWith={{ stage: states.Fertig, co: go.Fertig }} retwPaypal={queryKeys} cart={cartItems} />)}
    </React.Fragment>
  )
};
