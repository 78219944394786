import React, { Component } from 'react';

// https://stackoverflow.com/questions/50203369/class-constructor-cannot-be-invoked-without-new-typescript-with-commonjs
import HourGlass from './animations/HourGlass.js'
import styles from './styles.js'
import { StepperContext } from './StepperProvider.js'
// OP- Test Echsystem - Resouces failed - hier explizit angegeben müssen
import checked2 from './animations/checked2.svg'
import padlock from './animations/padlock.svg'

// pos, strKey kommnt aus  <Stepper.Stage pos={1} strKey={states.Lieferung} />	
// von StepperContext.Provider bereitgestellt... 

// Wichtig: keine Komentare innerhalb von .Consumer und const Stage!!!!!!!
// optimized production build will fail!!!!
export const Stage = ({ pos, strKey }) => (	
	<StepperContext.Consumer>
		{para => ( 
			<div style={circleStyle(strKey, pos, para.stage, para.co, para.withLaddr)}>
				{renderIcon(strKey, pos, para.stage, para.co, para.withLaddr)}
			</div>
		)}
	</StepperContext.Consumer>
);


const renderIcon = (strKey, pos, stage, co, withLaddr) => {
	if (pos === co) {
		//		console.log("HourGlass")
		return (
			<React.Fragment>
				<div>
					<div style={{
						position: 'absolute',
						left: '-13px', // '-10px',
						top: '-25px',
						width: '15px',
						fontSize: '12px',
						fontWeight: 700,
					}}>{strKey}</div>

					{<HourGlass size="30px" />}
				</div>
			</React.Fragment>
		)
	} else if (pos > co) {
		//	console.log("locked")
		return (
			<div>
				<div style={{

					fontSize: '12px',
					fontWeight: 700,
				}}
				>{strKey}</div>
				<img
					style={{
						position: 'absolute',
						left: '20px',
						top: '20px',
						width: '15px',
					}}
					src={padlock}
					alt="locked"
				/>
			</div>
		);
	}
	//	console.log("checked")
	return (
		<div>
			<div style={{
				position: 'absolute',
				left: '-25px', // '-10px',
				top: '-25px',
				width: '15px',
				fontSize: '12px',
				fontWeight: 700,
			}}
			>{strKey}</div>
			<img className="animated fadeIn"
				style={{ width: '12px' }}
				src={checked2}
				alt="checked" />
		</div>
	);
};

const circleStyle = (strKey, pos, stage, co, withLaddr) => {


	if (co === pos) {
		return { ...styles.circle, ...styles.circleActive };
	} else if (co > pos) {
		return { ...styles.circle, ...styles.circleComplete };
	}
	return styles.circle;
};

export default class Progress extends Component {
	render() {
		return (
			<div className="boxGrid sidebarGrid" style={{ minHeight: Window.minheight }}>
				<div style={styles.progressContainer2}>
					{this.props.children}
				</div>
			</div>
		)
	}
}
