import React, { useState, useEffect } from 'react';
import { AppContextProvider } from "./context/AppContext"
import AuthContextProvider from "./context/AuthContext";
import axios from 'axios'
import AppRouter from './AppRouter';
import { CookieBanner } from "./components/react-cookie-law" //
//import Shop from "./Shop/ProductList" // ---> zum testen eingebracht
//import ImpressumPage from "./sites/Impressum";

//import 'bootstrap/dist/css/bootstrap.min.css';
// Reihenfolge ist wichtig!
import 'bootstrap/scss/bootstrap.scss';  // 17.07.19 lt. package.json Version "bootstrap": "^4.3.1", ersetzt CDN in index.html.
import './scss/App.scss'; // App.scss überschreibt bootstrap.scss

function App() {
  const [checkout, setCheckout] = useState(false);
  const [amount, setAmount] = useState(null);
  const [pictureItem, setPictureItem] = useState(null);
  const [legalSize, setLegalSize] = useState(false);
  const [products, setProducts] = useState([]);
  const [cartBounce, setcartBounce] = useState(false); // OP: ggf ohne setting... Nein!
  const [uuidv1, setUuidv1] = useState("");
  const [filter, setFilter] = useState("");
  const [cart, setCart] = useState([]);
  const [editorMode, setEditorMode] = useState(false);
  useEffect(() => {

    // loadReCaptcha('6LftIcAZAAAAALe18YMTWIiwcW2rGontA3vRHZBR');
    axios.get('/api/cart/showProductsRel')
      .then((response) => {
        setProducts(response.data.map(product => ({ ...product, addedToCart: false })))
      }).catch((error) => {  // PseudoError z.b. status(400) - programmierer gesteuert...
        console.error(error);
      });

  }, [])
  const Cookiebanner = () => {
    return (
      <CookieBanner
        message="Diese Website verwendet Cookies"
        wholeDomain={true}
        onAccept={() => { }}
        onAcceptPreferences={() => { }}
        onAcceptStatistics={() => { }}
        onAcceptMarketing={() => { }}
        privacyPolicyLinkText='Datenschutzerklärung'
        necessaryOptionText='Notwendig'
        preferencesOptionText='Präferenzen'
        statisticsOptionText='Statistiken'
        marketingOptionText='Marketing'
        acceptButtonText='Bestätigen'
        declineButtonText='Ablehnen'
        policyLink="/showdocument/Datenschutz Kunstgalerie-Hieke.de"
      // showDeclineButton = {true}
      // dismissOnScroll = {true}
      />
    )
  }
  const ptrCx = { // Pointer auf Context
    checkoutGetter: (checkout),  
    updateCheckout: (bool) => setCheckout(bool), 


    amountGetter: (amount),  
    updateAmount: (ptr) => setAmount(ptr), 


    editorModeGetter: (editorMode),  // Get:
    updateEditorMode: (value) => setEditorMode(value), // brauchen wir das überhaupt???? JA!!


    pictureItemGetter: (pictureItem),  // Get:
    updatePictureItem: (value) => setPictureItem(value), // brauchen wir das überhaupt???? JA!!


    legalSizeGetter: (legalSize),  // Get:
    updateLegalSize: (value) => setLegalSize(value), // brauchen wir das überhaupt???? JA!!


    payIdGetter: (uuidv1),  // Get: cartContext.tada.cartBounce
    updateUuidv1: (uid) => setUuidv1(uid), // brauchen wir das überhaupt???? JA!!

    // TS: https://stackoverflow.com/questions/51189388/typescript-spread-types-may-only-be-created-from-object-types
    data: { products },
    updateData: (data) => setProducts(data),   // calling convention (cc): context.updateData(data)

    cart: { cart },
    updateCart: (data) => setCart(data),

    filterGetter: (filter),     // property: getter
    setFilterExpr: (filter) => setFilter(filter), //property: setter

    tada: { cartBounce },  // Get: cartContext.tada.cartBounce
    updateTada: (tada) => setcartBounce(tada), // brauchen wir das überhaupt???? JA!!
  }; // cc:  tada wird an updateTada übergeben, updateTada ruft Setter setcartBounce() auf und er ändert den "State"
  // const search = window.location.search.toString();
  // const pathname = window.location.pathname.toString();
  //debugger;
  return (
    <React.Fragment>
      <AppContextProvider value={ptrCx}>
        <AuthContextProvider>       
          <Cookiebanner/>
          <AppRouter />
        </AuthContextProvider>
      </AppContextProvider>
    </React.Fragment>
  );
}
export default App;
