import React, { useState, useEffect } from 'react';
import axios from 'axios';

// https://stackoverflow.com/questions/33973757/how-can-i-render-html-from-another-file-in-a-react-component
// https://medium.com/trabe/react-useeffect-hook-44d8aa7cccd0

/*
interface iFound {  
  document: string;  
}
*/

const AgbsPage = () => // functional component written in ES6 
{
  const [impressum, setImpressum] = useState([]);
  useEffect(() => {   // replace componentDidMount, componentDidUpdate, etc.

    axios({
      method: "POST",
      url: "/api/db/dbDocuments2",
      data: { SearchKey: "AGB Kunstgalerie-Hieke" }   // Fester Wert - muss parameterisiert werden. Server muss aktuelle AGB raussuchen

    }).then((response) => {
    //debugger
      setImpressum(response.data);
    })

  }, []);
  // Object is possibly 'undefined'.ts(2532)
  //  console.log(impressum)
  return (
    <div dangerouslySetInnerHTML={{ __html: impressum.document }} style={{ border: "2px solid #ccc", padding: 5, margin: 5, }} />
  )
}

export default AgbsPage
//  <div dangerouslySetInnerHTML={{ __html: impressum.document }} style={{ border: "2px solid #ccc", padding: 5, margin: 5, }} />