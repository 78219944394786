//import keyMirror from 'keymirror'; -- code direkt importiert - ohne package.json

// https://medium.com/@bhupendra.nitt/replacing-keymirror-with-7-lines-of-code-91825def7d15
// https://stackoverflow.com/questions/48163744/expected-to-return-a-value-in-arrow-function-array-callback-return-why/48163905
const keyMirror = (list) => {
  let newList= {};
  Object.keys(list).forEach(element => {  // forEach instead of map - unmutable and no return array expected
    var key = String(element);
    newList[key] = element
  });
return newList
}
// Wichtig:::::::::
// co wird über  Object.keys(states).indexOf(rc) ermittelt!!!!
// Position von states ergeben aktuelle co!!!!
// Positionen der Konstanten ergeben Reihenfolge von Steps!!!!
export  const states = keyMirror({  
  Warenkorb: true, 
  Abrechnung: true,
  Lieferung: true,
  Lieferart: true,    
  Zahlungsart: true, 
  Bestellung: true,
  Bezahlung: true,
  Bestätigung:true, 
  Fertig: true,

  Registrierung: true,  
  paypal: true,  
  Testing: true,
  Anmeldung: true, 

});

export const go = {
  Warenkorb: 0,
  Abrechnung: 1,
  Lieferung: 2,
  Lieferart: 3,    
  Zahlungsart: 4, 
  Bestellung: 5,
  Bezahlung: 6,
  Bestätigung:7, 
  Fertig: 8,
  Anmeldung: 9,
}


export const lF = {
  no: 0,
  checkout: 1,  
  modal:2,   // kein Checkout
  
}
