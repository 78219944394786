import React, { useState } from "react";
import axios from "axios"
import InputR from '../elements/InputR';

export default () => { // functional component written in ES6  
  const initialState = {
    datebase: "",
    username: "",
    host: "",
    dialect: "",    
    errorMessage: null,
    messageFromServer: null,
    updated: false,
    isLoading: true,
    error: false,
  };

  const [data, setData] = React.useState(initialState);

 

  React.useEffect(() => {
    setData({
      ...data,
      isLoading: true,
    });
    axios({
      method: "POST",
      url: "/api/db/Server_sag_wer_bin_ich",

    })
      .then((response) => {
        // ebugger;
        setData({
          ...data,
          isSubmitting: false,
          isLoading:false,
          username: response.data.config.username,
          database: response.data.config.database,
          host: response.data.config.host,
          dialect: response.data.config.dialect,
        });
      })
      .catch((error) => {  // PseudoError z.b. status(400) - programmierer gesteuert...
        // debugger;
        console.error(error);
        setData({
          ...data,
          messageFromServer: error,
          updated: false,
          isLoading: false,
          error: true,
        });
      });
  }, [])

  return (
    <React.Fragment>
    <div className="container-fluid">
   
        <div className="form-row ">
          <div className="col" style={{ fontWeight: "900" }}>
            Umgebung:
              </div>
          <div className="col" style={{ fontWeight: "900" }}>
            {process.env.NODE_ENV}
          </div>
        </div>

        <div className="form-row">
          <div className="col">
            <InputR inputtype={'text'}
              title={'Datenbankname:'}
              name={'datenbankname'}
              value={data.database}
            />
          </div>
          <div className="col">
            <InputR inputtype={'text'}
              title={'Benutzername:'}
              name={'benutzername'}
              value={data.username}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col">
            <InputR inputtype={'text'}
              title={'Host:'}
              name={'host'}
              value={data.host}

            />
          </div>
          <div className="col">
            <InputR inputtype={'text'}
              title={'Dialekt:'}
              name={'dialect'}
              value={data.dialect}

            />
          </div>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    
    </React.Fragment>
  )
};
