import React from "react";
import {Link } from "react-router-dom";
  
//import '../scss/Footer.scss'  

// https://bootsnipp.com/tags/footer/4?page=1
export default () => {

    return (
       
        <section id="footer">
            <div className="container">
                <div className="row text-center text-xs-center text-sm-left text-md-left">
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <h5>Information</h5>
                        <ul className="list-unstyled quick-links">

                        <li><a href="/"><i className="fa fa-angle-double-right"></i>About</a></li>
                            <li> <Link to="/contact"><i className="fa fa-angle-double-right"></i>Kontakt</Link></li>
                            <li><a href="/"><i className="fa fa-angle-double-right"></i>FAQ</a></li>
                            <li> <Link to="/showagbs"><i className="fa fa-angle-double-right"></i>AGB's</Link> </li>                            
                            <li> <Link to="/showdocument/Datenschutz Kunstgalerie-Hieke.de" target="_blank"><i className="fa fa-angle-double-right"></i>Datenschutzerklärung</Link> </li>             
                           
                        </ul>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <h5>Kontakt</h5>
                        <ul className="list-unstyled quick-links">
                            <li><a href="/"><i className="fa fa-angle-double-right"></i>Home</a></li>
                           
                            <li><a href="https://www.schatral.de" title="Designed und entwickelt von "><i className="fa fa-angle-double-right"></i>Schatral.de</a></li>
                          
                            <li><a href="/"><i className="fa fa-angle-double-right"></i>Videos</a></li>  
                            <li><a href="/"><i className="fa fa-angle-double-right"></i>Get Started</a></li>
                        </ul>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <h5>Zahlungsarten</h5>
                        <ul className="list-unstyled quick-links">
                            <li><a href="/"><i className="fa fa-angle-double-right"></i>Kauf auf Rechnung</a></li>
                            <li><a href="/"><i className="fa fa-angle-double-right"></i>Vorauskasse</a></li>
                            <li><a href="/"><i className="fa fa-angle-double-right"></i>Paypal</a></li>  
                            <li> <Link  to="/"><i  className="fa fa-angle-double-right"></i>Shop</Link> </li>                         
                           
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
                            <ul className="list-unstyled list-inline social text-center">
                                <li className="list-inline-item"><a href="/"><i className="fa fa-facebook"></i></a></li>
                                <li className="list-inline-item"><a href="/"><i className="fa fa-twitter"></i></a></li>
                                <li className="list-inline-item"><a href="/"><i className="fa fa-instagram"></i></a></li>
                                <li className="list-inline-item"><a href="/"><i className="fa fa-google-plus"></i></a></li>
                                <li className="list-inline-item"><a href="/" target="_blank"><i className="fa fa-envelope"></i></a></li>
                            </ul>
                        </div>
                        <hr />
                    </div>
                   
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">                           
                            <p className="h6">© Alle Rechte vorbehalten.<a className="text-green ml-2" href="/" target="_blank">Kunstgalerie Hieke</a></p>
                        </div>
                        <hr />
                    </div>
                </div>
            </div>
        </section>
   
    )
}
