// NavigationBar.jsx
import React from 'react';
import { authContext } from "../context/AuthContext";
//import jwt_decode from 'jwt-decode';
// OP: import { lF } from '../Checkout/States'
import OpenLoginForm from './OpenLoginForm'
const headerStyle = {
  float: "right",
  padding: "22px 15px 15px 0px",
}
/* unused:
const linkStyle = {
  color: 'white',
  textDecoration: 'underline'
}
*/

export default (props) => {
  //debugger;

  const [withLogout] = React.useState(props.withLogout);
  const Cx = React.useContext(authContext);
  //console.log("test", Cx)
  if (Cx.auth.token) {
    //debugger;
    // const decoded = jwt_decode(Cx.auth.token);
    //    console.log(decoded)
  }



  //OP: Linkbutton zu finden in modali.css --> globalisieren als scss   // style={{padding: "22px 15px 15px 0px"}}
  return (
    <div style={headerStyle}>
      {Cx.auth.authenticated && withLogout ? (   //OP: && (isadmin  === tru) fehlt noch...
        <ul>          
          <br></br>
          <button onClick={() => Cx.setUnauthStatus()}>
            <img src={Cx.auth.avatar} alt={Cx.auth.username} title={Cx.auth.username}
              className="rounded-circle"
              style={{ width: '25px', marginRight: '5px' }} />
               (Abmelden)
          </button>
        </ul>
      ) : (
          <OpenLoginForm/>
        )}
    </div>
  )
};

/*

<button disabled={props.lfMode > 0} onClick={() => props.openLoginForm(lF.modal)}>

            <i class="fa fa-user"></i>
           (Anmelden)
          </button>

<h1 disabled={props.lfMode > 0}  onClick={() => props.openLoginForm(lF.modal)}>
       
        <i class="fa fa-user"></i>
           
      </h1>

      


          <Link style={linkStyle} to="/login">
            Anmelden
          </Link>
*/