import React, { useRef, useState } from 'react';
import axios from 'axios';
import Input from '../elements/Input';
import TextArea from '../elements/TextArea';

import FormValidator from '../Helpers/FormValidator';
import miniLogo from "../assets/miniWaitStateReactLogo.svg";
import {loadReCaptcha, ReCaptcha} from 'react-recaptcha-v3'  // 'react-recaptcha-v3'
import {Link, withRouter } from "react-router-dom"; // Wichtig - Link get über withRouter
// OP: https://github.com/aksonov/react-native-router-flux/issues/1328

export default withRouter(() => {
    const [isLoaded, setisLoaded] = useState(false);  // Unnötig? -ja?
    React.useEffect(() => {
        //debugger;
        loadReCaptcha('6LftIcAZAAAAALe18YMTWIiwcW2rGontA3vRHZBR');
        return () => {
            console.log('cleaned up');
            console.log({
                
            });
        };
    }, []);
    const reCapRef = useRef() as React.MutableRefObject<ReCaptcha>

    const verifyRecaptcha = (recaptchaToken: string) => {
        // Here you will get the final recaptchaToken!!!  
        console.log(recaptchaToken, "<= your recaptcha token")
        isLoaded && axios({
            method: "POST",
            url: "api/ValidateReCaptureExternOnGoogle",
            data: {
                'g-recaptcha-response': recaptchaToken,
                'blind': false,
                'v3': true,
            }
        }).then((response) => {
            if (response.data.success === true) {
                console.log(response)
                roaming_Kontaktanfrage_per_e_mail();  // isLoaded macht hier Sinn...
            }
            else if (response.data.success === false) {
                console.log(response)
                alert("You are a spam machine: " + response.data.errors)
            }
        })
        setisLoaded(true)

    }

    const roaming_Kontaktanfrage_per_e_mail = () =>
    {
        axios({
            method: "POST",
            url: "api/roaming_Kontaktanfrage_per_e_mail",
            data: data

        }).then((response) => {
            if (response.data === 'contact email sent') {
                setData({
                    ...data,
                    done: true,
                    isSubmitting: false,
                    errorMessage: "",
                    messageFromServer: "Anfrage erfolgreich per E-Mail versendet.",
                });

            } else if (response.data === 'error email sent') {
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: "Anfrage konnte nicht gesendet werden -bitte versuchen Sie später nocheinal.",
                    messageFromServer: "",

                });
            }
        }).catch(error => {
            console.log(error);
            setData({
                ...data,
                isSubmitting: false,
                messageFromServer: "",
                errorMessage: error.message
            });
        });
    }

    const updateReCaptureToken = () => {

        // https://stackoverflow.com/questions/55677600/typescript-how-to-pass-object-is-possibly-null-error
        // you will get a new token in verifyRecaptcha
        // useRef() returns an object with a current property, which contains the object you actually care about. And before the the first render is complete, that current property will be null. This means the type of that ref is:
        // ... And that means you have to handle null is a possible value of the current property. But the ref object itself will always exist, it's just that it's current property may be null.
        // https://stackoverflow.com/questions/59901775/ref-object-is-possibly-undefined-typescript-react
        // Ts-Error:
        //   https://stackoverflow.com/questions/55677600/typescript-how-to-pass-object-is-possibly-null-error
        (reCapRef as React.MutableRefObject<ReCaptcha>).current.execute();  // Object is possibly 'undefined'.ts(2532
    }

    const validator = new FormValidator([
        {
            field: 'name',
            method: 'isEmpty',
            validWhen: false,
            message: 'Bitte einen Namen eingeben'
        },
        {
            field: 'email',
            method: 'isEmpty',
            validWhen: false,
            message: 'Bitte Email Adresse eingaben.'
        },
        {
            field: 'email',
            method: 'isEmail',
            validWhen: true,
            message: 'Das ist keine gültige E-Mail-Adresse.'
        },
        {
            field: 'message',
            method: 'isEmpty',
            validWhen: false,
            message: 'Bitte Nachricht eingeben.'
        },

    ]);

    const initialState = {
        anlagedatum: new Date(),        
        name: '',
        message: '',
        email: '',
        done: false,
        isSubmitting: false,  // OP: noch nicht eingebunden - Quelle suchen...
        errorMessage: "",
        messageFromServer: "",

    }  // static Binding - only one time rendering...
    // hier weitere Handler eintragen...  
    const [data, setData] = React.useState(initialState);
    const [validation, setValidation] = React.useState(validator.SetToValid());



    const handleFormSubmit = (e: { preventDefault: any; }) => {
        e.preventDefault();  // error: this... wenn not bind in the constructor...
        const validation = validator.validate(data);
        setValidation(validation);  // reicht ohne funktion?...

        if (validation.isValid) {
            updateReCaptureToken();          
            
        }

    }
    /*
    const handleClearForm = (e: { preventDefault: () => void; }) => {
        console.log("handleClearForm...")
        e.preventDefault(); // OP: verhindere Defaultverhalten... Neuladen Formular verhindern
        setData(initialState);

    }
    */

    const handleInputChange = (event: { target: { name: string; value: string; }; }) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
            // Wichtig: Input name="repeatPassword" muss state Variable data.repeatPassword genau entsprechen!!!!  ID im Input field notwendig?
        });
    };


    const errorClass = (ungueltig: string) => {
        return (ungueltig ? 'is-invalid' : '');
    }

    //  site= {this.state.recaptureBlind===true ? 'invisible' : 'normal'}
    // sitekey= {this.state.recaptureBlind===true ? '6Lc46XYUAAAAANOP2nvVmhyKDI1gSj1Hlwfa8yNY' : '6Lc4z3YUAAAAANyGm1hJ5Ti8NHw5wA_2x-EcOR8q'}



    return (
        <div className="container-fluid">
            <h4> Kontaktseite </h4>
            <form className="container-fluid" onSubmit={handleFormSubmit}>
                <Input inputtype={'text'}
                    title={'Name: *'}
                    name={'name'}
                    value={data.name}
                    placeholder={'Geben Sie hier den Namen ein'}
                    onChange={handleInputChange}
                    className={`form-control ${errorClass(validation.name.isInvalid)}`}
                    errormessage={validation.name.message}
                />
                <Input inputtype={'text'}
                    title={'E-mail: *'}
                    name={'email'}
                    value={data.email}
                    placeholder={'E-Mail Adresse '}
                    onChange={handleInputChange}
                    className={`form-control ${errorClass(validation.email.isInvalid)}`}
                    errormessage={validation.email.message}
                />
                <TextArea
                    title={'Nachricht: *'}
                    rows={10}
                    value={data.message}
                    name={'message'}
                    onChange={handleInputChange}
                    placeholder={'hier bitte Nachricht verfassen...'}
                    className={`form-control ${errorClass(validation.message.isInvalid)}`}
                    errormessage={validation.message.message}
                />
                {/* https://github.com/DefinitelyTyped/DefinitelyTyped/issues/35572 */}
                <ReCaptcha ref={reCapRef}
                    action='RecaptchaHook'
                    sitekey='6LftIcAZAAAAALe18YMTWIiwcW2rGontA3vRHZBR'
                    verifyCallback={verifyRecaptcha}
                />

                <br />
                <div>Hinweis: Felder, die mit * bezeichnet sind, sind Pflichtfelder.</div>
                <br />
                <button disabled={data.isSubmitting || data.done} className="btn btn-outline-success" style={{ height: "40px" }} type="submit">
                    {data.isSubmitting ? (<img className="spinner" src={miniLogo} alt="loading icon" />) :
                        'Abschicken'
                    }
                </button>
                <br />
                <br />
                <br />
                {data.errorMessage && (
                    <span className="form-error">{data.errorMessage}</span>
                )}
                {data.messageFromServer && (
                    <span className="form-success">{data.messageFromServer}</span>
                )}
                <br />
                <Link to="/" >Startseite</Link>
                <br />
                <br />
            </form>
            <div>
            </div>
        </div>
    );

});