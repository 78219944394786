import React, { useState } from 'react';
import preventTransitions from "../../Helpers/preventTransition"


//global.ppp = null;  // globale Variable in Checkout
//var ppp = null;
export default (props) => {  // PayPalPlus...
    const [isSdkReady, setIsSdkReady] = useState(false);


    React.useEffect(() => {
        if (!isSdkReady) {
            global.ppp.doCheckout();
            setIsSdkReady(true)
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSdkReady])



    console.log(global.ppp)  // onClick={"global.ppp.doCheckout()"}
    console.log(isSdkReady)
    console.log('Bezahlung - currentstep: ', props.state.currentStep)
    return (
        <React.Fragment>
            {preventTransitions(true)}
        </React.Fragment>
    )

};
