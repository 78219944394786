import React, { useRef } from 'react';

/*
const scrollToRef = (ref) => // funktioniert nicht richtig -scrollIntoView schon...
{

  //debugger;
  if (ref.current.offsetTop !== 0) {
    window.scrollTo(0, ref.current.offsetTop)
  }
}
*/
const CheckBox = (props) => {

  const errorRef = useRef(null)
  let classname = props.classname ? props.classname : "form-check-input"
  let counter = 0;
  if (props.scrollToError && props.errormessage.length > 0) {
    var element = document.getElementById("errorScrolling");
    if (element !== null) {
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
      element.scrollIntoView();
    }
    // scrollToRef(errorRef) -- wieder raus - fehlerbehaftet!!!

  }
  return (

    <div className="form-group">
      <label htmlFor={props.name} className="orm-label">{props.title}</label>
      <div className="checkbox">
        {props.options.map(option => {
          return (
            <label key={option} className="form-check form-check-inline">
              <input className={classname}
                id={props.name + (counter++).toString().trim()}
                name={props.name}
                onChange={props.handleChange}
                value={option}
                checked={props.selectedOptions.indexOf(option) > -1}
                type="checkbox" /> {option}
            </label>
          );
        })}
      </div>

      <span id="errorScrolling" ref={errorRef} className="invalid-feedback d-block">{props.errormessage}</span>


    </div>
  );

}

export default CheckBox;