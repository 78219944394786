// source snipe C:\React\_Context API\usehover 

import React, { useContext } from 'react';
import { useState, useCallback, useRef } from "react";
import { TransitionGroup } from 'react-transition-group'; //10.07.20 USE VErsion 1.X!!!!!!!!
import basket from '../assets/shopping-basket.svg';

import EmptyCart from "./EmptyCart";
//import Cartsymbol2 from '../assets/ShopCart_PNG/Icons/shopcart_48x48.png'; // http://www.iconarchive.com/show/shop-cart-icons-by-fasticon.html
// npm install --save-dev @iconify/react @iconify/icons-fa




import AppContext from "../context/AppContext"
import CustomScroller from 'react-custom-scroller';

import { withRouter } from "react-router-dom";
//import  '../scss/header/_warenKorb.scss'

var buttonStyle2 = {
  textalign: 'left'
};
// https://usehooks.com/useHover/
function useHover() {
  const [value, setValue] = useState(false);

  // Wrap in useCallback so we can use in dependencies below
  //https://reactjs.org/docs/hooks-reference.html#usecallback
  const handleMouseOver = useCallback(() => setValue(true), []);
  const handleMouseOut = useCallback(() => setValue(false), []);

  // Keep track of the last node passed to callbackRef
  // so we can remove its event listeners.
  const ref = useRef();

  // Use a callback ref instead of useEffect so that event listeners
  // get changed in the case that the returned ref gets added to
  // a different element later. With useEffect, changes to ref.current
  // wouldn't cause a rerender and thus the effect would run again.
  const callbackRef = useCallback(
    node => {
      if (ref.current) {
        ref.current.removeEventListener("mouseover", handleMouseOver);
        ref.current.removeEventListener("mouseout", handleMouseOut);
      }

      ref.current = node;

      if (ref.current) {
        ref.current.addEventListener("mouseover", handleMouseOver);
        ref.current.addEventListener("mouseout", handleMouseOut);
      }
    },
    [handleMouseOver, handleMouseOut]
  );

  return [callbackRef, value];
}

export default withRouter((props) => {
  const [showCart] = useState(true)   // OP: ggf. Globalisieren...
  let [hoverRef, isHovered] = useHover();  // Steuert Mouse over, ist aber kein State???

  const cartContext = useContext(AppContext);

  const getSelectedItemsCount = () => {
    return cartContext.data.products.filter(product => product.addedToCart).length;
  }

  const handleClick = event => {
    // debugger;
    //console.log(isHovered);
    cartContext.updateCheckout(true);
    // setShowCart(false)  // JS -- wieder raus:  27.07.20 rein 23.12.20 und wieder raus!
    //isHovered = false;   // ohne setShowCart() kein invisible!!! ist das ein "useState"?
    // aber hier nein... cart-preview active - abgeschalten - auch so bald wir mit der Mouse nicht mehr drüberstehen 
    // props.pthis.callCheckout();  // OP:::
    cartContext.updateCart(cartContext.data.products.filter(product => product.addedToCart));
    // 22.07.20 - Fehler behoben: verliert alle Items aus dem Warenkorb
    // window.localStorage.setItem("cartitems", JSON.stringify(cartContext.data.products));
    //window.location.href='/checkout';  // geht über kompletten Browser - Verliert "local memory" - p.e: Items im Warenkorb sind weg...
    props.history.push('/checkout')  // anstelle von  window.location.href - geht über den tiefergestellten BrowserRouter....


  }

  const handleCartUpdate = (id) => {
    const productsState = cartContext.data.products.map(product => ({ ...product, addedToCart: (product.Id === id ? !product.addedToCart : product.addedToCart) }));
    //debugger
    cartContext.updateData(productsState);

  }
  /* Error:
  React Hook "useHover" is called in function "smily" which is neither a React function component or a custom React Ho
  https://dev.to/ranewallin/js-bites-react-hook-is-called-in-a-function-which-is-neither-a-react-function-or-sic-a-custom-react-hook-1g2c
  */
  let cartItems;
  var formatPrice = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 });
  // https://upmostly.com/tutorials/react-filter-filtering-arrays-in-react-with-examples
  cartItems = cartContext.data.products.filter(product => product.addedToCart).map((product, key) => {
    return (
      <li className="cart-item" key={product.sku}>
        <img className="product-image" src={product.Images[0].original} alt={product.name} />
        <div className="product-info">
          <p className="product-name">{product.Title}</p>
          <p className="product-price">{formatPrice.format(product.price)}</p>
        </div>
        {/*
         <div className="product-total">
           <p className="quantity">
             {product.quantity} {product.quantity > 1 ? "Nos." : "No."}{" "}
           </p>
           <p className="amount">{product.quantity * product.price}</p>
         </div> 
         <button
           className="product-remove" 
           style={buttonStyle}
           onClick={this.props.removeProduct.bind(this, product.id)}
           >x</button> 
         */
        }
        <button
          className="product-remove"
          style={buttonStyle2}
          onClick={() => handleCartUpdate(product.Id)}
        >X</button>
      </li>
    );
  });
  let view;
  if (cartItems.length <= 0) {
    view = <EmptyCart />;
  } else {
    view = (
      <TransitionGroup
        transitionName="fadeIn"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
        component="ul"
        className="cart-items">
        {cartItems}
      </TransitionGroup>
    );
  }

  const enableBtn = () => {
    if (getSelectedItemsCount() > 0 && (!cartContext.checkoutGetter)) { return true }
    return false
  }
  // https://www.robinwieruch.de/react-function-component#react-function-component-event-handler
  // https://fontawesome.bootstrapcheatsheets.com/#
  //console.log(new Date().toLocaleTimeString()+" tada: ", cartContext.tada.cartBounce)
  return (
    <React.Fragment>
      {cartContext.checkoutGetter === false ?
        <div style={{ width: '72px', height: '72px' }} ref={hoverRef}>
          <div className="cart">
            <div className="cart-icon">
              <img className={cartContext.tada.cartBounce ? "tada" : "  "}
                style={{ width: '72px' }}   // OP: Media Query fehlt noch....
                src={basket} //"shopping-basket.svg"  // kein Pfad - da in public OP: 04.1.20 - gibt Probleme
                alt="shopping-basket" />
              {getSelectedItemsCount() ? (<span className="cart-count">{getSelectedItemsCount()}</span>) : ("")}
            </div>
          </div>
          <div className={showCart && isHovered ? "cart-preview active modali-animated modali-animation-fade-in" : "cart-preview"}>
            <CustomScroller>{view}</CustomScroller>
            <div className="action-block">
              <button type="button" className={enableBtn() === true ? " " : "disabled"} disabled={enableBtn() === true ? false : true}
                onClick={handleClick}  >
                zur Kasse gehen
           </button>
            </div>
          </div>
        </div> : null}
    </React.Fragment>
  );
})
/*
 <Link to="/checkout" style={{ textDecoration: "none" }}>
            <img className={cartContext.tada.cartBounce ? "tada" : "  "}
              style={{ width: '72px' }}   // OP: Media Query fehlt noch....
              src="shopping-basket.svg"  // kein Pfad - da in public
              alt="shopping-basket" />

          </Link>
*/