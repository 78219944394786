import React from 'react';
import { Prompt } from "react-router-dom";
import AppContext from "../context/AppContext"
// Op: Warum im Product.jsx enthalten?????
export default (condition, count) => {
    let display = true
    if (count !== undefined) {
        if (count === 0) 
        { 
            display = false 
        }

    }
    // Wenn condition is true - display it...
    const cartContext = React.useContext(AppContext);
    const kombi = condition && cartContext.checkoutGetter
    return (
        
        <React.Fragment>
            {display === true ?
            <Prompt when={kombi}
                message={location =>
                    (location.pathname === "/contact") ?
                        `ÄNDERUNGEN VERWERFEN? Verlassen und zur Kontaktseite gehen?` :
                        (location.pathname === "/impressum") ?
                            `ÄNDERUNGEN VERWERFEN? Verlassen und zum Impressum gehen?` :
                            (location.pathname === "/") ?
                                `ÄNDERUNGEN VERWERFEN? Checkout verlassen und zurück zur Galerie gehen?` :
                                `ÄNDERUNGEN VERWERFEN? Verlassen und nach ${location.pathname} gehen?`
                }
            /> : null}
        </React.Fragment>
    );
}
