import React from 'react';
// https://codesandbox.io/s/showhide-password-on-toggle-in-react-hooks-95qcz?file=/src/App.js

const Input = (props) => {
  //debugger
 	return (  
    <div  className={props.formgroup ? props.formgroup : "form-group"}> 
      <label htmlFor={props.name} className="form-label">{props.title}</label>
      <input   
      className={props.classname ? props.classname : "form-control"}
      id={props.name}
      name={props.name}
      type={props.inputtype}
      value={props.value}
      onFocus={props.onFocus}
      onChange={props.onChange}
      placeholder={props.placeholder}  
      required={props.required} 
      disabled={props.disabled} 
      {...props} 
      />
      {props.displayicon}
      <span className="invalid-feedback">{props.errormessage}</span>
   </div>
   ) 
  
}

export default Input;