import React, { Component } from "react";
import Dropzone from "./Dropzone";
import Progress from "./Progress";
import RemovePicture from "./RemovePicture"

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xhrstatus: 0,
      errorobj: {},
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  onFilesAdded(files) {
    let add =  true
    this.state.files.forEach(function (file) {
      const vgl = file.name;
      files.forEach(function (file) {
        if (vgl === file.name)
        { 
          add  = false
        }
        
      });
    });   
    if (add)  {  
    this.setState(prevState => ({
      files: prevState.files.concat(files)
    }));}
  }
  
  async uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    this.state.files.forEach(file => {
      promises.push(this.sendRequest(file));
    });
    try {
      await Promise.all(promises);
      this.props.updateProduct(this.state.files);                  // Prozedur in Product.js
      this.setState({ successfullUploaded: true, uploading: false });
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: false, uploading: false });
    }
  }

  sendRequest(file) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      req.onreadystatechange = function () {
        if (req.readyState === XMLHttpRequest.DONE) {
          if (req.status === 500) {
            alert(req.responseText)
          } // OP: ggf. erstetzen durch modali oder toasti...

        }
      }
      req.onerror = function () {
        reject(new Error("Verbindungsfehler!"));
      };

      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.upload.addEventListener("load", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "done", percentage: 100 };
        this.setState({ uploadProgress: copy });
        resolve(req.response);
      });

      req.upload.addEventListener("error", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ uploadProgress: copy });
        reject(req.response);
      });

      const formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("Product", this.props.product.Id)
      req.open("POST", "/uploadImage");
      req.send(formData);
    });
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
          <img
            className="CheckIcon"
            alt="done"
            src="baseline-check_circle_outline-24px.svg"
            style={{
              opacity:
                uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
            }}
          />
        </div>
      );
    }
  }

  renderActions() {
    if (this.state.successfullUploaded) {
      return (
        <button className="btn btn-outline-success" onClick={() => this.setState({ files: [], successfullUploaded: false })} >
          weiter
        </button>
      );
    } else {
      return (
        <button className="btn btn-outline-success"
          disabled={this.state.files.length <= 0 || this.state.uploading}
          onClick={this.uploadFiles}
        >
          abgelegte Bilder hochladen
        </button>
      );
    }
  }
 
  render() {
    return (
      <div className="Upload">

        <span className="Title">Bilder hochladen / austauschen: </span>
        <div className="Actions"><RemovePicture updImageDelete = {this.props.updImageDelete} uploading = {this.state.uploading}/></div>
        <div className="Content">
          <Dropzone
            onFilesAdded={this.onFilesAdded}
            disabled={this.state.uploading || this.state.successfullUploaded}
          />
          <div className="Files">
            {this.state.files.map((file, i) => {
              
              return (
                <div key={file.name} className="Row">
                  <span className="Filename">{file.name}</span>
                  {this.renderProgress(file)}
                </div>
              );
            })}
          </div>
        </div>
        <div className="Actions">{this.renderActions()}</div>
      </div>
    );
  }
}

export default Upload;
