import preventTransitions from "../Helpers/preventTransition"
import React, { useContext, useState } from 'react';
import AppContext from "../context/AppContext"
import QuickViewProduct from './QuickViewProduct'
import Upload from "../components/upload/Upload"
import helpVideoIcon from "../assets/Help_Product_Upload_small_Nov 23, 2020 12_55 PM.gif";
import helpVideo from "../assets/Untitled_ Nov 23, 2020 12_55 PM (575 x 480 px).gif";
import Modali, { useModali } from '../modal/modali';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import { authContext } from "../context/AuthContext";
import { roleKey } from "../Helpers/evalRechte"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
    library
} from '@fortawesome/fontawesome-svg-core'

import {
    faCog
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faCog,
)
export default (props) => {

    const [roundTripHelpModal, toggleHelpModal] = useModali({
        animated: true,
        wide: false,
        title: props.title, //'Allgemeine Geschäftsbedingungen',
        overlayClose: true,
        onHide: () => { },    // void - oder z.B. =>alert('I\'m now hidden'),
        closeButton: true,   // Vorerst drin lassen

    });


    let [isBlocking, setIsBlocking] = useState(false);
    const [updFlag, setUpdFlag] = useState(false);   // Update Flag: Erzwungenes RePaint der Componenten...
    const [toggleEdit, setToggleEdit] = useState(false);  // Speziell für nummerische Feldeingaben....

    const productContext = useContext(AppContext);
    // const [Title, setTitle] = useState(props.product.Title)
    const formatPrice = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 });
    const initialState = {
        Sortierung: props.product.Sortierung,
        Title: props.product.Title,
        price: parseFloat(props.product.price),
        description: props.product.description,
        isLoading: false,
        isSubmitting: false,  // OP: noch nicht eingebunden - Quelle suchen...
        errorMessage: "",
        messageFromServer: "",
        updated: false,     // Textänderungen durchgeführt noch nicht gespeichert - NOcH unused..
        errorFlag: false,
    };
    const useStateExt = (initialValue, name) => {
        const newLocal = React.useState(initialValue);
        const [value, setValue] = newLocal;
        //console.log(name, value);       
        //React.useDebugValue(`${name}: ${value}`);
        return [value, setValue];
    }

    const [data, setData] = useStateExt(initialState, "Data: ");
    const Cx = React.useContext(authContext);

    const handleCartUpdate = (id) => {
        const productsState = productContext.data.products.map(product => ({ ...product, addedToCart: (product.Id === id ? !product.addedToCart : product.addedToCart) }));
        productContext.updateData(productsState);
        // https://upmostly.com/tutorials/settimeout-in-react-components-using-hooks
        productContext.updateTada(true);   // OP: entnehmen? war als Test gedacht?
        setTimeout(() => {
            productContext.updateTada(false);
        }, 1000);
    }

    const updImageDelete = (imageId) => {
        //// PROBLEMFALL::::
        const product = props.product;    // Klärung: ÜBER internen Pointer zugeordnet? - 
        //debugger;   

        product.Images.forEach(function (item, index, object) {
            if (item.Id === imageId) {
                object.splice(index, 1);
            }
        });
        setUpdFlag(!updFlag)    // Damit die Component neu ausgegeben wird!!!!! -nach ID Fehler in Productlist OP - ggf. Test ob noch nötig!!!

    }
    const updateProduct = (files) => {
        //// PROBLEMFALL::::
        const product = props.product;    // Klärung: ÜBER internen Pointer zugeordnet? - 
        //debugger;   
        files.forEach(function (file) {
            var co = 901
            const image =
            {
                Id: co++,
                ProductId: product.Id,
                original: '/Content/Upload/' + file.name,
                productId: product.Id,
                thumbnail: '/Content/Upload/' + file.name,
            };
            product.Images.push(image);
            setUpdFlag(!updFlag)    // Damit die Component neu ausgegeben wird!!!!!
        });

    }
    const toogleToEditorMode = (e) => {
        productContext.updateEditorMode(!productContext.editorModeGetter)

    };

    const handleInputChangePrice = (event) => {
        setData({
            ...data,
            [event.target.name]: parseFloat(event.target.value),
            // Wichtig: Input name="repeatPassword" muss state Variable data.repeatPassword genau entsprechen!!!!  ID im Input field notwendig?
        });
        setIsBlocking(event.target.value.length > 0);
        props.handleUpdated(true);
    };

    const handleInputChange = event => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
            // Wichtig: Input name="repeatPassword" muss state Variable data.repeatPassword genau entsprechen!!!!  ID im Input field notwendig?
        });
        setIsBlocking(event.target.value.length > 0);
        props.handleUpdated(true);
        //toggleBearbeitungsHinweis();
    };
    const handleFormSubmit = e => {
        // Test - nehme hier oder woanders mal e.preventDefault() raus...
        // https://www.mediaevent.de/javascript/event-handler-default-verhindern.html
        e.preventDefault(); // verhindere Defaultverhalten - komplete Seite würde neu geladen, Warenkorb sehr, etc

        if (productContext.editorModeGetter) {
            setData({
                ...data,
                isSubmitting: true,
                errorMessage: "",
                messageFromServer: "",
                errorFlag: false,
            });
            setIsBlocking(false);
            axios.post('/updateProductData', {   // .post hat body....
                Id: props.product.Id,
                Title: data.Title,
                price: data.price,
                description: data.description,
                Sortierung: data.Sortierung,

            }).then(response => {
                props.handleUpdated(false);
                props.product.Sortierung = data.Sortierung;
                props.product.Title = data.Title;  // per Reference? geht das? sonst array über Context austauschen...
                props.product.price = data.price;
                props.product.description = data.description;

                // console.log(response.data);
                setData({
                    ...data,
                    messageFromServer: response.data.message,
                    errorMessage: "",
                    isLoading: false,
                    isSubmitting: false,
                    errorFlag: false,
                });

            }).catch(error => {
                setData({
                    ...data,
                    messageFromServer: error.response.data.err.errors[0].message,
                    errorMessage: error.message,
                    isLoading: false,
                    isSubmitting: false,
                    errorFlag: true,
                });
                console.log(error);
            });
        }
    }
    const clickevent = (e) => {
        toggleHelpModal();
    }
    const evalRechte = () => {
        //debugger;
        const role = Cx.auth.roles;
        switch (role) {
            case roleKey.Admin:
                return (true);
            case roleKey.Hauptbenutzer:
                return (true);
            case roleKey.Gast:
                return (false)
            default:
                // this.setState({ titel: 'Alternative Lieferanschrift' })
                //this.props.setCurrentStep(states.Lieferung)  // Warum ist das drin??!!!
                return (false)
        }

    }
    const addToBasket = () => {
        return (
            <React.Fragment>
                {productContext.editorModeGetter ? (
                    <div className="form-group row product-text">
                        <button disabled={data.isSubmitting} className="btn btn-outline-success" style={{ height: "40px" }} type="submit">
                            {data.isSubmitting ? ( // title="ein wenig Gedult bitte..."
                                ('etwas Gedult bitte...')
                            )
                                :

                                ('Speichern')
                            }
                        </button>
                    </div>
                ) : props.product.addedToCart ?  // siehe ..\Header\OpenLoginForm.js
                        (
                            <button className="btn btn-warning" onClick={() => handleCartUpdate(props.product.Id)}>
                                <img
                                    style={{ width: '48px', hight: '48px' }}   // OP: Media Query fehlt noch....
                                    src="shopping-basket.svg"  // kein Pfad - da in public
                                    alt="shopping-basket" /> Löschen
                            </button>
                        ) :
                        (
                            <button className="btn btn-success" disabled={props.product.addedToCart} onClick={() => handleCartUpdate(props.product.Id)}>
                                <img
                                    style={{ width: '48px', hight: '48px' }}   // OP: Media Query fehlt noch....
                                    src="shopping-basket.svg"  // kein Pfad - da in public
                                    alt="shopping-basket" /> Hinzufügen
                            </button>
                        )
                }
            </React.Fragment>
        );
    }
  
    // console.log("errorMessage: ", data.errorMessage)
    return (
        <React.Fragment>
            <Modali.Modal   {...roundTripHelpModal}>
                <img
                    src={helpVideo} // siehe imageGallery.jsx
                    alt={"Hilfe Video"}
                //sizes={item.sizes}
                />
            </Modali.Modal>
            <form className="account-form" onSubmit={handleFormSubmit}>
                {preventTransitions(isBlocking)}
                <div className="product" key={0}>
                    {addToBasket()}
                    {productContext.editorModeGetter ?
                        <React.Fragment>
                            <div className="cardUpload">
                                <div className="row">
                                    <div className="col">
                                        <Upload updImageDelete={updImageDelete} updateProduct={updateProduct} product={props.product} />
                                    </div>
                                    <div className="col">
                                        <span className="Actions Title">Hilfe Video:</span>

                                        <img
                                            src={helpVideoIcon} // siehe imageGallery.jsx
                                            alt={"Click to watch"}
                                            //sizes={item.sizes}
                                            title={"klicken um zu vergrössern"}

                                            onClick={clickevent}
                                        />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                        :
                        null
                    }
                    <div className="form-row">
                        <div className="col">

                        </div>
                        <div className="col">
                            {productContext.editorModeGetter ?
                                <div>
                                    <div className={"form-group row product-text"}>

                                        <input
                                            //id = {"Title"}
                                            className={"form-control"}
                                            name={"Title"}
                                            type={"text"}
                                            value={data.Title}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className="product-name">{props.product.Title}</div>
                                </div>
                            }
                        </div>
                        <div className="col">
                            {evalRechte() ?
                                <div className="d-flex justify-content-end">
                                    <div className="fasGear">
                                        <FontAwesomeIcon
                                            icon={['fas', 'cog']}
                                            fixedWidth={false}
                                            size="2x"
                                            spin={productContext.editorModeGetter}
                                            onClick={() => toogleToEditorMode()}
                                            title="Editormodus ein-/ausschalten"
                                        />
                                    </div>
                                </div>
                                :
                                null
                            }

                        </div>
                    </div>
                    <div className="rahmen-7">
                        <div className="product-image">
                           <QuickViewProduct product={props.product} /> 
                            {productContext.editorModeGetter ?
                                <div>
                                    <div className="product-text">
                                        <textarea className="form-control"
                                            rows={10}
                                            value={data.description}
                                            name="description"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className={"form-group row product-text"}>
                                        <label htmlFor={props.product.sku} className="col-sm-2 col-form-label product-price"></label>
                                        <div className="col-sm-10">
                                            {toggleEdit ? (
                                                <NumberFormat name="price" value={formatPrice.format(data.price)} suffix={' €'}
                                                    decimalSeparator={","}
                                                    decimalScale={2}
                                                    className={"form-control"}
                                                    onChange={handleInputChangePrice}
                                                    onBlur={() => setToggleEdit(!toggleEdit)}  // https://www.varvet.com/blog/format-numbers-in-input-fields-using-react/
                                                />
                                            ) : (
                                                    <input
                                                        //id = {"Title"}
                                                        className={"form-control"}
                                                        name={"priceDummy"}  // ist quasi readOnly: wegen grauen layout nicht genommen..
                                                        type={"text"}
                                                        value={formatPrice.format(data.price)}
                                                        onFocus={() => setToggleEdit(!toggleEdit)}
                                                        onChange={handleInputChangePrice}
                                                    />
                                                )}
                                        </div>
                                        <label htmlFor={props.product.sku} className="col-sm-2 col-form-label">Sort.:</label>
                                        <div className="col-sm-10">

                                            <input name="Sortierung" value={data.Sortierung}
                                                className={"form-control"}
                                                onChange={handleInputChange}
                                                title="Angabe der Sortierreihenfolge möglichst in 10er Schritten ohne Doppelvergabe (unique key)"
                                                placeholder="Sortierreihenfolge"
                                            />

                                        </div>
                                    </div>
                                </div>
                                :
                                <div>
                                    <p className="product-text">{props.product.description}</p>
                                    <p className="product-price">{formatPrice.format(props.product.price)}</p>
                                </div>
                            }

                        </div>
                    </div>
                    <br></br>
                    <div>
                        {data.messageFromServer && data.errorFlag && (

                            <span className="form-error">{data.messageFromServer}</span>
                        )}
                        {data.messageFromServer && !data.errorFlag && (

                            <span className="form-success">{data.messageFromServer}</span>
                        )}
                    </div>
                    <br></br>
                    {data.errorMessage && (
                        <span className="form-error">{data.errorMessage}</span>
                    )}
                    {addToBasket()}
                </div>
            </form>
        </React.Fragment>
   
        )
    
}
/*


Demo für  Fotoqualität - und 1 Euro Preis für Checkout Test über Online-Bezahlsystems PayPal  (Mitbegründer: Tesla Chef Elon Musk).


thousandSeparator={"."}

 suffix={' EUR'}
thousandSeparator="." decimalSeparator="," decimalScale={2}


style={{fontFamily: "Tahoma, sans-serif"}}

<img
        alt="upload"
        className="image-gallery-thumbnail-image"
        src={mProduct.Images[0].original}
      />


*/