// For testing purposes - use _vgl Verison - *master is not working because of gulb...

import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import { Swipeable, LEFT, RIGHT } from 'react-swipeable';
import throttle from 'lodash.throttle';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import ResizeObserver from 'resize-observer-polyfill';
import Modali, { useModali } from '../../modal/modali';
//import ReactImageMagnify from 'react-image-magnify';
import InPictureMagnifier from "./pictureinpicture/InPictureMagnifier"
import AppContext from "../../context/AppContext"

import {
  arrayOf,
  bool,
  func,
  number,
  oneOf,
  shape,
  string,
} from 'prop-types';
import SVG from './SVG';


//const image = require("./sample-image.jpg");

const screenChangeEvents = [
  'fullscreenchange',
  'MSFullscreenChange',
  'mozfullscreenchange',
  'webkitfullscreenchange',
];

const imageSetType = arrayOf(shape({
  srcSet: string,
  media: string,
}));

function isEnterOrSpaceKey(event) {
  const key = parseInt(event.keyCode || event.which || 0, 10);
  const ENTER_KEY_CODE = 66;
  const SPACEBAR_KEY_CODE = 62;
  return key === ENTER_KEY_CODE || key === SPACEBAR_KEY_CODE;
}
/* Theme: react convert component to function
https://medium.com/@olinations/10-steps-to-convert-a-react-class-component-to-a-functional-component-with-hooks-ab198e0fa139
https://www.digitalocean.com/community/tutorials/five-ways-to-convert-react-class-components-to-functional-components-with-react-hooks
Details:
https://dev.to/chandra/set-a-callback-to-setstate-usestate-in-react-funtional-component-g7p
https://dev.to/this_dane/react-custom-hooks-for-prevprops-prevstate-15ho  // Test it...

// Fazit: - wird so bisher nicht gebraucht!
const usePrevious = (value) => {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
*/
const ImageGallery = (props) => {
  // ehemaliger Constructor:

  const imageContext = useContext(AppContext);

  const loadedImages = {};
  const [imageGallery] = useState(React.createRef());
  const [thumbnailsWrapper] = useState(React.createRef());
  const [thumbnails] = useState(React.createRef());
  const [imageGallerySlideWrapper] = useState(React.createRef());


  const [thumbsTranslate, setThumbsTranslate] = useState(0);
  const [currentSlideOffset, setCurrentSlideOffset] = useState(0);
  const [galleryWidth, setGalleryWidth] = useState(0);
  const [gallerySlideWrapperHeight, setGallerySlideWrapperHeight] = useState(0);
  const [thumbnailsWrapperWidth, setThumbnailsWrapperWidth] = useState(0);
  const [thumbnailsWrapperHeight, setThumbnailsWrapperHeight] = useState(0);
  const [slideStyle, setSlideStyle] = useState({});

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [modalFullscreen, setModalFullscreen] = useState(false);

  const [lazyLoaded, setLazyLoaded] = useState([]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [scrollingLeftRight, setScrollingLeftRight] = useState(false);
  const [scrollingUpDown, setScrollingUpDown] = useState(false);

  // Save it to a state!!! - not to a const or let!
  const [currentIndex, setCurrentIndex] = useStateExt(props.startIndex, "currentIndex");
  const [previousIndex, setPreviousIndex] = useState(currentIndex);
  const [prevProps, setPrevProps] = useState(props);



  const [callOnSliding, setCallOnSliding] = useState(false);
  const [imageClickPath, setImageClickPath] = React.useState(null);
  const [imageTitle, setImageTitle] = React.useState("");
  //console.log(props);
  const [imageModal, toggleModal] = useModali(
    {
      animated: true, large: false, wide: true, title: imageTitle,
    }
    //{large: true}
    // { wide: true },
  );


  const callSetCurrentIndex = (aktIndex) => {
    imageContext.updatePictureItem(props.items[aktIndex]);
    setCurrentIndex(aktIndex);

  }

  function useStateExt(initialValue, name) {
    const [value, setValue] = useState(initialValue);
    //console.log(`${name}: ${value}`);
    // imageContext.updatePictureItem(props.items[initialValue]);   // not possible...
    React.useDebugValue(`${name}: ${value}`);
    return [value, setValue]; 
  } 

  React.useEffect(() => {                   // componentDidMount()
    if (callOnSliding) {
      onSliding();
      setCallOnSliding(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callOnSliding])

  let thumbnailMouseOverTimer = null;
  let transitionTimer = null;
  let playPauseIntervalId = null;
  let direction = "";
  let resizeObserver = null;
  React.useEffect(() => {                       // componentDidUpdate(prevProps, prevState)
    const {
      items,
      lazyLoad,
      slideDuration,
      startIndex,
      thumbnailPosition,
      showThumbnails,
    } = props;
    // const { currentIndex } = state;
    if (prevProps) {
      const showThumbnailsChanged = prevProps.showThumbnails !== showThumbnails;
      const itemsSizeChanged = prevProps.items.length !== items.length;
      const itemsChanged = !isEqual(prevProps.items, items);
      const startIndexUpdated = prevProps.startIndex !== startIndex;
      const thumbnailsPositionChanged = prevProps.thumbnailPosition !== thumbnailPosition;


      if (thumbnailsPositionChanged) {
        // re-initialize resizeObserver because slides was unmounted and mounted again
        removeResizeObserver();
        initResizeObserver(imageGallerySlideWrapper);
      }

      if (itemsSizeChanged || showThumbnailsChanged) {
        handleResize();
      }
      if (previousIndex !== currentIndex) {
        slideThumbnailBar(previousIndex);
      }
      // if slideDuration changes, update slideToIndex throttle
      if (prevProps.slideDuration !== slideDuration) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        slideToIndex = throttle(
          unthrottledSlideToIndex, slideDuration, { trailing: false },
        );
      }
      if (lazyLoad && (!prevProps.lazyLoad || itemsChanged)) {
        setLazyLoaded([]);
      }

      if (startIndexUpdated || itemsChanged) {
        // TODO: should be fix/removed if all it is doing
        // is resetting the gallery currentIndext state
        callSetCurrentIndex(startIndex); // setCurrentIndex(startIndex);
      }
      setPrevProps(props)
    }
  }, [currentIndex, props.lazyLoad, props.slideDuration, props.thumbnailPosition, props.startIndex, props.items, props.items.length, props.showThumbnails])

  
  let slideToIndex = (index, event) => {
    //const { currentIndex, isTransitioning } = state;
    const { items, slideDuration, onBeforeSlide } = props;

    if (!isTransitioning) {
      if (event) {
        if (playPauseIntervalId) {
          // user triggered event while ImageGallery is playing, reset interval
          pause(false);
          play(false);
        }
      }

      const slideCount = items.length - 1;
      let nextIndex = index;
      if (index < 0) {
        nextIndex = slideCount;
      } else if (index > slideCount) {
        nextIndex = 0;
      }

      if (onBeforeSlide && nextIndex !== currentIndex) {
        onBeforeSlide(nextIndex);
      }
      // https://stackoverflow.com/questions/56247433/how-to-use-setstate-callback-on-react-hooks
      /*
      setState({
        previousIndex: currentIndex,
        currentIndex: nextIndex,
        isTransitioning: nextIndex !== currentIndex,
        currentSlideOffset: 0,
        slideStyle: { transition: `all ${slideDuration}ms ease-out` },
      }, onSliding);
      */
      setPreviousIndex(currentIndex);                     // OP: Klärung funktioniert dieser Stack? ggf. Vor onSliding() ein TimeOut...
      callSetCurrentIndex(nextIndex); // setCurrentIndex(nextIndex);   //OP: 06.11.20 - Callerfunktion mit ImageContext vorschalten
      setIsTransitioning(nextIndex !== currentIndex);
      setCurrentSlideOffset(0);
      setSlideStyle({ transition: `all ${slideDuration}ms ease-out` });
      setCallOnSliding(true);  // onSliding(); // Problem - Klarung: erst onSliding wenn setState für alle Elemente abgeschlossen ist - useEffect ungeeignet?

    }
  }


  let unthrottledSlideToIndex = slideToIndex;
  slideToIndex = throttle(
    unthrottledSlideToIndex, props.slideDuration, { trailing: false },
  );

  // Used to update the throttle if slideDuration changes



  if (props.lazyLoad) {   // notwendig?
    setLazyLoaded([]);
  }

  React.useEffect(() => {                   // componentDidMount()
    const { autoPlay } = props;
    if (autoPlay) {
      play();
    }
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('mousedown', handleMouseDown);
    initResizeObserver(imageGallerySlideWrapper);
    addScreenChangeEvent();
    return function cleanup() {           //  componentWillUnmount()
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('mousedown', handleMouseDown);
      removeScreenChangeEvent();
      removeResizeObserver();
      if (playPauseIntervalId) {
        window.clearInterval(playPauseIntervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        playPauseIntervalId = null;
      }
      if (transitionTimer) {
        window.clearTimeout(transitionTimer);
      }

    }
  }, [])




  const onSliding = () => {
    //  const { currentIndex, isTransitioning } = state;
    const { onSlide, slideDuration } = props;
    transitionTimer = window.setTimeout(() => {
      if (isTransitioning) {
        setIsTransitioning(!isTransitioning);
        if (onSlide) {
          onSlide(currentIndex);
        }
      }
    }, slideDuration + 50);
  }

  const onThumbnailClick = (event, index) => {  // OP
    const { onThumbnailClick } = props;
    slideToIndex(index, event);
    if (onThumbnailClick) {
      onThumbnailClick(event, index);
    }
  }

  const onThumbnailMouseOver = (event, index) => {
    if (thumbnailMouseOverTimer) {
      window.clearTimeout(thumbnailMouseOverTimer);
      thumbnailMouseOverTimer = null;
    }
    thumbnailMouseOverTimer = window.setTimeout(() => {
      slideToIndex(index);
      pause();
    }, 300);
  }

  const onThumbnailMouseLeave = () => {
    if (thumbnailMouseOverTimer) {
      const { autoPlay } = props;
      window.clearTimeout(thumbnailMouseOverTimer);
      thumbnailMouseOverTimer = null;
      if (autoPlay) {
        play();
      }
    }
  }

  const setScrollDirection = (dir) => {
    // const { scrollingUpDown, scrollingLeftRight } = state;

    if (!scrollingUpDown && !scrollingLeftRight) {
      if (dir === LEFT || dir === RIGHT) {
        setScrollingLeftRight(true);
      } else {
        setScrollingUpDown(true);
      }
    }
  }

  const ThumbsTranslate = (thumbsTranslate) => {
    setThumbsTranslate(thumbsTranslate);
  }

  const ModalFullscreen = (state) => { //true or false
    const { onScreenChange } = props;
    setModalFullscreen(state);
    // manually call because browser does not support screenchange events
    if (onScreenChange) {
      onScreenChange(state);
    }
  }

  const getThumbsTranslate = (indexDifference) => {
    const { disableThumbnailScroll, items } = props;
    // const { thumbnailsWrapperWidth, thumbnailsWrapperHeight } = state;
    let totalScroll;
    const thumbElement = thumbnails && thumbnails.current;

    if (disableThumbnailScroll) return 0;

    if (thumbElement) {
      // total scroll required to see the last thumbnail
      if (isThumbnailVertical()) {
        if (thumbElement.scrollHeight <= thumbnailsWrapperHeight) {
          return 0;
        }
        totalScroll = thumbElement.scrollHeight - thumbnailsWrapperHeight;
      } else {
        if (thumbElement.scrollWidth <= thumbnailsWrapperWidth || thumbnailsWrapperWidth <= 0) {
          return 0;
        }
        totalScroll = thumbElement.scrollWidth - thumbnailsWrapperWidth;
      }
      // scroll-x required per index change
      const perIndexScroll = totalScroll / (items.length - 1);
      return indexDifference * perIndexScroll;
    }
    return 0;
  }

  const getAlignmentClassName = (index) => {
    // Necessary for lazing loading
    // const { currentIndex } = state;
    const { infinite, items } = props;
    let alignment = '';
    const leftClassName = 'left';
    const centerClassName = 'center';
    const rightClassName = 'right';

    switch (index) {
      case (currentIndex - 1):
        alignment = ` ${leftClassName}`;
        break;
      case (currentIndex):
        alignment = ` ${centerClassName}`;
        break;
      case (currentIndex + 1):
        alignment = ` ${rightClassName}`;
        break;
      default:
        break;
    }

    if (items.length >= 3 && infinite) {
      if (index === 0 && currentIndex === items.length - 1) {
        // set first slide as right slide if were sliding right from last slide
        alignment = ` ${rightClassName}`;
      } else if (index === items.length - 1 && currentIndex === 0) {
        // set last slide as left slide if were sliding left from first slide
        alignment = ` ${leftClassName}`;
      }
    }

    return alignment;
  }

  const getTranslateXForTwoSlide = (index) => {
    // For taking care of infinite swipe when there are only two slides
    //const { currentIndex, currentSlideOffset, previousIndex } = state;
    const indexChanged = currentIndex !== previousIndex;
    const firstSlideWasPrevSlide = index === 0 && previousIndex === 0;
    const secondSlideWasPrevSlide = index === 1 && previousIndex === 1;
    const firstSlideIsNextSlide = index === 0 && currentIndex === 1;
    const secondSlideIsNextSlide = index === 1 && currentIndex === 0;
    const swipingEnded = currentSlideOffset === 0;
    const baseTranslateX = -100 * currentIndex;
    let translateX = baseTranslateX + (index * 100) + currentSlideOffset;

    // keep track of user swiping direction
    // important to understand how to translateX based on last direction
    if (currentSlideOffset > 0) {
      direction = 'left';
    } else if (currentSlideOffset < 0) {
      direction = 'right';
    }


    // when swiping between two slides make sure the next and prev slides
    // are on both left and right
    if (secondSlideIsNextSlide && currentSlideOffset > 0) { // swiping right
      translateX = -100 + currentSlideOffset;
    }
    if (firstSlideIsNextSlide && currentSlideOffset < 0) { // swiping left
      translateX = 100 + currentSlideOffset;
    }

    if (indexChanged) {
      // when indexChanged move the slide to the correct side
      if (firstSlideWasPrevSlide && swipingEnded && direction === 'left') {
        translateX = 100;
      } else if (secondSlideWasPrevSlide && swipingEnded && direction === 'right') {
        translateX = -100;
      }
    } else {
      // keep the slide on the correct side if the swipe was not successful
      if (secondSlideIsNextSlide && swipingEnded && direction === 'left') {
        translateX = -100;
      }
      if (firstSlideIsNextSlide && swipingEnded && direction === 'right') {
        translateX = 100;
      }
    }

    return translateX;
  }

  const getThumbnailBarHeight = () => {
    if (isThumbnailVertical()) {
      //const { gallerySlideWrapperHeight } = state;
      return { height: gallerySlideWrapperHeight };
    }
    return {};
  }

  const getSlideStyle = (index) => {
    //const { currentIndex, currentSlideOffset, slideStyle } = state;
    const {
      infinite,
      items,
      useTranslate3D,
      isRTL,
    } = props;
    const baseTranslateX = -100 * currentIndex;
    const totalSlides = items.length - 1;

    // calculates where the other slides belong based on currentIndex
    // if it is RTL the base line should be reversed
    let translateX = (baseTranslateX + (index * 100)) * (isRTL ? -1 : 1) + currentSlideOffset;

    if (infinite && items.length > 2) {
      if (currentIndex === 0 && index === totalSlides) {
        // make the last slide the slide before the first
        // if it is RTL the base line should be reversed
        translateX = -100 * (isRTL ? -1 : 1) + currentSlideOffset;
      } else if (currentIndex === totalSlides && index === 0) {
        // make the first slide the slide after the last
        // if it is RTL the base line should be reversed
        translateX = 100 * (isRTL ? -1 : 1) + currentSlideOffset;
      }
    }

    // Special case when there are only 2 items with infinite on
    if (infinite && items.length === 2) {
      translateX = getTranslateXForTwoSlide(index);
    }

    let translate = `translate(${translateX}%, 0)`;

    if (useTranslate3D) {
      translate = `translate3d(${translateX}%, 0, 0)`;
    }

    return Object.assign({}, {
      WebkitTransform: translate,
      MozTransform: translate,
      msTransform: translate,
      OTransform: translate,
      transform: translate,
    }, slideStyle);
  }

  // eslint-disable-next-line no-unused-vars
  const getCurrentIndex = () => {             // wird in App.js verwendet...
    const { currentIndex } = this.state;
    return currentIndex;
  }

  const getThumbnailStyle = () => {
    let translate;
    const { useTranslate3D, isRTL } = props;
    //const { thumbsTranslate } = state;
    const verticalTranslateValue = isRTL ? thumbsTranslate * -1 : thumbsTranslate;

    if (isThumbnailVertical()) {
      translate = `translate(0, ${thumbsTranslate}px)`;
      if (useTranslate3D) {
        translate = `translate3d(0, ${thumbsTranslate}px, 0)`;
      }
    } else {
      translate = `translate(${verticalTranslateValue}px, 0)`;
      if (useTranslate3D) {
        translate = `translate3d(${verticalTranslateValue}px, 0, 0)`;
      }
    }
    return {
      WebkitTransform: translate,
      MozTransform: translate,
      msTransform: translate,
      OTransform: translate,
      transform: translate,
    };
  }

  const handleImageError = (event) => {
    const { onErrorImageURL } = props;
    if (onErrorImageURL && event.target.src.indexOf(onErrorImageURL) === -1) {
      /* eslint-disable no-param-reassign */
      event.target.src = onErrorImageURL;
      /* eslint-enable no-param-reassign */
    }
  }


  const renderThumbInner = (item) => {
    const { onThumbnailError } = props;
    const handleThumbnailError = onThumbnailError || handleImageError;

    return (
      <div className="image-gallery-thumbnail-inner">
        <img
          className="image-gallery-thumbnail-image"
          src={item.thumbnail}
          alt={item.thumbnailAlt}
          title={item.thumbnailTitle}
          onError={handleThumbnailError}
        />
        {
          item.thumbnailLabel && (
            <div className="image-gallery-thumbnail-label">
              {item.thumbnailLabel}
            </div>
          )
        }
      </div>
    );
  }


  const getSlideItems = () => {
    // const { currentIndex } = state;
    const {
      infinite,
      items,
      slideOnThumbnailOver,
      onClick,
      //lazyLoad,
      onTouchMove,
      onTouchEnd,
      onTouchStart,
      onMouseOver,
      onMouseLeave,
      // renderItem,
      // renderThumbInner,
      showThumbnails,
      showBullets,
    } = props;

    const slides = [];
    const Thumbnails = [];
    const bullets = [];

    items.forEach((item, index) => {
      const alignment = getAlignmentClassName(index);
      const originalClass = item.originalClass ? ` ${item.originalClass}` : '';
      const thumbnailClass = item.thumbnailClass ? ` ${item.thumbnailClass}` : '';
      const handleRenderItem = item.renderItem || props.renderItem || renderItem;
      const handleRenderThumbInner = item.renderThumbInner || props.renderThumbInner || renderThumbInner;

      const showItem = !props.lazyLoad || alignment || lazyLoaded[index];
      if (showItem && props.lazyLoad && !lazyLoaded[index]) {
        lazyLoaded[index] = true;
      }

      const slideStyle = getSlideStyle(index);

      const slide = (
        <div
          aria-label={`Go to Slide ${index + 1}`}
          key={`slide-${item.original}-${index}`}
          tabIndex="-1"
          className={`image-gallery-slide ${alignment} ${originalClass}`}
          style={slideStyle}
          onClick={onClick}
          onKeyUp={handleSlideKeyUp}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
          onTouchStart={onTouchStart}
          onMouseOver={onMouseOver}
          onFocus={onMouseOver}
          onMouseLeave={onMouseLeave}
          role="button"
        >
          {showItem ? handleRenderItem(item) : <div style={{ height: '100%' }} />}
        </div>
      );

      if (infinite) {
        // don't add some slides while transitioning to avoid background transitions
        if (shouldPushSlideOnInfiniteMode(index)) {
          slides.push(slide);
        }
      } else {
        slides.push(slide);
      }

      if (showThumbnails) {
        const igThumbnailClass = clsx(
          'image-gallery-thumbnail',
          thumbnailClass,
          { active: currentIndex === index },
        );
        Thumbnails.push(
          <button
            key={`thumbnail-${item.original}-${index}`}
            type="button"
            tabIndex="0"
            aria-pressed={currentIndex === index ? 'true' : 'false'}
            aria-label={`Go to Slide ${index + 1}`}
            className={igThumbnailClass}
            onMouseLeave={slideOnThumbnailOver ? onThumbnailMouseLeave : null}
            onMouseOver={event => handleThumbnailMouseOver(event, index)}
            onFocus={event => handleThumbnailMouseOver(event, index)}
            onKeyUp={event => handleThumbnailKeyUp(event, index)}
            onClick={event => onThumbnailClick(event, index)}
          >
            {handleRenderThumbInner(item)}
          </button>,
        );
      }
      if (showBullets) {
        // generate bullet elements and store them in array
        const bulletOnClick = (event) => {
          if (item.bulletOnClick) {
            item.bulletOnClick({ item, itemIndex: index, currentIndex });
          }
          // https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Function/call
          // function.prototype.call()
          return slideToIndex.call(this, index, event);  // OP: this - notwendig? 
        };
        const igBulletClass = clsx(
          'image-gallery-bullet',
          item.bulletClass,
          { active: currentIndex === index },
        );
        bullets.push(
          <button
            type="button"
            key={`bullet-${item.original}-${index}`}
            className={igBulletClass}
            onClick={bulletOnClick}
            aria-pressed={currentIndex === index ? 'true' : 'false'}
            aria-label={`Go to Slide ${index + 1}`}
          />,
        );
      }
    });

    return {
      slides,
      Thumbnails,
      bullets,
    };
  }

  const ignoreIsTransitioning = () => {
    /*
      Ignore isTransitioning because were not going to sibling slides
      e.g. center to left or center to right
    */
    const { items } = props;
    //const { previousIndex, currentIndex } = state;
    const totalSlides = items.length - 1;
    // we want to show the in between slides transition
    const slidingMoreThanOneSlideLeftOrRight = Math.abs(previousIndex - currentIndex) > 1;
    const notGoingFromFirstToLast = !(previousIndex === 0 && currentIndex === totalSlides);
    const notGoingFromLastToFirst = !(previousIndex === totalSlides && currentIndex === 0);

    return slidingMoreThanOneSlideLeftOrRight
      && notGoingFromFirstToLast
      && notGoingFromLastToFirst;
  }

  const isFirstOrLastSlide = (index) => {
    const { items } = props;
    const totalSlides = items.length - 1;
    const isLastSlide = index === totalSlides;
    const isFirstSlide = index === 0;
    return isLastSlide || isFirstSlide;
  }


  const slideIsTransitioning = (index) => {
    /*
    returns true if the gallery is transitioning and the index is not the
    previous or currentIndex
    */
    // const { isTransitioning, previousIndex, currentIndex } = state;
    const indexIsNotPreviousOrNextSlide = !(index === previousIndex || index === currentIndex);
    return isTransitioning && indexIsNotPreviousOrNextSlide;
  }

  const shouldPushSlideOnInfiniteMode = (index) => {
    /*
      Push (show) slide if slide is the current slide and the next slide
      OR
      The slide is going more than one slide left or right, but not going from
      first to last and not going from last to first

      Edge case:
      If you go to the first or last slide, when they're
      not left, or right of each other they will try to catch up in the background
      so unless were going from first to last or vice versa we don't want the first
      or last slide to show up during the transition
    */
    return !slideIsTransitioning(index)
      || (ignoreIsTransitioning() && !isFirstOrLastSlide(index));
  }

  const slideThumbnailBar = (previousIndex) => {
    //const { thumbsTranslate, currentIndex } = state;
    if (currentIndex === 0) {
      ThumbsTranslate(0);
    } else {
      const indexDifference = Math.abs(previousIndex - currentIndex);
      const scroll = getThumbsTranslate(indexDifference);
      if (scroll > 0) {
        if (previousIndex < currentIndex) {
          ThumbsTranslate(thumbsTranslate - scroll);
        } else if (previousIndex > currentIndex) {
          ThumbsTranslate(thumbsTranslate + scroll);
        }
      }
    }
  }

  const canSlide = () => {
    const { items } = props;
    return items.length >= 2;
  }

  const canSlideLeft = () => {
    const { infinite, isRTL } = props;
    return infinite || (isRTL ? canSlideNext() : canSlidePrevious());
  }

  const canSlideRight = () => {
    const { infinite, isRTL } = props;
    return infinite || (isRTL ? canSlidePrevious() : canSlideNext());
  }

  const canSlidePrevious = () => {
    // const { currentIndex } = state;
    return currentIndex > 0;
  }

  const canSlideNext = () => {
    // const { currentIndex } = state;
    const { items } = props;
    return currentIndex < items.length - 1;
  }

  const handleSwiping = ({ event, absX, dir }) => {
    const { preventDefaultTouchmoveEvent, disableSwipe, stopPropagation } = props;

    if (disableSwipe) return;
    const { swipingTransitionDuration } = props;
    setScrollDirection(dir);
    if (stopPropagation) event.stopPropagation();
    if ((preventDefaultTouchmoveEvent || scrollingLeftRight) && event.cancelable) {
      event.preventDefault();
    }
    if (!isTransitioning && !scrollingUpDown) {
      const side = dir === RIGHT ? 1 : -1;

      let currentSlideOffset = (absX / galleryWidth * 100);
      if (Math.abs(currentSlideOffset) >= 100) {
        currentSlideOffset = 100;
      }

      const swipingTransition = {
        transition: `transform ${swipingTransitionDuration}ms ease-out`,
      };


      setCurrentSlideOffset(side * currentSlideOffset);
      setSlideStyle(swipingTransition);

    } else {
      // don't move the slide
      setCurrentSlideOffset(0);
    }
  }

  const sufficientSwipe = () => {
    // const { currentSlideOffset } = state;
    const { swipeThreshold } = props;
    return Math.abs(currentSlideOffset) > swipeThreshold;
  }

  const handleOnSwiped = ({ event, dir, velocity }) => {
    const { disableSwipe, stopPropagation, flickThreshold } = props;
    //const { scrollingUpDown, scrollingLeftRight } = state;

    if (disableSwipe) return;

    const { isRTL } = props;
    if (stopPropagation) event.stopPropagation();
    if (scrollingUpDown) {
      // user stopped scrollingUpDown
      setScrollingUpDown(false);
    }

    if (scrollingLeftRight) {
      // user stopped scrollingLeftRight
      setScrollingLeftRight(false);
    }

    if (!scrollingUpDown) { // don't swipe if user is scrolling
      // if it is RTL the direction is reversed
      const swipeDirection = (dir === LEFT ? 1 : -1) * (isRTL ? -1 : 1);
      const isFlick = velocity > flickThreshold;
      handleOnSwipedTo(swipeDirection, isFlick);
    }
  }

  const handleOnSwipedTo = (swipeDirection, isFlick) => {
    // const { currentIndex, isTransitioning } = state;
    let slideTo = currentIndex;

    if ((sufficientSwipe() || isFlick) && !isTransitioning) {
      // slideto the next/prev slide
      slideTo += swipeDirection;
    }

    // If we can't swipe left or right, stay in the current index (noop)
    if ((swipeDirection === -1 && !canSlideLeft())
      || (swipeDirection === 1 && !canSlideRight())) {
      slideTo = currentIndex;
    }

    unthrottledSlideToIndex(slideTo);
  }

  const handleMouseDown = () => {
    // keep track of mouse vs keyboard usage for a11y
    imageGallery.current.classList.add('image-gallery-using-mouse');
  }

  const handleKeyDown = (event) => {
    const { disableKeyDown, useBrowserFullscreen } = props;
    // const { isFullscreen } = state;
    // keep track of mouse vs keyboard usage for a11y
    imageGallery.current.classList.remove('image-gallery-using-mouse');

    if (disableKeyDown) return;
    const LEFT_ARROW = 37;
    const RIGHT_ARROW = 39;
    const ESC_KEY = 27;
    const key = parseInt(event.keyCode || event.which || 0, 10);

    switch (key) {
      case LEFT_ARROW:
        if (canSlideLeft() && !playPauseIntervalId) {
          slideLeft(event);
        }
        break;
      case RIGHT_ARROW:
        if (canSlideRight() && !playPauseIntervalId) {
          slideRight(event);
        }
        break;
      case ESC_KEY:
        if (isFullscreen && !useBrowserFullscreen) {
          exitFullScreen();
        }
        break;
      default:
        break;
    }
  }

  const removeResizeObserver = () => {
    if (resizeObserver && imageGallerySlideWrapper && imageGallerySlideWrapper.current) {
      resizeObserver.unobserve(imageGallerySlideWrapper.current);
    }
  }

  const handleResize = () => {
    //const { currentIndex } = state;
    if (imageGallery && imageGallery.current) {
      setGalleryWidth(imageGallery.current.offsetWidth);
    }

    if (imageGallerySlideWrapper && imageGallerySlideWrapper.current) {
      setGallerySlideWrapperHeight(imageGallerySlideWrapper.current.offsetHeight);
    }

    if (thumbnailsWrapper && thumbnailsWrapper.current) {
      if (isThumbnailVertical()) {
        setThumbnailsWrapperHeight(thumbnailsWrapper.current.offsetHeight);
      } else {
        setThumbnailsWrapperWidth(thumbnailsWrapper.current.offsetWidth);
      }
    }

    // Adjust thumbnail container when thumbnail width or height is adjusted
    ThumbsTranslate(-getThumbsTranslate(currentIndex)); // OP: minus????
  }

  const initResizeObserver = (element) => {
    resizeObserver = new ResizeObserver(debounce((entries) => {
      if (!entries) return;
      entries.forEach(() => {
        handleResize();
      });
    }, 300));
    resizeObserver.observe(element.current);
  }

  const toggleFullScreen = () => {
    //const { isFullscreen } = state;
    if (isFullscreen) {
      exitFullScreen();
    } else {
      fullScreen();
    }
  }

  const togglePlay = () => {
    if (playPauseIntervalId) {
      pause();
    } else {
      play();
    }
  }


  const handleScreenChange = () => {
    /*
      handles screen change events that the browser triggers e.g. esc key
    */
    const { onScreenChange, useBrowserFullscreen } = props;
    const fullScreenElement = document.fullscreenElement
      || document.msFullscreenElement
      || document.mozFullScreenElement
      || document.webkitFullscreenElement;

    // check if screenchange element is the gallery
    const isFullscreen = imageGallery.current === fullScreenElement;
    if (onScreenChange) onScreenChange(isFullscreen);
    if (useBrowserFullscreen) setIsFullscreen(isFullscreen);
  }

  const slideLeft = (event) => {
    const { isRTL } = props;
    if (isRTL) {
      slideNext(event);
    } else {
      slidePrevious(event);
    }
  }

  const slideRight = (event) => {
    const { isRTL } = props;
    if (isRTL) {
      slidePrevious(event);
    } else {
      slideNext(event);
    }
  }

  const slidePrevious = (event) => {
    //const { currentIndex, currentSlideOffset, isTransitioning } = state;
    const { items } = props;
    const nextIndex = currentIndex - 1;

    if (isTransitioning) return;

    if (items.length === 2) {
      /*
        When there are only 2 slides fake a tiny swipe to get the slides
        on the correct side for transitioning
      */
      // https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react             
      setCurrentSlideOffset(currentSlideOffset + 0.001); // ...will reset once index changes
      setSlideStyle({ transition: 'none' }); // move the slide over instantly
      // OP: setState callback after sets...
      // add 25ms timeout to avoid delay in moving slides over
      window.setTimeout(() => slideToIndex(nextIndex, event), 25);
    } else {
      slideToIndex(nextIndex, event);
    }
  }

  const slideNext = (event) => {
    // const { currentIndex, currentSlideOffset, isTransitioning } = state;
    const { items } = props;
    const nextIndex = currentIndex + 1;

    if (isTransitioning) return;

    if (items.length === 2) {
      // same as above for 2 slides
      setCurrentSlideOffset(currentSlideOffset - 0.001);      // OP: funktioniert dieser setState Stack???
      setSlideStyle({ transition: 'none' })
      window.setTimeout(() => slideToIndex(nextIndex, event), 25);  // useEffect ungeeignet - wegen event?

    } else {
      slideToIndex(nextIndex, event);
    }
  }

  const handleThumbnailMouseOver = (event, index) => {
    const { slideOnThumbnailOver } = props;
    if (slideOnThumbnailOver) onThumbnailMouseOver(event, index);
  }

  const handleThumbnailKeyUp = (event, index) => {
    // a11y support ^_^
    if (isEnterOrSpaceKey(event)) onThumbnailClick(event, index);
  }

  const handleSlideKeyUp = (event) => {
    // a11y support ^_^
    if (isEnterOrSpaceKey(event)) {
      const { onClick } = props;
      onClick(event);
    }
  }

  const isThumbnailVertical = () => {
    const { thumbnailPosition } = props;
    return thumbnailPosition === 'left' || thumbnailPosition === 'right';
  }

  const addScreenChangeEvent = () => {
    screenChangeEvents.forEach((eventName) => {
      document.addEventListener(eventName, handleScreenChange);
    });
  }

  const removeScreenChangeEvent = () => {
    screenChangeEvents.forEach((eventName) => {
      document.removeEventListener(eventName, handleScreenChange);
    });
  }

  const fullScreen = () => {
    const { useBrowserFullscreen } = props;
    const gallery = imageGallery.current;
    if (useBrowserFullscreen) {
      if (gallery.requestFullscreen) {
        gallery.requestFullscreen();
      } else if (gallery.msRequestFullscreen) {
        gallery.msRequestFullscreen();
      } else if (gallery.mozRequestFullScreen) {
        gallery.mozRequestFullScreen();
      } else if (gallery.webkitRequestFullscreen) {
        gallery.webkitRequestFullscreen();
      } else {
        // fallback to fullscreen modal for unsupported browsers
        ModalFullscreen(true);
      }
    } else {
      ModalFullscreen(true);
    }
    setIsFullscreen(true);
  }

  const exitFullScreen = () => {
    // const { isFullscreen } = state;
    const { useBrowserFullscreen } = props;
    if (isFullscreen) {
      if (useBrowserFullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else {
          // fallback to fullscreen modal for unsupported browsers
          ModalFullscreen(false);
        }
      } else {
        ModalFullscreen(false);
      }
      setIsFullscreen(false);
    }
  }

  const pauseOrPlay = () => {
    const { infinite } = props;
    // const { currentIndex } = state;
    if (!infinite && !canSlideRight()) {
      pause();
    } else {
      slideToIndex(currentIndex + 1);
    }
  }

  const play = (shouldCallOnPlay = true) => {
    const {
      onPlay,
      slideInterval,
      slideDuration,
    } = props;
    // const { currentIndex } = state;
    if (!playPauseIntervalId) {
      setIsPlaying(true);
      playPauseIntervalId = window.setInterval(
        pauseOrPlay,
        Math.max(slideInterval, slideDuration),
      );
      if (onPlay && shouldCallOnPlay) {
        onPlay(currentIndex);
      }
    }
  }

  const pause = (shouldCallOnPause = true) => {
    const { onPause } = props;
    // const { currentIndex } = state;
    if (playPauseIntervalId) {
      window.clearInterval(playPauseIntervalId);
      playPauseIntervalId = null;
      setIsPlaying(false);
      if (onPause && shouldCallOnPause) {
        onPause(currentIndex);
      }
    }
  }
  /* unused
   const isImageLoaded = (item) => {
     
     //  Keep track of images loaded so that onImageLoad prop is not
     //  called multiple times when re-render the images
     
     const imageExists = loadedImages[item.original];
     if (imageExists) {
       return true;
     }
     // add image as loaded
     loadedImages[item.original] = true;
     return false;
   }
   */
  const handleImageLoaded = (event, item) => {
    const { onImageLoad } = props;
    const imageExists = loadedImages[item.original];
    if (!imageExists && onImageLoad) {
      loadedImages[item.original] = true; // prevent from call again
      // image just loaded, call onImageLoad
      onImageLoad(event);
    }
  }


  const renderItem = (item) => {
    // const { isFullscreen } = state;
    const { onImageError } = props;
    const HandleImageError = onImageError || handleImageError;
    const itemSrc = isFullscreen ? (item.fullscreen || item.original) : item.original;
    //console.log("imageId", item.ImageId)
    const clickevent = (e) => {
      //console.log(e.currentTarget.currentSrc);
      setImageClickPath(e.currentTarget.currentSrc);
      setImageTitle(e.currentTarget.title)
      toggleModal();
    }
    return (
      <React.Fragment>
        <div>
          {
            item.imageSet ? (
              <picture
                onLoad={event => handleImageLoaded(event, item)}
                onError={HandleImageError}>
                {
                  item.imageSet.map((source, index) => (
                    <source
                      key={`media-${source.srcSet}-${index}`}
                      media={source.media}
                      srcSet={source.srcSet}
                      type={source.type}
                    />
                  ))
                }
                <img
                  className="image-gallery-image"
                  alt={item.originalAlt}
                  src={itemSrc}
                  onClick={clickevent}
                />
              </picture>
            ) : (
                <img
                  className="image-gallery-image"
                  src={itemSrc}
                  alt={item.originalAlt}
                  srcSet={item.srcSet}
                  sizes={item.sizes}
                  title={item.originalTitle}
                  onLoad={event => handleImageLoaded(event, item)}
                  onError={HandleImageError}
                  onClick={clickevent}
                />
              )
          }

          {
            item.description && (
              <span className="image-gallery-description">
                {item.description}
              </span>
            )
          }
        </div>
        <Modali.Modal {...imageModal}>
          <InPictureMagnifier image={imageClickPath} />
        </Modali.Modal>
      </React.Fragment>
    );
  }

  //const { currentIndex,isFullscreen, modalFullscreen, isPlaying,  } = state;

  const {
    additionalClass,
    indexSeparator, // deprecate soon, and allow custom render
    isRTL,
    items,
    thumbnailPosition,
    renderFullscreenButton,
    renderCustomControls,
    renderLeftNav,
    renderRightNav,
    showBullets,
    showFullscreenButton,
    showIndex,
    showThumbnails,
    showNav,
    showPlayButton,
    renderPlayPauseButton,
  } = props;

  const thumbnailStyle = getThumbnailStyle();
  const { slides, Thumbnails, bullets } = getSlideItems();
  const slideWrapperClass = clsx(
    'image-gallery-slide-wrapper',
    thumbnailPosition,
    { 'image-gallery-rtl': isRTL },
  );

  const slideWrapper = (
    <div ref={imageGallerySlideWrapper} className={slideWrapperClass}>
      {renderCustomControls && renderCustomControls()}
      {
        canSlide() ? (
          <React.Fragment>
            {
              showNav && (
                <React.Fragment>
                  {renderLeftNav(slideLeft, !canSlideLeft())}
                  {renderRightNav(slideRight, !canSlideRight())}
                </React.Fragment>
              )
            }
            <Swipeable
              className="image-gallery-swipe"
              delta={0}
              onSwiping={handleSwiping}
              onSwiped={handleOnSwiped}
            >
              <div className="image-gallery-slides">
                {slides}
              </div>
            </Swipeable>
          </React.Fragment>
        ) : (
            <div className="image-gallery-slides">
              {slides}
            </div>
          )
      }
      {showPlayButton && renderPlayPauseButton(togglePlay, isPlaying)}
      {
        showBullets && (
          <div className="image-gallery-bullets">
            <div
              className="image-gallery-bullets-container"
              role="navigation"
              aria-label="Bullet Navigation"
            >
              {bullets}
            </div>
          </div>
        )
      }
      {showFullscreenButton && renderFullscreenButton(toggleFullScreen, isFullscreen)}
      {
        showIndex && (
          <div className="image-gallery-index">
            <span className="image-gallery-index-current">
              {currentIndex + 1}
            </span>
            <span className="image-gallery-index-separator">
              {indexSeparator}
            </span>
            <span className="image-gallery-index-total">
              {items.length}
            </span>
          </div>
        )
      }
    </div>
  );

  const igClass = clsx('image-gallery', additionalClass, { 'fullscreen-modal': modalFullscreen });
  const igContentClass = clsx('image-gallery-content', thumbnailPosition, { fullscreen: isFullscreen });
  const thumbnailWrapperClass = clsx(
    'image-gallery-thumbnails-wrapper',
    thumbnailPosition,
    { 'thumbnails-wrapper-rtl': !isThumbnailVertical() && isRTL },
  );
  return (
    <div
      ref={imageGallery}
      className={igClass}
      aria-live="polite"
    >
      <div className={igContentClass}>
        {(thumbnailPosition === 'bottom' || thumbnailPosition === 'right') && slideWrapper}
        {
          showThumbnails && (
            <div
              className={thumbnailWrapperClass}
              style={getThumbnailBarHeight()}
            >
              <div
                className="image-gallery-thumbnails"
                ref={thumbnailsWrapper}
              >
                <div
                  ref={thumbnails}
                  className="image-gallery-thumbnails-container"
                  style={thumbnailStyle}
                  aria-label="Thumbnail Navigation"
                >
                  {Thumbnails}
                </div>
              </div>
            </div>
          )
        }
        {(thumbnailPosition === 'top' || thumbnailPosition === 'left') && slideWrapper}
      </div>

    </div>
  );

}
export default ImageGallery;

ImageGallery.propTypes = {
  flickThreshold: number,
  items: arrayOf(shape({
    bulletClass: string,
    bulletOnClick: func,
    description: string,
    original: string.isRequired,
    fullscreen: string,
    originalAlt: string,
    originalTitle: string,
    thumbnail: string,
    thumbnailAlt: string,
    thumbnailLabel: string,
    thumbnailTitle: string,
    originalClass: string,
    thumbnailClass: string,
    renderItem: func,
    renderThumbInner: func,
    imageSet: imageSetType,
    srcSet: string,
    sizes: string,
  })).isRequired,
  showNav: bool,
  autoPlay: bool,
  lazyLoad: bool,
  infinite: bool,
  showIndex: bool,
  showBullets: bool,
  showThumbnails: bool,
  showPlayButton: bool,
  showFullscreenButton: bool,
  disableThumbnailScroll: bool,
  disableKeyDown: bool,
  disableSwipe: bool,
  useBrowserFullscreen: bool,
  preventDefaultTouchmoveEvent: bool,
  onErrorImageURL: string,
  indexSeparator: string,
  thumbnailPosition: oneOf(['top', 'bottom', 'left', 'right']),
  startIndex: number,
  slideDuration: number,
  slideInterval: number,
  slideOnThumbnailOver: bool,
  swipeThreshold: number,
  swipingTransitionDuration: number,
  onSlide: func,
  onBeforeSlide: func,
  onScreenChange: func,
  onPause: func,
  onPlay: func,
  onClick: func,
  onImageLoad: func,
  onImageError: func,
  onTouchMove: func,
  onTouchEnd: func,
  onTouchStart: func,
  onMouseOver: func,
  onMouseLeave: func,
  onThumbnailError: func,
  onThumbnailClick: func,
  renderCustomControls: func,
  renderLeftNav: func,
  renderRightNav: func,
  renderPlayPauseButton: func,
  renderFullscreenButton: func,
  renderItem: func,
  renderThumbInner: func,
  stopPropagation: bool,
  additionalClass: string,
  useTranslate3D: bool,
  isRTL: bool,
};

ImageGallery.defaultProps = {
  onErrorImageURL: '',
  additionalClass: '',
  showNav: true,
  autoPlay: false,
  lazyLoad: false,
  infinite: true,
  showIndex: false,
  showBullets: false,
  showThumbnails: true,
  showPlayButton: true,
  showFullscreenButton: true,
  disableThumbnailScroll: false,
  disableKeyDown: false,
  disableSwipe: false,
  useTranslate3D: true,
  isRTL: false,
  useBrowserFullscreen: true,
  preventDefaultTouchmoveEvent: false,
  flickThreshold: 0.4,
  stopPropagation: false,
  indexSeparator: ' / ',
  thumbnailPosition: 'left',
  startIndex: 0,
  slideDuration: 450,
  swipingTransitionDuration: 0,
  onSlide: null,
  onBeforeSlide: null,
  onScreenChange: null,
  onPause: null,
  onPlay: null,
  onClick: null,
  onImageLoad: null,
  onImageError: null,
  onTouchMove: null,
  onTouchEnd: null,
  onTouchStart: null,
  onMouseOver: null,
  onMouseLeave: null,
  onThumbnailError: null,
  onThumbnailClick: null,
  renderCustomControls: null,
  renderThumbInner: null,
  renderItem: null,
  slideInterval: 3000,
  slideOnThumbnailOver: false,
  swipeThreshold: 30,
  renderLeftNav: (onClick, disabled) => (

    <button
      type="button"
      className="image-gallery-icon image-gallery-left-nav"
      disabled={disabled}
      onClick={onClick}
      aria-label="Previous Slide"
    >
      <SVG icon="left" viewBox="6 0 12 24" />
    </button>
  ),
  renderRightNav: (onClick, disabled) => (
    <button
      type="button"
      className="image-gallery-icon image-gallery-right-nav"
      disabled={disabled}
      onClick={onClick}
      aria-label="Next Slide"
    >
      <SVG icon="right" viewBox="6 0 12 24" />
    </button>
  ),
  renderPlayPauseButton: (onClick, isPlaying) => (
    <button
      type="button"
      className="image-gallery-icon image-gallery-play-button"
      onClick={onClick}
      aria-label="Play or Pause Slideshow"
    >
      <SVG strokeWidth={2} icon={isPlaying ? 'pause' : 'play'} />
    </button>
  ),
  renderFullscreenButton: (onClick, isFullscreen) => (
    <button
      type="button"
      className="image-gallery-icon image-gallery-fullscreen-button"
      onClick={onClick}
      aria-label="Open Fullscreen"
    >
      <SVG strokeWidth={2} icon={isFullscreen ? 'minimize' : 'maximize'} />
    </button>
  ),
};
