import * as React from "react";
import jwt_decode from 'jwt-decode';
// Quellen: 
// https://levelup.gitconnected.com/learn-react-hooks-by-building-an-auth-based-to-do-app-c2d143928b0b
// Code: C:\React\_Context API\react-hooks-by-example-master


/** Custom types */
type UserAuth = {
  avatar: string;
  token: string;
  authenticated: boolean;
  id: string;
  email: string;
  username: string;
  roles: string;  // Plural!
};

/** Custom Hook: */

const useAuthHandler = (initialState: UserAuth) => {
  const [auth, setAuth] = React.useState(initialState);

  const setAuthStatus = (userAuth: UserAuth) => {
    window.localStorage.setItem("UserAuth", JSON.stringify(userAuth));
    setAuth(userAuth);
  };

  const setUnauthStatus = () => {
    window.localStorage.clear();
    setAuth(DEFAULT_USER_AUTH);
  };

  return {
    auth,
    setAuthStatus,
    setUnauthStatus
  };
};

/** Utils */
const DEFAULT_USER_AUTH = { avatar: "", 
token: "", authenticated: false, id: "", 
email: "", username:"", roles: ""};


// https://nozzlegear.com/blog/implementing-a-jwt-auth-system-with-typescript-and-node
// https://github.com/auth0/jwt-decode
interface EncodeResult {
  token: string,
  exp: number,
  iat: number
}
/** Return user auth from local storage value */
const getStoredUserAuth = (): UserAuth => {
  // Geprüft - ok: 28.08.20
  // https://stackoverflow.com/questions/847185/convert-a-unix-timestamp-to-time-in-javascript
  // https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Date/now
  // JS timestamps are in milliseconds and PHP (Unix) delivers in second
  const currentTime = Date.now() / 1000; // decoded.exp are in sconds!!!, so you have to multiply 
  // wether decoded.exp with 1000 or divide currentTime through 1000...
  
  // window.localStorage.clear();  //  Wieder RAUS!!!!! - nur zwecks test 
  const auth = window.localStorage.getItem("UserAuth");
  if (auth) {
    if (auth === 'undefined' || auth === null){
      return DEFAULT_USER_AUTH;
    }
    const authObj = JSON.parse(auth);
    
    const decoded: EncodeResult = jwt_decode(authObj.token);
    //console.log(new Date(decoded.exp), new Date(currentTime))
    if (decoded.exp < currentTime) {

      window.localStorage.clear();

      return DEFAULT_USER_AUTH;
    }
    return JSON.parse(auth);
  }
  return DEFAULT_USER_AUTH;
};


interface IAuthContextInterface {
  auth: UserAuth;
  setAuthStatus: (userAuth: UserAuth) => void;
  setUnauthStatus: () => void;
}

export const authContext = React.createContext<IAuthContextInterface>({
  auth: DEFAULT_USER_AUTH,
  setAuthStatus: () => {},
  setUnauthStatus: () => {}
});

const { Provider } = authContext;

const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
  const { auth, setAuthStatus, setUnauthStatus } = useAuthHandler( getStoredUserAuth() );

  return (
    <Provider value={{ auth, setAuthStatus, setUnauthStatus }}>
      {children}
    </Provider>
  );
};


export default AuthContextProvider;
