import React, { Component } from 'react';
import styles from './styles';
import Progress, { Stage } from './Progress';
//import Steps, {Step} from './Steps.js';
import { StepperContext } from './StepperProvider'
import { states } from './States.js';
//import CheckoutScrollBar from "./CheckoutScrollBar";
import Measure from 'react-measure';


// import  AuthContextProvider from "../contexts/AuthContext";
//import { authContext } from "../contexts/AuthContext";

//import Button from './components/Button';
const Header = () => (
	<StepperContext.Consumer>
		{(context) => {
			const { titel } = context  			
			const { stage, handleClick, handleClickBack } = context
			console.log("titel: " + titel)
			return (
				<div className="boxGrid headerGrid">
					<div style={styles.footerContainer}>
						<div style={styles.stageButtonBack}>
							<button className="btn btn-outline-secondary" disabled={stage === states.Warenkorb || stage === states.Fertig} onClick={handleClickBack.bind(this)}>Zurück</button>
						</div>	<div className="stepName">{titel}</div>
						<div style={styles.stageButton}>
							<button className="btn btn-outline-success" type="button" disabled={stage === states.Fertig} onClick={handleClick.bind(this)}>Weiter</button>
						</div>

					</div>
				</div>
			);
		}}
	</StepperContext.Consumer>
)


const Sidebar2 = ({ title, closeCheckout }) => {
	/* 
	<div className="boxGrid sidbar2Grid">
		
		<div style={styles.sidetbar2Container}>
			
			<button type="button" className="stepperClose" onClick={closeCheckout}>
				&times;
         	</button>
			{title}
		</div>
		
	</div>
	*/
	return null}
	
const Footer = ({ title }) => (
	<StepperContext.Consumer>
		{(context) => {
			const { stage, handleClick, handleClickBack } = context
			return (
				<div className="boxGrid footerGrid">
					<div style={styles.footerContainer}>
						<div style={styles.stageButtonBack}>
							<button className="btn btn-outline-secondary" disabled={stage === states.Warenkorb|| stage === states.Fertig} onClick={handleClickBack.bind(this)}>Zurück</button>
						</div>
						<div style={styles.stageButton}>
							<button className="btn btn-outline-success" type="button" disabled={stage === states.Fertig} onClick={handleClick.bind(this)}>Weiter</button>
						</div>
					</div>
				</div>
			)
		}}
	</StepperContext.Consumer>

)
// it's a function...
export const Step = ({ strKey, content }) => (
	<StepperContext.Consumer>
		{para => {   
			const { stage } = para
			return stage === strKey ? <div key={strKey} >{content}</div> : null
		}}
	</StepperContext.Consumer>
)

class Steps extends Component {
	constructor(props) {
		super(props);

		this.state = {
			innerHeight: window.innerHeight - 200,
			dimensions: {
				width: -1,
				height: -1,
			},

		};
		this.calcHeight = this.calcHeight.bind(this);
	}
	componentDidMount() {
		// https://www.hawatel.com/blog/handle-window-resize-in-react/  -- ohne bind klappt es nicht..
		window.addEventListener('resize', this.calcHeight);



	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.calcHeight)
	}
	calcHeight() {


		this.setState({ innerHeight: window.innerHeight - 200 }) //document.body.clientHeight})

	}


	render() {
		 // https://github.com/souporserious/react-measure  // style={{minheight:  this.state.dimensions.height }}
		let content = (
			<Measure bounds onResize={contentRect => { this.setState({ dimensions: contentRect.bounds }) }} >
				{({ measureRef, contentRect }) => (  
					<div ref={measureRef} className="contentGrid login-box"   >
						{this.props.children}
					</div>
				)}
			</Measure>);
		//console.log(this.state.innerHeight)
		return (
			<React.Fragment>
				{content}
			</React.Fragment>

		);
	}
}

class Stepper extends Component {
	static Progress = Progress
	static Steps = Steps
	static Stage = Stage
	static Step = Step
	static Header = Header
	static Footer = Footer
	static Sidebar2 = Sidebar2;
	render() {
		return (

			<div className="wrapperGrid" >
				{this.props.children}

			</div>

		);
	}
}

export default Stepper;

