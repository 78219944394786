import React from "react";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import "../../../scss/components/_checkout.scss";

function activeClass(active) {
  return (active ? 'btn form-check-label' : 'btn form-check-label radio-toolbar-unselected');
}
function activeStyle(active) {
  return (active ? style.radioButtonSelected : style.radioButtonUnSelected);
}
const style = {
  radioButtonSelected: {
    background: "none",
    borderColor: "green",
    color: "green",
    width: "calc(100% - 8px)",
    borderRadius: "10px",
    border: "2px solid green",

  },
  radioButtonUnSelected: {
    background: "none",
    borderColor: "gray",
    color: "#212529",
    width: "calc(100% - 8px)",
    borderRadius: "10px",
  }
}

const InPictureControls = props => {
  /*
    const [previewSizePercentage, setPreviewSizePercentage] = React.useState(35);
    const [opacityChange, setOpacityChange] = React.useState(1);
    const [previewHorizontalPos, setPreviewHorizontalPos] = React.useState("left");
    const [previewVerticalPos, setPreviewVerticalPos] = React.useState("bottom");
  */
  const {
    handleVerticalPos,
    handleHorizontalPos,
    handleSizeChange,
    handleOpacityChange,

    previewHorizontalPos,
    previewVerticalPos,
    previewSizePercentage,
    previewOpacity,
  } = props;
  const callPreviewVerticalPos = e => {
    //setPreviewVerticalPos(e.target.value)
    handleVerticalPos(e);
  };

  const callPreviewHorizontalPos = e => {
    //setPreviewHorizontalPos(e.target.value)
    handleHorizontalPos(e);
  };

  const callopacityChange = e => {
    //const value = Number(e);
    //setOpacityChange(value)
    handleOpacityChange(e);
  };

  const callhandleSizeChange = e => {
    //const value = Number(e);
    //setPreviewSizePercentage(value)
    handleSizeChange(e);
  };

  return (
    <div className="container-fluid">
      <div className="form-row">
        <h3>Einstellung Voransicht</h3>
      </div>
      <div className="form-row">
        Horizontale Ausrichtung:
         </div>
      <div className="form-row">
        <div className="col">
          <div className="radio-toolbar">
            <label className={activeClass(previewHorizontalPos === 'left')} style={activeStyle(previewHorizontalPos === 'left')} >
              <input className="form-check-input"
                style={{ display: "none" }}
                type="radio"
                name="previewHorizontalPos"
                value="left"
                onChange={callPreviewHorizontalPos}
              />
                Links
            </label>
          </div>
        </div>
        <div className="col">
          <div className="radio-toolbar">
            <label className={activeClass(previewHorizontalPos === 'right')} style={activeStyle(previewHorizontalPos === 'right')}>
              <input className="form-check-input"
                type="radio"
                name="previewHorizontalPos"
                value="right"
                onChange={callPreviewHorizontalPos}
              />
                Rechts
            </label>
          </div>
        </div>
      </div>
      <div className="form-row">
        Vertikale Ausrichtung:
         </div>

      <div className="form-row">

        <div className="col">
          <div className="radio-toolbar">
            <label className={activeClass(previewVerticalPos === 'top')} style={activeStyle(previewVerticalPos === 'top')}>
              <input className="form-check-input"
                type="radio"
                name="previewVerticalPos"
                value="top"
                onChange={callPreviewVerticalPos}
              />
          Oben
      </label>
          </div>
        </div>

        <div className="col">
          <label className="label-left">
            Voransicht Grösse (%):
            <RangeSlider
              min={0}
              max={50}

              value={previewSizePercentage}
              onChange={changeEvent => callhandleSizeChange(changeEvent.target.value)}
            />
          </label>
        </div>

      </div>

      <div className="form-row">
        <div className="col">
          <div className="radio-toolbar">
            <label className={activeClass(previewVerticalPos === 'bottom')} style={activeStyle(previewVerticalPos === 'bottom')} >
              <input className="form-check-input"
                style={{ display: "none" }}
                type="radio"
                name="previewVerticalPos"
                value="bottom"
                onChange={callPreviewVerticalPos}
              />
          Unten
      </label>
          </div>
        </div>

        <div className="col">
          <label className="label">
            Voransicht Opazität:
        <RangeSlider
              min={0}
              max={1}
              step={.1}
              value={previewOpacity}
              onChange={changeEvent => callopacityChange(changeEvent.target.value)}
            />
          </label>
        </div>

      </div>


    </div>
  );
};

export default InPictureControls;

/*


*/