import React, { useEffect } from 'react';

import { authContext } from '../context/AuthContext';

import Modali, { useModali } from '../modal/modali';
import LoginForm from '../LoginForm/loginForm'
export default  () => {
  const [firstModal, toggle] = useModali({
    animated: true,
    large: false,
    closeButton: false,
    overlayClose: false,
    onHide: () => {}, // return void, no function null and nothing...   onHide: () => alert('I\'m now hidden'),
  });
  useEffect(() =>  
  {
    toggle()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const auth = React.useContext(authContext);
  const callCloseLoginForm = (confToken) => {
    toggle();
    if (confToken !== undefined) {
      let timeoutHack;
      timeoutHack = setTimeout(() => {
        auth.setAuthStatus(
          {
            avatar: confToken.avatar,
            token: confToken.token.replace("Bearer ", ""),
            authenticated: true,
            id: confToken.id,
            email: confToken.email,
            username: confToken.username,  // OP prüfen, warum unbelegt...
            roles: confToken.roles
          }
        );
        
        clearTimeout(timeoutHack);
      }, 100);
    
    }
    //
  }
  return (
    <React.Fragment>     
      <Modali.Modal {...firstModal}>
        <LoginForm closeLoginForm={callCloseLoginForm}/>
      </Modali.Modal>
    </React.Fragment>
  );
};

