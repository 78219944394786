import React, { useState } from 'react';
import { useContext } from 'react';
import axios from 'axios';
import AppContext from "../../context/AppContext"
import { useStateExt } from "../../Helpers/hookHelper"
import  preventTransitions  from "../../Helpers/preventTransition"
import { Link } from 'react-router-dom'
// https://stackoverflow.com/questions/52852018/use-npm-uuid-in-reactjs
import { v1 as uuidv1 } from 'uuid';  //ES6 module synatax

//global.ppp = null;  // globale Variable in Checkout
//var ppp = null;
export default (props) => {  // PayPalPlus...
    const [isSdkReady, setIsSdkReady] = useState(false);
    const [approval_url, setApproval_url] = React.useState(null);
    const [pppload, setPppload] = React.useState(false);
    const [script, setScript] = React.useState(null);   
    const appContext = useContext(AppContext);

    const initialState = {
        isLoading: false,
        isSubmitting: false,  // OP: noch nicht eingebunden - Quelle suchen...
        errorMessage: "",
        messageFromServer: "",
        updated: false,     // Textänderungen durchgeführt noch nicht gespeichert
    };
    const [data, setData] = useStateExt(initialState, "Data: ");

    React.useEffect(() => {
        if (!isSdkReady) {

            var erg = window.localStorage.getItem("uuidv1");
            if (erg === null) {
                erg = uuidv1();
                window.localStorage.setItem("uuidv1", erg);
                appContext.updateUuidv1(erg);  // OP: macht hier erstmalig Probleme - Wert lässt sich später nicht abrufen...
            }
            setData({
                ...data,
                isSubmitting: true,
                errorMessage: "",
                messageFromServer: "",
                error: false,
            });
            axios
                .get('/api/paypal/create_payment', {
                    //body:
                    params: {
                        OrderNumber: uuidv1(), //"47110815",  // als Testmöglich: kompatible zu ExtBoilerplate
                        Uuidv1: erg, //"47110815",  
                        cart: props.state.cart,
                        Lieferung: props.state.Lieferung,
                        Abrechnung: props.state.Abrechnung,


                        // Todo:
                        // Next step: Generate a transaction
                    },
                })
                // Zahlungsgenerierung auf dem Server anspossen in Form einer internen "Auftragsnummer".

                .then((response) => {
                    console.log(response);
                    appContext.updateAmount(response.data.amount);
                    setApproval_url(response.data.payment);  // sync Problem: keine Auswirkung - workaround - als Parameter übergeben oder PlusSdk von woanders aus starten
                    if (!isSdkReady) { addpppPlusSdk(response.data.payment) }
                })
                .catch((error) => {  // PseudoError z.b. status(400) - programmierer gesteuert...
                    setData({
                        ...data,
                        messageFromServer: JSON.stringify(error),
                        errorMessage: error.message,
                        isLoading: false,
                        isSubmitting: false,
                        error: true,
                    });
                    console.error(error);

                });
        }
        return function cleanup() {
            if (script) {
                document.body.removeChild(script)
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSdkReady])

    const onloadppp = () => {
        setPppload(true)
        console.log("onloadppp")
    }

    const handleClick = (e) => {
        //debugger;
        e.preventDefault();
        // ppp.doCheckout(); 
        // 26.09.20 - raus! hierdurch würde 
        // https://www.sandbox.paypal.com/webapps/hermes?country=DE&useraction=continue&token=EC-6V927100NL3614513&country.x=DE&locale.x=de_DE
        // sofort aufgerufen werden und Bestellung kontrollieren übersprungen werden

    }

    const addpppPlusSdk = (pApproval_url) => {
        console.log(approval_url)  // muss drin bleiben, workaround...
        // http://jamesknelson.com/rendering-react-components-to-the-document-body/

        // Anleitung:
        // https://www.paypalobjects.com/webstatic/de_DE/downloads/PayPal-PLUS-IntegrationGuide.pdf
        // PayPal PLUS - Germany: https://developer.paypal.com/docs/paypal-plus/germany/
        // bzw. direkt auf https://developer.paypal.com/docs/paypal-plus/germany/integrate/integrate-payment-wall/#
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://www.paypalobjects.com/webstatic/ppplus/ppplus.min.js";
        script.async = true;
        script.type = "text/javascript";

        document.body.appendChild(script);
        
        script.onload = () => {
            // https://developer.paypal.com/docs/paypal-plus/germany/
            // console.log(ppp);
            // ppp ist globale react Variable, window könnte das IFrame sein und .app.PPP ist eine ppplus funktion,
           
            var ppp = window.PAYPAL.apps.PPP({
                //"onContinue:": this.onContinue_CB(),  // ... test funktioniert...
                //"onLoad:": this.onLoad_CB(),
                "approvalUrl": pApproval_url, //approval_url, //"https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=EC-8M7236010S5344322",
                "placeholder": "ppplus",
                "mode":  process.env.NODE_ENV === 'production' ? "live":"sandbox",
                "country": "DE",
                "language": "de_DE",   // OP: optional  "en_US" per Parameter
                "buttonLocation": "outside",
                preselection: "paypal", // paypal is default - OP: Test fehlt noch...
                disableContinue: "MyCheckoutButtonID",
                enableContinue: "MyCheckoutButtonID",
                onLoad: onloadppp,
                showPuiOnSandbox: true,
                /*thirdPartyPaymentMethods:  // OP - Vorkasse - geht vorraussichtlich nur, wenn ich ein https logo ansprechen kann.. 

                    [{
                        "redirectUrl": "http://localhost:3000/payPalVorkasse",
                        "methodName": "Bank Transfer",
                        "imageUrl": "https://localhost:30001/icons/checked2.png",
                        "description": "Vorkasse Überweisung"
                    }]
                */
            });
            setIsSdkReady(true);
            global.ppp = ppp;

        };
        script.onerror = () => {
            throw new Error("Script loading Error: https://www.paypalobjects.com/webstatic/ppplus/ppplus.min.js could not be loaded.");
        };
        document.body.appendChild(script);
        setScript(script)
    }
    //debugger;



    //debugger;
    /*   
   const approval_url = "https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=EC-2LK86402SF642662H"
   var ppplus = "ppplus";
   var mode = "sandbox"; 
   var country = "DE";
   var ppp = PAYPAL.apps.PPP(approval_url={approval_url}, placeholder={ppplus}, mode={mode}, country={country});
  
  console.log(this.isSdkReady); //, this.props.state.currentStep)
 */
    console.log(global.ppp)  // onClick={"global.ppp.doCheckout()"}
    console.log(isSdkReady)
    console.log('Zahlungsart - currentstep: ', props.state.Zahlungsart.bezahlart)

    return (
        <div>
            {preventTransitions(true)}
            {pppload ? (
                <div>
                    <h1>{process.env.NODE_ENV === 'production' ? "":"* "}Bitte eine Bezahlmethode wählen: </h1>
                    <div id="ppplus"> </div>

                </div>
            ) :
                (

                    <div>
                        <h1>{process.env.NODE_ENV === 'production' ? "":"* "}Einen Moment bitte... mögliche Bezahlmethoden von PayPal werden geladen... </h1>
                        <div id="ppplus"> </div>
                        <button type="submit" id="MyCheckoutButtonID" onClick={handleClick.bind(this)}>
                            Mögliche Bezahlmethoden von PayPal werden geladen...
                        </button>
                    </div>

                )
            }
            <br></br>
            <div>
                {data.messageFromServer && (
                    <span className="form-success">
                        <textarea className="form-control"
                            rows={10}
                            value={data.messageFromServer}
                            name="description"
                            readOnly
                        />
                    </span>
                )} </div>
            <br></br>
            {data.errorMessage && (
                <span className="form-error">{data.errorMessage} - Bezahlung NICHT durchgeführt!
                    <br />
                    <Link className="stepperClose" to="/">
                        Zurück zur Galerie
             </Link>
                    <br />
             oder
                    <br />
                    <Link className="stepperClose" to="/contact">
                        Kontaktseite
             </Link>

                </span>

            )}
        </div>

    )

};
