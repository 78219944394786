import React from 'react';


import errorClass from './lib'
// import mySelect from '../../../elements/SelectwithKey';
import Input from '../../elements/Input';
//import axios from 'axios';
import Select from 'react-select';
import preventTransitions from "../../Helpers/preventTransition"
function activeClass(active) {
  return (active ? 'btn  form-check-label radio-toolbar-selected' : 'btn form-check-label radio-toolbar-unselected');
}

export default (props) => {


  const { land } = props.state.Lieferung;
  // https://stackoverflow.com/questions/47278189/use-different-key-for-searching-instead-of-value-or-label-in-react-select-in-rea 
  // https://reactjs.org/docs/handling-events.html
  //debugger;
  console.log('Lieferung - this.props.state: ', props.state)
  console.log('Lieferung - this.props.state.Lieferung.land: ', land)
  //    console.log('Anrede: ', this.props.state.valDelivery.anrede.message)

  // Radio Example: https://codesandbox.io/s/mzynnpzwp8
  // Besonderheit hier - activeClass setzt checked in Form von grüne Umrandung

  return (
    <React.Fragment>
      {preventTransitions(true)}
      <div className="container-fluid ">
        <div className="form-row">
          Anrede:
         </div>
        <div className="form-row">
          <div className="col">
            <div className="radio-toolbar">
              <label className={activeClass(props.state.Lieferung.anrede === 'Frau')}>
                <input className="form-check-input"
                  style={{ display: "none" }}
                  type="radio"
                  name="anrede"
                  value="Frau"
                  onChange={props.handleChange}
                />
                Frau
            </label>
            </div>
          </div>
          <div className="col">
            <div className="radio-toolbar">
              <label className={activeClass(props.state.Lieferung.anrede === 'Herr')}>
                <input className="form-check-input"
                  type="radio"
                  name="anrede"
                  value="Herr"
                  onChange={props.handleChange}
                />
                Herr
            </label>
            </div>
          </div>
          <span className="invalid-feedback d-block">{props.state.valDelivery.anrede.message}</span>
          <div>
          </div>

        </div>
        <div className="form-row">
          Titel:
         </div>
        <div className="form-row">
          <div className="col">
            <div className="radio-toolbar">
              <label className={activeClass(props.state.Lieferung.titel === 'Dr.')}>
                <input className="form-check-input"
                  style={{ display: "none" }}
                  type="radio"
                  name="titel"
                  value="Dr."
                  onChange={props.handleChange}
                />
                Dr.
              </label>
            </div>
          </div>
          <div className="col">
            <div className="radio-toolbar">
              <label className={activeClass(props.state.Lieferung.titel === 'Prof.')}>
                <input className="form-check-input"
                  type="radio"
                  name="titel"
                  onChange={props.handleChange}

                  value="Prof."
                />
                Prof.
              </label>
            </div>
          </div>
          <div className="col">
            <div className="radio-toolbar">
              <label className={activeClass(props.state.Lieferung.titel === 'Prof. Dr.')}>
                <input className="form-check-input"
                  type="radio"
                  name="titel"
                  onChange={props.handleChange}

                  value="Prof. Dr."
                />
                Prof. Dr.
              </label>
            </div>
          </div>
          <div className="col">
            <div className="radio-toolbar">
              <label className={activeClass(props.state.Lieferung.titel === '')}>
                <input className="form-check-input"
                  type="radio"
                  name="titel"
                  onChange={props.handleChange}
                  value=""
                />&nbsp;
              </label>
            </div>
          </div>
          <div>
          </div>
        </div>



        <Input inputtype={'text'}
          title={'Vorname: *'}
          name={'vorname'}
          value={props.state.Lieferung.vorname}
          placeholder={'Vorname '}
          onChange={props.handleChange}
          className={`form-control ${errorClass(props.state.valDelivery.vorname.isInvalid)}`}
          errormessage={props.state.valDelivery.vorname.message}
        />
        <Input inputtype={'text'}
          title={'Nachname: *'}
          name={'nachname'}
          value={props.state.Lieferung.nachname}
          placeholder={'Nachname '}
          onChange={props.handleChange}
          className={`form-control ${errorClass(props.state.valDelivery.nachname.isInvalid)}`}
          errormessage={props.state.valDelivery.nachname.message}
        />
        <Input inputtype={'text'}
          title={'Firmenname (optional):'}
          name={'firmenname'}
          value={props.state.Lieferung.firmenname}
          placeholder={'Firmenname - optional '}
          onChange={props.handleChange}
        />
        <div className="form-row">
          <div className="col">
            <Input inputtype={'text'}
              title={'Strasse: *'}
              name={'strasse'}
              value={props.state.Lieferung.strasse}
              placeholder={'Strasse '}
              onChange={props.handleChange}
              className={`form-control ${errorClass(props.state.valDelivery.strasse.isInvalid)}`}
              errormessage={props.state.valDelivery.strasse.message}
            />
          </div>
          <div className="col">
            <Input inputtype={'text'}
              title={'Hausnummer: *'}
              name={'hausnummer'}
              value={props.state.Lieferung.hausnummer}
              placeholder={'Hausnummer '}
              onChange={props.handleChange}
              className={`form-control ${errorClass(props.state.valDelivery.hausnummer.isInvalid)}`}
              errormessage={props.state.valDelivery.hausnummer.message}
            />
          </div>
        </div>


        <Input inputtype={'text'}
          title={'Packstation (optional):'}
          name={'packstation'}
          value={props.state.Lieferung.packstation}
          placeholder={'Adressen für Packstation mit Nummer, z.B. "Packstation 125"'}
          onChange={props.handleChange}
        />
        <div className="form-row">
          <div className="col">
            <Input inputtype={'text'}
              title={'Postleitzahl: *'}
              name={'postleitzahl'}
              value={props.state.Lieferung.postleitzahl}
              placeholder={'Postleitzahl '}
              onChange={props.handleChange}
              className={`form-control ${errorClass(props.state.valDelivery.postleitzahl.isInvalid)}`}
              errormessage={props.state.valDelivery.postleitzahl.message}
            />
          </div>
          <div className="col">
            <Input inputtype={'text'}
              title={'Ort: *'}
              name={'ort'}
              value={props.state.Lieferung.ort}
              placeholder={'Ort '}
              onChange={props.handleChange}
              className={`form-control ${errorClass(props.state.valDelivery.ort.isInvalid)}`}
              errormessage={props.state.valDelivery.ort.message}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col">
            <div className="form-group">
              <label htmlFor={'land'}> {'Land: *'} </label>
              <Select
                name={'landLieferung'}
                value={land}
                onChange={props.handleChange}
                options={props.state.countries}
                getOptionLabel={(option) => option.de}
                getOptionValue={(option) => option.CountryId}
              />
            </div>
          </div>
          <div className="col">
            <Input inputtype={'text'}
              title={'Region:'}
              name={'region'}
              value={props.state.Lieferung.region}
              placeholder={'Bundesland / Region / Gemeinde / Kanton (optional) '}
              onChange={props.handleChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col">
            <Input inputtype={'text'}
              title={'Telefonnummer für Rückfragen:'}
              name={'telefonnummer'}
              value={props.state.Lieferung.telefonnummer}
              placeholder={'wird auf Wunsch auf das Etikett gedruckt zwecks Liefererleichterung'}
              onChange={props.handleChange}
            />
          </div>
        </div>

      </div>
    </React.Fragment>
  );


}
