//import React from 'react';

const styles = {
	container: {
		width: 'calc(100% - 20px)',
		height: 'calc(100vh - 20px)',
		display: 'flex',
		//overflowY: 'scroll',
	},
	footerContainer: {  // vererst auch für header geused...
		display: 'flex',
		width: 'calc(100% - 0px)',
		//margin: '15px 0px 15px 15px',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '40px',
		//background: 'white',
		//border: "2px solid #ccc",
		borderRadius: '8px',
	},
	sidetbar2Container: {
		display: 'flex',
		width: "75px",
		//margin: '15px 0px 15px 15px',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: 'calc(100% - 0px)',
		background: 'white',
		//border: "2px solid #ccc",
		borderRadius: '8px',
		overflowX: 'hidden',
	},
	progressContainer2: {
		display: 'flex',
		width: "96px",    // OP gibt einen hässlichen Streifen muss noch optimiert werden
		//margin: '15px 0px 15px 15px',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: 'calc(100% - 0px)',
		background: 'white',
		//border: "2px solid #ccc",
		borderRadius: '8px',
		overflowX: 'hidden',
	},
	progressContainer: {  // unused
		display: 'flex',
		width: "10px",
		margin: '15px 0px 15px 15px',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: 'calc(100% - 20px)',
		background: 'white',
		border: "2px solid #ccc",
		borderRadius: '8px',
		//overflowY: 'scroll',
		//overflowX: 'hidden',
	},
	stagesContainer: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		margin: '15px 0px 15px 15px',
		height: 'calc(100% - 20px)',
		border: "2px solid #ccc",
		borderRadius: '8px',
	},
	stages: {
		display: 'flex',
		flex: 1,
		position: 'relative',
		borderRadius: '8px',
		width: 'calc(100% - 20px)',
		// justifyContent: 'center',
		// alignItems: 'center',

	},
	header: {
		height: '60px',
		marginLeft: "20px",
		marginTop: "20px",
		border: "2px solid #ccc",
		borderRadius: '8px',
		color: '#6bada7',
		fontWeight: 700,
		fontSize: '24px',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: '15px'
	},
	stepperCloseButtonX: {
		display: 'flex',
		justifyContent: 'flex-end',
		height: "30px",
		marginTop: "15px",
		marginRight: "5px",
		marginBottom: '10px'
	},
	stageContent: {
		display: 'flex',
		margin: '10px',
		borderRadius: '8px',
		width: 'calc(100%)',
		height: 'calc(100% - 20px)',
		background: '#eee',
		position: "absolute",
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: "40px",
		color: '#a1a1a1',
		fontWeight: 700
	},

	footer: {
		height: '50px',
		marginLeft: "20px",
		border: "2px solid #ccc",
		borderRadius: '8px',
		color: '#6bada7',
		fontWeight: 700,
		fontSize: '24px',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: '15px'
	},
	stageButton: {
		display: 'flex',
		justifyContent: 'flex-end',
		//height: "40px",
		marginTop: "15px",
		marginRight: "5px",
		marginBottom: '20px'
	},
	stageButtonBack: {
		position: 'relative',
		bottom: '0px',
		left: '0px',
		justifyContent: 'flex-start',
		height: "40px",
		marginTop: "10px",
		marginLeft: "5px",
		marginBottom: '10px'
	},
	circle: {
		marginTop: "20px",
		marginBottom: "20px",
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: '#ccc',
		width: '30px',
		height: '30px',
		background: 'white',
		borderRadius: '40px',
		border: '3px solid #ccc',
		zIndex: '3',
		position: 'relative'
	},
	circleActive: {
		width: '50px',
		height: '50px',
		border: '3px solid #ccc',
	},
	circleComplete: {
		border: '3px solid #6bada7',
	},
	blindradioButton:{	
			display: "none",
		  
			
		
	},
	empty:{}
}



export default styles
