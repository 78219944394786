import React, { useState, useContext } from "react";
//import ExampleContainer from "./ExampleContainer";
//import { PictureInPictureMagnifier } from "react-image-magnifiers";
import { PictureInPictureMagnifier } from "../../react-image-magnifiers-masters/src"
import InPictureControls from "./InPictureControls"
import SideBySideMagnifier from "./SideBySideMagnifier"
//import "./style.css";
import AppContext from "../../../context/AppContext"

const InPictureMagnifier = props => {
  const [previewHorizontalPos, setPreviewHorizontalPos] = useState("left");
  const [previewVerticalPos, setPreviewVerticalPos] = useState("bottom");
  const [previewSizePercentage, setPreviewSizePercentage] = useState(35);
  const [previewOpacity, setPreviewOpacity] = useState(1);  // setPreviewOpacity
  const [shadow, setShadow] = useState(false);

  const [show, setShow] = useState(true);

  const [alwaysInPlace,] = useState(false); // setAlwaysInPlace
  const [overlayOpacity,] = useState(0.6);
  const [switchSides,] = useState(false); // setSwitchSides
  const [fillAvailableSpace,] = useState(false); //setFillAvailableSpace
  const [fillAlignTop,] = useState(false); //  setFillAlignTop

  const [fillGapLeft,] = useState(0); //  setFillGapLeft
  const [fillGapRight,] = useState(10); // setFllGapRight
  const [fillGapTop,] = useState(10); // setFillGapTop
  const [fillGapBottom,] = useState(10);  // setFillGapBottom


  const imageContext = useContext(AppContext);
  const [legalSize, setLegalSize] = useState(false);
  React.useEffect(() => {                   // componentDidMount()
    setShow(true);
    //const show = () => {this.setState(() => ({ show: true }));  };    
  }, [previewVerticalPos, previewHorizontalPos, previewSizePercentage])



  const handleVerticalPos = e => {
    const value = e.target.value;
    setPreviewVerticalPos(value);
    setShow(false);
    //this.setState(() => ({ previewVerticalPos: value, show: false }), this.show);

  };
  const handleHorizontalPos = e => {
    const value = e.target.value;
    setPreviewHorizontalPos(value);
    setShow(false);
    // this.setState(() => ({ previewHorizontalPos: value, show: false }), this.show);
  };

  const handleShadowChange = e => {
    const value = Boolean(e.target.value);
    setShadow(value);
    // this.setState(() => ({ shadow: value }));
  };

  const handleSizeChange = e => {
    const value = Number(e);
    setPreviewSizePercentage(value);
    setShow(false);
    // this.setState(() => ({ previewSizePercentage: value, show: false }), this.show );
  };

  const handleOpacityChange = e => {
    const value = Number(e);
    setPreviewOpacity(value);
    // this.setState(() => ({ previewOpacity: value }));
  };


 
  React.useEffect(() => {                   // componentDidMount()
  
    if (legalSize !== imageContext.legalSizeGetter) {
      setLegalSize(imageContext.legalSizeGetter);
    }
    //const show = () => {this.setState(() => ({ show: true }));  };    
  }, [imageContext.legalSizeGetter,legalSize]) // legalSize eingefügt - geändert 31.10.20

  //console.log("Target - legalSize " + new Date().toLocaleTimeString() + ":", legalSize);
 
  const { image, largeImage } = props;
  //console.log("previewSizePercentage: ", previewSizePercentage)
  return (
    <React.Fragment>
      <div className="flex">
        <div >   {/*JS 20.10.20 */}
          {show ? (previewSizePercentage > 0 ?
            (<PictureInPictureMagnifier
              className="input-position"
              imageSrc={image}
              largeImageSrc={largeImage}
              previewHorizontalPos={previewHorizontalPos}
              previewVerticalPos={previewVerticalPos}
              previewSizePercentage={previewSizePercentage}
              previewOpacity={previewOpacity}
              shadow={shadow}
            />) :
            (
              <SideBySideMagnifier
                className="input-position"
                style={{ order: switchSides ? "1" : "0" }}
                imageSrc={image}
                largeImageSrc={largeImage}
                alwaysInPlace={alwaysInPlace}
                overlayOpacity={overlayOpacity}
                switchSides={switchSides}
                zoomPosition="left"
                inPlaceMinBreakpoint={641}
                fillAvailableSpace={fillAvailableSpace}
                fillAlignTop={fillAlignTop}
                fillGapTop={fillGapTop}
                fillGapRight={fillGapRight}
                fillGapBottom={fillGapBottom}
                fillGapLeft={fillGapLeft}
                zoomContainerBorder="1px solid #ccc"
                zoomContainerBoxShadow="0 4px 8px rgba(0,0,0,.5)"
              />
            )
          ) : null}
          {/* */}

          {show && legalSize ? (  // Lösung: Wichtig! show und legalSize zusammen macht "einen Schuh daraus!"
            <InPictureControls
              handleVerticalPos={handleVerticalPos}
              handleHorizontalPos={handleHorizontalPos}
              handleSizeChange={handleSizeChange}
              handleOpacityChange={handleOpacityChange}
              // ununsed:
              handleShadowChange={handleShadowChange}

              previewHorizontalPos= {previewHorizontalPos}
              previewVerticalPos = {previewVerticalPos}
              previewSizePercentage = {previewSizePercentage}
              previewOpacity = {previewOpacity}
            />
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
}

export default InPictureMagnifier;
