import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { authContext } from "../context/AuthContext";
import logo from '../assets/Malerpalette_mit_2_Pinseln.gif';
import LoginBar from "./LoginBar"
import Warenkorb from "./Warenkorb"
import SearchForm from './searchForm'
// https://hackernoon.com/withrouter-advanced-features-of-react-router-for-single-page-apps-42b2a1a0d315
import { withRouter } from 'react-router-dom';

import { roleKey } from "../Helpers/evalRechte"
export default withRouter((props) => {

  const [collapsed, setCollapsed] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const Cx = React.useContext(authContext);

  const toggleNavbar = () => { setCollapsed(!collapsed) }
  const toggleOpen = () => setDropdownOpen(!dropdownOpen)
  const menuClass = `dropdown-menu${dropdownOpen ? " show" : ""}`;
  const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
  const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

  // Op: Links für Adminstratoren - hier sollen entweder die links dupliziert werden oder hauptlinks und authlinks integriert werden.
  const adminLinks = (
    <ul className="navbar-nav ml-auto">
      <li className="nav-item">
        <Link className="nav-link" to="/dashboard">Dashboard</Link>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" activeStyle={{
          fontWeight: "bold",
          color: "red"
        }} to="/checkout">Checkout</NavLink>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/paypalsuccess">Fertig</Link>
      </li>     
      <li className="nav-item">
        <Link className="nav-link" to="/impressum">Impressum</Link>
      </li>
    </ul>
  )
  const benutzerLinks = (  // für Hauptuser z.b. Artikelpflege.
    <ul className="navbar-nav ml-auto">

      <li className="nav-item">
        <Link className="nav-link" to="/contact">Kontakt</Link>
      </li>

      <li className="nav-item">
        <Link className="nav-link" to="/impressum">Impressum</Link>
      </li>
    </ul>
  )

  const guestLinks = (    // für Standartbenutzer
    <ul className="navbar-nav ml-auto">
      <li className="nav-item">
        <Link className="nav-link" to="/contact">Kontakt</Link>
      </li>

      <li className="nav-item">
        <Link className="nav-link" to="/impressum">Impressum</Link>
      </li>
    </ul>
  )
 

  const hauptBenutzerLinks = (    // für Standartbenutzer
    <ul className="navbar-nav ml-auto">
      <li className="nav-item">
        <Link className="nav-link" to="/contact">Kontakt</Link>
      </li>

      <li className="nav-item">
        <Link className="nav-link" to="/impressum">Impressum</Link>
      </li>
    </ul>
  )


  const evalRechte = () => {
    const role = Cx.auth.roles;
    switch (role) {
      case roleKey.Admin:
        return (adminLinks);
      case roleKey.Hauptbenutzer:
        return (hauptBenutzerLinks);
      case roleKey.Gast:
        return (guestLinks)
      default:
        return  (benutzerLinks)
    }

  }
  // console.log(Cx.auth.authenticated);
  // console.log(Cx.auth.roles);
  return (

    <header className="navbar navbar-expand-lg navbar-dark transparent-nav" style={{ padding: "0.5rem 5rem 0.5rem 0.5rem" }}>
      <div className="container" color="light" expand="md">
        <div className="brand">
            <Link to="/"><img className="logo" src={logo} alt="Firmenlogo" title="Zurück zur Galerie" /></Link>
        </div>
        <button onClick={() => { toggleNavbar() }} className={`${classTwo}`} type="button" title="Menü öffnen, schliessen"
          data-toggle="collapse" data-target="#navbarResponsive"
          aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
         {/*  */}
        <SearchForm /> 
        <LoginBar withLogout={true} />
        <Warenkorb />
       
        <div className={`${classOne}`} id="navbarResponsive">
          <ul className="navbar-nav ml-auto">
            {Cx.auth.roles === roleKey.Admin && (

              <div className="dropdown" onClick={() => toggleOpen()}>
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true">
                  Kontakt
                </button>
                <div className={menuClass} aria-labelledby="dropdownMenuButton">
                  <a className="dropdown-item" href="/contact">
                    Kontaktseite
                  </a>
                  <a className="dropdown-item" href="/client_Who_am_I">
                    Wer bin ich?
                  </a>
                  <a className="dropdown-item" href="#remark?">
                    Sprache
                  </a>                 
                  <a className="dropdown-item" href="/version">
                  Version
                </a>
                </div>
              </div>)}
            {!Cx.auth.authenticated ? (guestLinks) : (evalRechte())} {/*adminLinks  guestLinks*/}
          </ul>
        </div>
        {/*  */}</div>
    </header>

  )
})
/*






{Cx.auth.roles=== roleKey.Hauptbenutzer && (
            <div className="dropdown" onClick={() => toggleOpen()}>
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true">
                Kontakt
                </button>
              <div className={menuClass} aria-labelledby="dropdownMenuButton">
                <a className="dropdown-item" href="/contact">
                  Kontaktseite
                  </a>
                <a className="dropdown-item" href="/client_Who_am_I">
                  Wer bin ich?
                  </a>
                <a className="dropdown-item" href="#remark?">
                  Sprache
                  </a>
                <a className="dropdown-item" href="/about">
                  About
                  </a>
              </div>
            </div>)}


*/
